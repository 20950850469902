import exchangeRate from "../api/city/exchangeRate/index"

const Convert = (query) => {
    return new Promise((resolve) => {
        resolve(exchangeRate.exchangeRate.Convert(query))
    })
}

export default {
    namespaced: true,
    state: {
        exchangeRate: null,
    },
    getters: {
        GET_EXCHANGE_RATE(state) {
            return state.exchangeRate;
        },
    },
    mutations: {
        SET_EXCHANGE_RATE(state, payload) {
            state.exchangeRate = payload;
        },
    },
    actions: {
        async GET_EXCHANGE_RATE_FROM_API({ commit }, payload) {
            await Convert(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_EXCHANGE_RATE', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_EXCHANGE_RATE', null);
                })
        }
    }
}