import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import store from './store/index'
import signalr from './plugins/signalr'
import * as VueGoogleMaps from 'vue2-google-maps'
import CatCarousel from 'vue-cat-carousel'
import VueCountryCode from "vue-country-code";
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBdBN_Nu51PzpnkmAfxHkXrpzByHQlmlHI',
  }
});

Vue.use(CatCarousel);
Vue.use(VueCountryCode);
Vue.use(VueMeta);

new Vue({
  vuetify,
  render: h => h(App),
  router,
  store,
  signalr
}).$mount('#app')

