<template>
  <v-container>
    <div>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="font-weight-bold">{{
              stringValues.addReview[selectedLanguage.code]
            }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AddReviewTemplate />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-if="reviews.totalRecords > 0">
      <v-card outlined tile v-for="review in reviews.data" :key="review.id">
        <v-card-subtitle>
          <v-row>
            <v-col cols="8">
              <v-list-item dense>
                <v-list-item-avatar size="30">
                  <v-img :src="review.user.imageLink"> </v-img>
                </v-list-item-avatar>
                <v-list-item-title
                  ><span
                    class="text-caption d-inline-block text-truncate"
                    style="max-width: 100%"
                    >{{ review.user.name }}
                  </span></v-list-item-title
                >
              </v-list-item>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-end">
              <v-card elevation="0">
                <div>
                  <v-icon
                    small
                    :color="review.mark >= 1 ? 'green' : 'grey lighten-1'"
                    >mdi-hand-front-left</v-icon
                  >
                  <v-icon
                    small
                    :color="review.mark >= 2 ? 'green' : 'grey lighten-1'"
                    >mdi-hand-front-left</v-icon
                  >
                  <v-icon
                    small
                    :color="review.mark >= 3 ? 'green' : 'grey lighten-1'"
                    >mdi-hand-front-left</v-icon
                  >
                  <v-icon
                    small
                    :color="review.mark >= 4 ? 'green' : 'grey lighten-1'"
                    >mdi-hand-front-left</v-icon
                  >
                  <v-icon
                    small
                    :color="review.mark >= 5 ? 'green' : 'grey lighten-1'"
                    >mdi-hand-front-left</v-icon
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          {{ review.description }}
        </v-card-text>
      </v-card>
      <div class="text-center">
        <v-pagination
          circle
          v-model="reviews.pageNumber"
          :length="reviews.totalPages"
          color="purple"
        ></v-pagination>
      </div>
    </div>
    <div v-else>
      <h4 class="red--text font-weight-regular text-center">
        {{ stringValues.notElements[selectedLanguage.code] }}
      </h4>
    </div>
  </v-container>
</template>
<script>
import AddReviewTemplate from "./AddReviewTemplate.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ReviewTemplate",
  components: {
    AddReviewTemplate,
  },
  computed: {
    ...mapGetters({
      getInfo: "collector/GET_INFO",
      getReviews: "collector/GET_REVIEWS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    reviews: {
      get: function () {
        return this.getReviews;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getReviewsByPointIdFromApi: "collector/GET_REVIEWS_BY_POINT_ID_FROM_API",
    }),
    async getItemsFromApi(pointId, pageNumber, pageSize) {
      await this.getReviewsByPointIdFromApi({
        pointId: pointId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      });
    },
  },
  watch: {
    info: function () {
      this.getItemsFromApi(this.info.data.id, 1, 20);
    },
    "reviews.pageNumber": function () {
      this.getItemsFromApi(this.info.data.id, this.reviews.pageNumber, 20);
    },
  },
  async created() {
    await this.getItemsFromApi(this.info.data.id, 1, 20);
  },
};
</script>
