<template>
  <v-container>
    <v-card elevation="0" class="my-8">
      <v-card-title>
        {{ stringValues.changePassword[selectedLanguage.code] }}</v-card-title
      >
      <v-row class="d-flex align-center justify-center">
        <v-col cols="10" class="text-center">
          <v-text-field
            v-model="currentPassword"
            :label="stringValues.currentPassword[selectedLanguage.code]"
            color="purple"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordShow ? 'text' : 'password'"
            @click:append="passwordShow = !passwordShow"
            :rules="[
              passwordRequiredRule(currentPassword),
              passwordMinLengthRule(currentPassword),
              passwordMaxLengthRule(currentPassword),
            ]"
            :error-messages="
              errorMessages
                .filter((x) =>
                  ['passwordRequired', 'passwordMin', 'passwordMax'].includes(
                    x.ruleName
                  )
                )
                .map((x) => x.text)
                .join()
            "
            dense
            outlined
          >
          </v-text-field>
          <v-text-field
            v-model="newPassword"
            :label="stringValues.newPassword[selectedLanguage.code]"
            color="purple"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordShow ? 'text' : 'password'"
            @click:append="passwordShow = !passwordShow"
            :rules="[
              passwordRequiredRule(newPassword),
              passwordMinLengthRule(newPassword),
              passwordMaxLengthRule(newPassword),
            ]"
            :error-messages="
              errorMessages
                .filter((x) =>
                  ['passwordRequired', 'passwordMin', 'passwordMax'].includes(
                    x.ruleName
                  )
                )
                .map((x) => x.text)
                .join()
            "
            dense
            outlined
          >
          </v-text-field>
          <v-btn
            class="
              mt-2
              purple
              white--text
              font-weight-regular
              rounded-lg
              text-capitalize
            "
            @click="change()"
            elevation="0"
            :loading="loading"
            :disabled="loading"
            small
          >
            {{ stringValues.apply[selectedLanguage.code] }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ChangePassword",
  computed: {
    ...mapGetters({
      getChangeStatus: "account/GET_CHANGE_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    changeStatus: {
      get: function () {
        return this.getChangeStatus;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  components: {},
  data: () => ({
    currentPassword: null,
    newPassword: null,
    passwordShow: false,
    modelState: [
      {
        ruleName: "passwordRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMin",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMax",
        status: false,
        text: null,
      },
    ],
    errorMessages: [],
    loading: false,
  }),
  methods: {
    ...mapActions({
      changePasswordFromApi: "account/CHANGE_PASSWORD_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setDialog: "common/SET_DIALOG",
    }),
    passwordRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Enter password";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMin"
      );
      if (v != null && v.length < 6) {
        currentRule.status = false;
        currentRule.text = "Minimum password length 6 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMax"
      );
      if (v != null && v.length > 15) {
        currentRule.status = false;
        currentRule.text = "Maximum password length 255 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    async change() {
      var validateStatus = this.modelState.every((x) => x.status);
      if (validateStatus) {
        this.loading = true;
        await this.changePasswordFromApi({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        });
        if (this.changeStatus.succeeded) {
          this.setDialog({
            show: false,
            fullscreen: false,
            hide_overlay: true,
            transition: "dialog-bottom-transition",
            title: "Изменить пароль",
            view_id: 10,
            width: 800,
          });
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
        this.loading = false;
      } else {
        this.errorMessages = this.modelState
          .filter((x) => !x.status)
          .map((x) => ({
            ruleName: x.ruleName,
            text: x.text,
          }));
      }
    },
  },
  async created() {},
};
</script>
