<template>
  <v-container>
    <v-card
      v-if="productList.succeeded && productList.data.length > 0"
      elevation="0"
      :loading="loading"
    >
      <v-card-title>
        <v-spacer />
        <v-btn
          small
          outlined
          color="green"
          @click="generateExcel()"
          :loading="excelLoading"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>
          Excel
        </v-btn>
      </v-card-title>
      <template slot="progress">
        <v-progress-linear color="purple" indeterminate></v-progress-linear>
      </template>
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-row no-gutters>
            <template>
              <v-data-table
                :headers="headers[selectedLanguage.code]"
                :items="productList.data"
                :items-per-page="20"
                class="elevation-0"
                hide-default-footer
                :item-class="itemRowBackground"
              >
                <template v-slot:item.enter="{ item }">
                  <v-btn small fab text @click="openAd(item.id)">
                    <v-icon color="black">mdi-location-enter</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.insertedDate="{ item }">
                  <span>{{
                    new Date(item.insertedDate).toLocaleString()
                  }}</span>
                </template>

                <template v-slot:item.user="{ item }">
                  <span>{{
                    item.user.firstName + " " + item.user.lastName
                  }}</span>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <v-pagination
              circle
              v-model="productList.pageNumber"
              :length="productList.totalPages"
              color="purple"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else elevation="0">
      <h4 class="red--text font-weight-regular text-center">
        {{ stringValues.notElements[selectedLanguage.code] }}
      </h4>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import marketInstance from "../../../api/market/instance";
export default {
  name: "MarketAdsAdministrator",
  props: {
    productCategoryId: {
      type: Number,
      default() {
        return 8;
      },
    },
  },
  components: {},
  computed: {
    ...mapGetters({
      getProductList: "market/GET_PRODUCTS",
      getSelectedRegion: "common/GET_SELECTED_REGION",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getExcelGuid: "market/GET_EXCEL_GUID",
    }),
    productList: {
      get: function () {
        return this.getProductList;
      },
    },
    selectedRegion: {
      get: function () {
        return this.getSelectedRegion;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    excelGuid: {
      get: function () {
        return this.getExcelGuid;
      },
    },
  },
  data: () => ({
    instance: marketInstance,
    excelLoading: false,
    dialog: false,
    dialogDelete: false,
    headers: {
      ru: [
        { text: "Открыть", value: "enter", sortable: false },
        { text: "ID", value: "id", sortable: false },
        {
          text: "Статус",
          value: "status.name",
          sortable: false,
        },
        {
          text: "Название",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Категория", value: "productCategory.name", sortable: false },
        { text: "Регион", value: "region.name", sortable: false },
        { text: "Дата", value: "insertedDate", sortable: false },
        { text: "Автор", value: "user", sortable: false },
        { text: "Телефон", value: "contactNumber", sortable: false },
        { text: "Описание", value: "comment", sortable: false },
      ],
      en: [
        { text: "Open", value: "enter", sortable: false },
        { text: "ID", value: "id", sortable: false },
        {
          text: "Status",
          value: "status.name",
          sortable: false,
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Category", value: "productCategory.name", sortable: false },
        { text: "Region", value: "region.name", sortable: false },
        { text: "Date", value: "insertedDate", sortable: false },
        { text: "Author", value: "user", sortable: false },
        { text: "Phone number", value: "contactNumber", sortable: false },
        { text: "Description", value: "comment", sortable: false },
      ],
      tr: [
        { text: "Açmak", value: "enter", sortable: false },
        { text: "ID", value: "id", sortable: false },
        {
          text: "Durum",
          value: "status.name",
          sortable: false,
        },
        {
          text: "İsim",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Kategori", value: "productCategory.name", sortable: false },
        { text: "Bölge", value: "region.name", sortable: false },
        { text: "Tarih", value: "insertedDate", sortable: false },
        { text: "Yazar", value: "user", sortable: false },
        { text: "Telefon", value: "contactNumber", sortable: false },
        { text: "Tanım", value: "comment", sortable: false },
      ],
    },
    sortId: 1,
    loading: true,
  }),
  methods: {
    ...mapActions({
      getProductListByCategoryIdFromApi:
        "market/ADMIN_GET_PRODUCTS_BY_CATEGORY_ID_FROM_API",
      getByCategoryIdExcelFromApi:
        "market/ADMIN_GET_BY_CATEGORY_ID_EXCEL_FROM_API",
    }),
    openAd(productId) {
      window.open(`/market/admin/ad/${productId}`, "_blank");
    },
    async getItemsFromApi(pageNumber, pageSize) {
      this.loading = true;
      await this.getProductListByCategoryIdFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        categoryId: this.productCategoryId,
        regionId: this.selectedRegion.id,
        sortId: this.sortId,
      });
      this.loading = false;
    },
    async selectSort(id) {
      this.sortId = id;
      await this.getItemsFromApi(1, 20);
    },
    itemRowBackground: function (item) {
      let color = "white";
      switch (item.status.id) {
        case 1:
          color = "green lighten-4";
          break;
        case 2:
          color = "red lighten-4";
          break;
        case 3:
          color = "orange lighten-4";
          break;
        default:
          break;
      }

      return color;
    },
    async generateExcel() {
      this.excelLoading = true;
      await this.getByCategoryIdExcelFromApi({
        categoryId: 8,
        regionId: 243,
        sortId: 1,
      });
      if (this.excelGuid.succeeded) {
        document.location.href = `${this.instance.defaults.baseURL}/Administrator/Download?fileGuid=${this.excelGuid.data}`;
      }
      this.excelLoading = false;
    },
  },
  watch: {
    "productList.pageNumber": function () {
      this.getItemsFromApi(this.productList.pageNumber, 20);
    },
  },
  async created() {
    this.getItemsFromApi(1, 20);
  },
};
</script>