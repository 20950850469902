<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3" lg="3" xl="3">
        <v-card elevation="0" height="90vh">
          <v-card-title>
            {{ stringValues.personalCabinet[selectedLanguage.code] }}
          </v-card-title>
          <v-card-text>
            <v-list dense two-line>
              <v-list-item-group v-model="tab">
                <v-list-item>
                  <span class="text-capitalize">{{
                    stringValues.aboutRC[selectedLanguage.code]
                  }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="text-capitalize">{{
                    stringValues.bulletinBoard[selectedLanguage.code]
                  }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="text-capitalize">{{
                    stringValues.documents[selectedLanguage.code]
                  }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="text-capitalize">{{
                    stringValues.ads[selectedLanguage.code]
                  }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="text-capitalize">{{
                    stringValues.videoCameras[selectedLanguage.code]
                  }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="text-capitalize">{{
                    stringValues.connectKapydzhi[selectedLanguage.code]
                  }}</span>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="9" lg="9" xl="9">
        <v-card
          elevation="0"
          height="90vh"
          class="grey lighten-4 overflow-y-auto"
        >
          <About v-if="tab == 0" />
          <News v-else-if="tab == 1" />
          <Documents v-else-if="tab == 2" />
          <Ads v-else-if="tab == 3" />
          <Chat v-else-if="tab == 5" />
          <div v-else></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import About from "./Enter/About.vue";
import News from "./Enter/News.vue";
import Documents from "./Enter/Documents.vue";
import Chat from "./Enter/Chat.vue";
import Ads from "./Enter/Ads.vue";
export default {
  name: "HouseEnter",
  components: {
    About,
    News,
    Documents,
    Chat,
    Ads,
  },
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    tab: 0,
  }),
};
</script>
