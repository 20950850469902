<template>
  <div>
    <v-slide-group show-arrows v-if="subProductCategories != null" class="pa-4">
      <v-slide-item v-for="item in subProductCategories" :key="item.id">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card
              class="mx-2 my-2 rounded-lg"
              :elevation="0"
              @click="open(item)"
            >
              <v-card-text class="px-0 pt-0">
                <v-img
                  lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                  :src="item.imageLink"
                  class="rounded-lg mx-auto"
                  height="96"
                  width="96"
                >
                </v-img>
              </v-card-text>
              <v-card-text class="text-center text-subtitle-2 py-0">
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 96px"
                  >{{ item.name }}</span
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-slide-item>
    </v-slide-group>
    <div v-else>
      <v-skeleton-loader type="image" class="rounded-lg"></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MarketNestedProductCategoryList",
  props: {
    productCategoryId: {
      type: String,
      default() {
        return "0";
      },
    },
  },
  computed: {
    ...mapGetters({
      getAll: "market/GET_PRODUCT_CATEGORIES",
    }),
    productCategoryList: {
      get: function () {
        return this.getAll;
      },
    },
  },
  data: () => ({
    subProductCategories: null,
  }),
  methods: {
    open(item) {
      let productCategoryIdArr = this.productCategoryId.split("|");
      if (item.id != productCategoryIdArr[productCategoryIdArr.length - 1]) {
        this.$router.push({
          path: "/market/" + this.productCategoryId + "|" + item.id,
        });
      }
    },
    init(productCategoryId) {
      if (this.productCategoryList != null) {
        let productCategoryIdInner = 0;
        let productCategoryIdArr = productCategoryId.split("|");
        if (productCategoryIdArr.length == 1) {
          productCategoryIdInner = productCategoryIdArr[0];
          this.subProductCategories = this.productCategoryList.data.find(
            (x) => x.id == parseInt(productCategoryIdInner, 10)
          ).subProductCategories;
        } else {
          let res = [];
          for (var i = 0; i < productCategoryIdArr.length; i++) {
            if (i == 0) {
              res = this.productCategoryList.data.find(
                (x) => x.id == parseInt(productCategoryIdArr[i], 10)
              ).subProductCategories;
            } else {
              if (i == productCategoryIdArr.length - 1) {
                let currentRes = res.filter(
                  (x) => x.id == parseInt(productCategoryIdArr[i], 10)
                );
                res = currentRes;
              } else {
                res = res.find(
                  (x) => x.id == parseInt(productCategoryIdArr[i], 10)
                ).subProductCategories;
              }
            }
            this.subProductCategories = res;
          }
        }
      }
    },
  },
  watch: {
    $route(to) {
      this.init(to.params.productCategoryId);
    },
  },
  created() {
    this.init(this.productCategoryId);
  },
};
</script>