export default function (instance) {
    return {
        GetByUser() {
            return instance.get('/ResidentialComplex/GetByUser');
        },
        GetByRegionId(payload) {
            return instance.post(`/ResidentialComplex/GetByRegionId?id=${payload.id}`, payload.model);
        },
        GetById(payload) {
            return instance.get(`/ResidentialComplex/GetById?id=${payload.id}`);
        },
        GetReviewsByResidentialComplexId(payload) {
            return instance.get(`/Review/GetPagedByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}&PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`);
        },
        GetNewsByResidentialComplexId(payload) {
            return instance.get(`/News/GetPagedByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}&PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`);
        },
        GetDocumentsByResidentialComplexId(payload) {
            return instance.get(`/Document/GetPagedByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}&PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`);
        },
        GetParameters() {
            return instance.get('/ResidentialComplex/GetResidentialComplexParameters');
        },
        GetParametersByCategoryId(payload) {
            return instance.get(`/City/GetParametersByCategory?id=${payload.id}`)
        },
        GetByRegionIdCategoryIdd(payload) {
            return instance.post(`/City/GetByRegionIdCategoryId?RegionId=${payload.regionId}&CategoryId=${payload.categoryId}&Longitude=${payload.longitude}&Latitude=${payload.latitude}&OrderId=${payload.orderId}&PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`, payload.parameters)
        },
        GetByIdCollector(payload) {
            return instance.get(`/City/GetById?id=${payload.id}`);
        },
        AddUser(payload) {
            return instance.post(`/ResidentialComplex/AddUser`, payload);
        },
        AddReview(payload) {
            return instance.post(`/Review/Add`, payload);
        },
        GetReviewsByPointId(payload) {
            return instance.get(`/Review/GetPagedByPointId?PointId=${payload.pointId}&PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`);
        },
        AddPointReview(payload) {
            return instance.post(`/Review/AddPointReview`, payload);
        },
        GetAllLanguages() {
            return instance.get('/Language/GetAll');
        },
        CheckUserExists(payload) {
            return instance.get(`/ResidentialComplex/CheckUserExists?Id=${payload.residentialComplexId}`);
        },
        AdminGetPagedResidentialComplexByUserId(payload) {
            return instance.get(`/Administrator/GetPagedResidentialComplexByUserId?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`);
        },
        AdminGetPagedApplianceByUserIdAndResidentialComplex(payload) {
            return instance.get(`/Administrator/GetPagedApplianceByUserIdAndResidentialComplex?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}`);
        },
        AdminUpdateAppliance(payload) {
            return instance.put(`/Administrator/UpdateAppliance`, payload);
        },
        AdminGetPagedNewsByUserIdAndResidentialComplex(payload) {
            return instance.get(`/Administrator/GetPagedNewsByUserIdAndResidentialComplex?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}`);
        },
        AdminUpdateNews(payload) {
            return instance.put(`/Administrator/UpdateNews`, payload);
        },
        AdminDeleteNews(payload) {
            return instance.delete(`/Administrator/DeleteNews`, payload);
        },
        AdminAddNews(payload) {
            return instance.post(`/Administrator/AddNews`, payload);
        },
        AdminGetPagedDocumentsByUserIdAndResidentialComplex(payload) {
            return instance.get(`/Administrator/GetPagedDocumentByUserIdAndResidentialComplex?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}`);
        },
        AdminUpdateDocuments(payload) {
            return instance.put(`/Administrator/UpdateDocument`, payload);
        },
        AdminDeleteDocuments(payload) {
            return instance.delete(`/Administrator/DeleteDocument`, payload);
        },
        AdminAddDocuments(payload) {
            return instance.post(`/Administrator/AddDocument`, payload);
        },
        AddSystemReview(payload) {
            return instance.post(`/Review/AddSystemReview`, payload);
        },
        AdminGetPagedOrder(payload) {
            return instance.get(`/Administrator/GetPagedOrder?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`)
        },
        AdminUpdateByOrderId(payload) {
            return instance.put(`/Administrator/UpdateOrderById?Id=${payload.id}&StartDate=${payload.startDate}&EndDate=${payload.endDate}&Comment=${payload.comment}`)
        },
        AdminConfirmByOrderId(payload) {
            return instance.put(`/Administrator/ConfirmOrderById?Id=${payload.id}`)
        },
        AdminAddPointToTop(payload) {
            return instance.post(`/Administrator/AddPointToTop`, payload)
        },
    }
}
