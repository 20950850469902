<template>
  <v-container>
    <div v-if="info != null && info.succeeded">
      <v-card elevation="0" class="rounded-lg">
        <v-card-text
          class="py-0"
          v-if="
            loginInfo != null &&
            (loginInfo.data.user.roleName.includes('app_admin') ||
              loginInfo.data.user.roleName.includes('super_admin'))
          "
        >
          <v-row no-gutters>
            <v-col cols="12" class="text-right">
              <v-btn
                x-small
                elevation="1"
                class="green white--text my-1"
                @click="openTopDialog()"
                >Добавить в ТОП</v-btn
              >
            </v-col>
          </v-row>
          <v-dialog v-model="topDialog" width="800" scrollable>
            <v-card>
              <v-card-title class="pa-0">
                <v-toolbar dark color="grey">
                  <v-spacer />
                  <v-btn icon dark @click="topDialog = false" color="white">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addedItem.startDate"
                            hide-details
                            color="purple"
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :label="
                              stringValues.dateStart[selectedLanguage.code]
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addedItem.startDate"
                          no-title
                          scrollable
                          color="purple"
                          :locale="
                            selectedLanguage != undefined &&
                            selectedLanguage != null
                              ? selectedLanguage.code
                              : 'en'
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addedItem.endDate"
                            hide-details
                            color="purple"
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :label="stringValues.dateEnd[selectedLanguage.code]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addedItem.endDate"
                          no-title
                          scrollable
                          color="purple"
                          :locale="
                            selectedLanguage != undefined &&
                            selectedLanguage != null
                              ? selectedLanguage.code
                              : 'en'
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" text @click="topDialog = false">
                  {{ stringValues.cancel[selectedLanguage.code] }}
                </v-btn>
                <v-btn color="purple" text @click="save()">
                  {{ stringValues.apply[selectedLanguage.code] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="5" lg="5" xl="5">
              <div>
                <v-carousel
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="info.data.images.length > 1"
                  height="40vh"
                >
                  <v-carousel-item
                    v-for="(item, i) in info.data.images"
                    :key="i"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      :src="item.path"
                      contain
                      height="40vh"
                      width="100%"
                      @click="openImage()"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>
            <v-col cols="12" md="7" lg="7" xl="7" class="px-4 py-4">
              <v-row>
                <v-col cols="8">
                  <v-card elevation="0" height="3vh">
                    <span
                      class="font-weight-bold text-subtitle-1 purple--text"
                      >{{ info.data.name }}</span
                    >
                  </v-card>
                  <v-card elevation="0" height="3vh">
                    <span class="font-weight-regular text-caption">{{
                      info.data.address
                    }}</span>
                  </v-card>
                  <v-card elevation="0" height="3vh">
                    <span class="font-weight-regular text-caption">{{
                      info.data.region.name
                    }}</span>
                  </v-card>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-card elevation="0" height="3vh">
                    {{
                      info.data.workHours != null
                        ? info.data.workHours.find(
                            (x) => x.dayId == new Date().getDay()
                          ) != null
                          ? info.data.workHours.find(
                              (x) => x.dayId == new Date().getDay()
                            ).startTime
                          : ""
                        : ""
                    }}
                  </v-card>
                  <v-card elevation="0" height="6vh">
                    <div class="text-caption">Рейтинг</div>
                    <div>
                      <v-icon
                        small
                        :color="
                          info.data.averageMark >= 1
                            ? 'green'
                            : 'grey lighten-1'
                        "
                        >mdi-hand-front-left</v-icon
                      >
                      <v-icon
                        small
                        :color="
                          info.data.averageMark >= 2
                            ? 'green'
                            : 'grey lighten-1'
                        "
                        >mdi-hand-front-left</v-icon
                      >
                      <v-icon
                        small
                        :color="
                          info.data.averageMark >= 3
                            ? 'green'
                            : 'grey lighten-1'
                        "
                        >mdi-hand-front-left</v-icon
                      >
                      <v-icon
                        small
                        :color="
                          info.data.averageMark >= 4
                            ? 'green'
                            : 'grey lighten-1'
                        "
                        >mdi-hand-front-left</v-icon
                      >
                      <v-icon
                        small
                        :color="
                          info.data.averageMark >= 5
                            ? 'green'
                            : 'grey lighten-1'
                        "
                        >mdi-hand-front-left</v-icon
                      >
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider class="my-1" />
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card-title class="font-weight-bold py-1 px-0">{{
                    stringValues.description[selectedLanguage.code]
                  }}</v-card-title>
                  <v-card-text class="px-0">
                    <div v-if="!viewFullComment">
                      <span class="font-weight-regular">
                        {{
                          info.data.comment != null &&
                          info.data.comment.length > 250
                            ? info.data.comment.substring(0, 250) + "..."
                            : info.data.comment
                        }}
                      </span>
                      <div>
                        <v-btn
                          x-small
                          text
                          color="purple"
                          class="px-0 text-capitalize"
                          @click="viewFullComment = !viewFullComment"
                          >Подробнее...</v-btn
                        >
                      </div>
                    </div>
                    <div v-else>
                      <span class="font-weight-regular">
                        {{ info.data.comment }}
                      </span>
                      <div>
                        <v-btn
                          x-small
                          text
                          color="purple"
                          class="px-0 text-capitalize"
                          @click="viewFullComment = !viewFullComment"
                          >Свернуть...</v-btn
                        >
                      </div>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
              <!-- <div>
                <div class="my-2">
                  <v-btn
                    outlined
                    color="purple"
                    v-if="
                      info.data.socialNetworks.some(
                        (x) => x.socialNetworkId == 1
                      )
                    "
                    @click="
                      goToLink(
                        1,
                        info.data.socialNetworks.find(
                          (x) => x.socialNetworkId == 1
                        )
                      )
                    "
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    color="purple"
                    class="mx-5"
                    v-if="
                      info.data.socialNetworks.some(
                        (x) => x.socialNetworkId == 2
                      )
                    "
                    @click="
                      goToLink(
                        2,
                        info.data.socialNetworks.find(
                          (x) => x.socialNetworkId == 2
                        )
                      )
                    "
                  >
                    <v-icon>mdi-send-circle-outline</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    color="purple"
                    v-if="
                      info.data.socialNetworks.some(
                        (x) => x.socialNetworkId == 3
                      )
                    "
                    @click="
                      goToLink(
                        3,
                        info.data.socialNetworks.find(
                          (x) => x.socialNetworkId == 3
                        )
                      )
                    "
                  >
                    <v-icon>mdi-instagram</v-icon>
                  </v-btn>
                </div>-->
            </v-col>
          </v-row>
          <v-divider class="my-1" />
          <v-row>
            <v-col cols="12">
              <v-card-title class="font-weight-bold">{{
                stringValues.reviews[selectedLanguage.code]
              }}</v-card-title>
              <v-card-text>
                <ReviewTemplate />
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="my-1" />
          <v-row>
            <v-col cols="12">
              <v-card-title class="font-weight-bold">{{
                stringValues.additionalInformation[selectedLanguage.code]
              }}</v-card-title>
              <v-card-text>
                <MoreTemplate />
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="my-1" />
          <v-row>
            <v-col cols="12">
              <v-card-title class="font-weight-bold">{{
                stringValues.schedule[selectedLanguage.code]
              }}</v-card-title>
              <v-card-text>
                <WorkhourTemplate />
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else class="text-center">
      <h4 class="red--text font-weight-regular">
        {{ stringValues.error[selectedLanguage.code] }}
      </h4>
    </div>
    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="grey">
            <v-spacer />
            <v-btn icon dark @click="dialog = false" color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="my-2">
          <v-carousel :show-arrows="info.data.images.length > 1">
            <v-carousel-item
              v-for="(item, i) in info.data.images"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-img
                :src="item.path"
                max-height="100%"
                max-width="100%"
              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ReviewTemplate from "./ReviewTemplate.vue";
import MoreTemplate from "./MoreTemplate.vue";
import WorkhourTemplate from "./WorkhourTemplate.vue";
export default {
  name: "InfoTemplate",
  components: {
    ReviewTemplate,
    MoreTemplate,
    WorkhourTemplate,
  },
  computed: {
    ...mapGetters({
      getInfo: "collector/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getActionStatus: "house/GET_ACTION_STATUS",
      getLoginInfo: "account/GET_LOGIN_INFO",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
    },
  },
  data: () => ({
    viewFullComment: false,
    dialog: false,
    topDialog: false,
    addedItem: {
      pointId: 0,
      startDate: "",
      endDate: "",
    },
    menu: false,
    menu1: false,
  }),
  metaInfo() {
    return {
      title:
        this.info != null && this.info.succeeded ? this.info.data.name : "",
      titleTemplate: "%s | EasyLife",
      meta: [
        {
          property: "og:title",
          content:
            this.info != null && this.info.succeeded ? this.info.data.name : "",
        },
        {
          property: "og:description",
          content:
            this.info != null && this.info.succeeded
              ? this.info.data.comment
              : "",
        },
        {
          property: "og:image",
          content:
            this.info != null && this.info.succeeded
              ? this.info.data.images[0].path
              : "",
        },
        { property: "og:url", content: document.location.href },
      ],
    };
  },
  methods: {
    ...mapActions({
      getInfoFromApi: "collector/GET_INFO_FROM_API",
      addPointToTopFromApi: "house/ADMIN_ADD_POINT_TO_TOP_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    goToLink(socialNetworkId, item) {
      switch (socialNetworkId) {
        case 1:
          window.open("https://wa.me/" + item.value, "_blank");
          break;
        case 2:
          window.open("https://t.me/" + item.value, "_blank");
          break;
        case 3:
          window.open("https://instagram.com/" + item.value, "_blank");
          break;
        default:
          break;
      }
    },
    async init(id) {
      await this.getInfoFromApi({
        id: id,
      });
    },
    openImage() {
      this.dialog = true;
    },
    openTopDialog() {
      this.topDialog = true;
    },
    async save() {
      this.addedItem.pointId = this.info.data.id;

      await this.addPointToTopFromApi(this.addedItem);

      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });

        this.topDialog = false;
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
    },
  },
  watch: {
    $route(to) {
      this.init(to.params.id);
    },
  },
  async created() {
    let id = parseInt(this.$route.params.id, 10);
    await this.init(id);
  },
};
</script>
