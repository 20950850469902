<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg">
      <v-card-text>
        <v-card-title> Придумайте название </v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              label="Наименование объявления или услуги"
              outlined
              dense
              color="purple"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider />
        <v-card-title> Выберите категорию </v-card-title>
        <v-row>
          <v-col cols="12">
            <span class="text-subtitle-2">Выбранная категория: </span>
            <span class="text-subtitle-2 purple--text">{{
              selectedCategory.name
            }}</span>
            <v-card-subtitle class="purple white--text">
              <v-row>
                <v-col cols="8">
                  <span>Выбор категории</span>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn
                    x-small
                    class="rounded-lg text-capitalize"
                    tile
                    elevation="0"
                    @click="reset()"
                    >Сбросить</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-list dense height="20vh" class="overflow-auto">
              <v-list-item-group>
                <v-list-item
                  v-for="productCategory in subCategories"
                  :key="productCategory.id"
                  @click="getSubCategories(productCategory)"
                >
                  <v-list-item-title>{{
                    productCategory.name
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <v-divider />
        <v-card-title> Заполните описание </v-card-title>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="comment"
              label="Описание (минимум 20 символов)"
              outlined
              dense
              color="purple"
              placeholder="Расскажите об особенностях, которые могут быть интересны людям"
            ></v-textarea>
            <div
              v-if="parameters != null ?? parameters.data != null"
              class="my-2"
            >
              <v-row
                v-for="parameter in selectedParameters.data"
                :key="parameter.id"
              >
                <v-col cols="6" class="text-center">
                  <span
                    >{{ parameter.name
                    }}{{ parameter.isRequired ? " (обязательно*)" : "" }}:</span
                  >
                </v-col>
                <v-col cols="6" class="text-center">
                  <div v-if="parameter.isFromCatalog">
                    <v-select
                      v-model="parameter.selectedValues"
                      :items="parameter.parameterCatalogs"
                      item-text="name"
                      item-value="id"
                      label="Выберите элементы"
                      :multiple="parameter.isMultipleSelect"
                      outlined
                      dense
                      small-chips
                      color="purple"
                    ></v-select>
                  </div>
                  <div v-else>
                    <v-text-field
                      v-model="parameter.selectedValues"
                      :label="parameter.name"
                      outlined
                      dense
                      color="purple"
                      :type="parameter.valueTypeId == 1 ? 'text' : 'number'"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-divider />
        <v-card-title> Укажите цену </v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="price"
              label="Цена"
              outlined
              dense
              type="number"
              color="purple"
            >
              <template v-slot:append>
                <v-icon color="purple">{{ selectedCurrency }}</v-icon>
              </template>
              <template v-slot:append-outer>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="rounded-lg text-capitalize purple white--text"
                      elevation="0"
                    >
                      <v-icon left> mdi-menu </v-icon>
                      Валюта
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item-group v-model="selectedCurrency">
                      <v-list-item
                        dense
                        v-for="currency in currencies"
                        :key="currency.id"
                        :value="currency.text"
                      >
                        <v-list-item-avatar>
                          <v-icon>{{ currency.icon }}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            currency.title
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider />
        <v-card-title> Добавьте фотографии </v-card-title>
        <v-row>
          <v-col cols="12">
            <v-file-input
              v-model="images"
              accept="image/png, image/jpeg, image/bmp, image/jpg"
              placeholder="Выберите изображения"
              prepend-icon="mdi-camera"
              label="Изображения"
              color="purple"
              counter
              chips
              multiple
              outlined
              dense
            ></v-file-input>
            <div class="d-flex overflow-x-auto px-2 py-2">
              <v-img
                v-for="(item, index) in imageURLs"
                :key="index"
                :src="item.path"
                max-height="120px"
                max-width="200px"
                class="mx-2 text-right"
                @click="changeMainImage(item)"
              >
                <v-chip
                  small
                  class="purple white--text my-1 mx-1"
                  v-if="item.isMain"
                  >Основное</v-chip
                >
              </v-img>
            </div>
          </v-col>
        </v-row>
        <v-divider />
        <v-card-title> Добавьте контакты </v-card-title>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <span class="text-subtitle-2">Выбранный регион: </span>
                <span class="text-subtitle-2 purple--text">{{
                  selectedRegion.name
                }}</span>
                <v-card-subtitle class="purple white--text">
                  <v-row>
                    <v-col cols="8">
                      <span>{{
                        stringValues.chooseRegion[selectedLanguage.code]
                      }}</span>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <v-btn
                        x-small
                        tile
                        elevation="0"
                        class="rounded-lg text-capitalize"
                        @click="resetRegion()"
                        >Сбросить</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-subtitle>
                <v-list dense height="20vh" class="overflow-auto">
                  <v-list-item-group>
                    <v-list-item
                      v-for="region in subRegions"
                      :key="region.id"
                      @click="getSubRegions(region)"
                    >
                      <v-list-item-title>{{ region.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>

            <v-select
              v-model="language"
              :items="languages"
              label="Предпочитаемый язык общения"
              outlined
              dense
              color="purple"
              hide-details
              class="my-2"
            ></v-select>

            <v-row>
              <v-col cols="2" md="1" lg="1" xl="1">
                <vue-country-code
                  @onSelect="countryCodeSelect"
                  :preferredCountries="['TR', 'KZ', 'RU']"
                  style="width: 100%"
                ></vue-country-code>
              </v-col>
              <v-col cols="10" md="11" lg="11" xl="11">
                <v-text-field
                  v-model="phoneNumber"
                  label="Номер телефона"
                  color="purple"
                  type="phone"
                  :prefix="'+' + countryPhoneNumberCode"
                  :maxlength="
                    18 - (1 + countryPhoneNumberCode).toString().length
                  "
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              v-model="address"
              label="Адрес"
              outlined
              dense
              color="purple"
              hide-details
              class="my-2"
            ></v-text-field>
            <div>Продавец</div>
            <v-btn-toggle color="purple" v-model="sellerType">
              <v-btn small elevation="0" class="rounded-lg text-capitalize">
                Частный
              </v-btn>
              <v-btn small elevation="0" class="rounded-lg text-capitalize">
                Бизнес
              </v-btn>
            </v-btn-toggle>
            <div>
              <v-btn
                class="purple white--text my-2 text-capitalize rounded-lg"
                small
                @click="markOnTheMap()"
              >
                Отметить на карте
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-divider />
        <v-card-title></v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn
              small
              class="purple white--text rounded-lg text-capitalize"
              @click="add()"
              :loading="loading"
              :disabled="loading"
              elevation="0"
              >Добавить</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MarketUserListAdd",
  components: {},
  computed: {
    ...mapGetters({
      getAllProductCategories: "market/GET_PRODUCT_CATEGORIES",
      getParameters: "market/GET_PARAMETERS",
      getRegions: "common/GET_REGIONS",
      getActionStatus: "market/GET_ACTION_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getLocation: "common/GET_LOCATION",
      getAddLocation: "market/GET_ADD_LOCATION",
    }),
    productCategories: {
      get: function () {
        return this.getAllProductCategories;
      },
    },
    parameters: {
      get: function () {
        return this.getParameters;
      },
    },
    regions: {
      get: function () {
        return this.getRegions;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    location: {
      get: function () {
        return this.getLocation;
      },
    },
    addLocation: {
      get: function () {
        return this.getAddLocation;
      },
    },
  },
  data: () => ({
    name: null,
    subCategories: [],
    subRegions: [],
    selectedCategory: {
      id: 0,
      name: "Не выбрано!",
    },
    comment: null,
    images: [],
    selectedParameters: null,
    price: null,
    currencies: [
      { id: 1, icon: "mdi-currency-try", title: "Турецкая лира", text: "₺" },
      { id: 2, icon: "mdi-currency-usd", title: "Доллар США", text: "$" },
      { id: 3, icon: "mdi-currency-rub", title: "Российский рубль", text: "₽" },
      { id: 4, icon: "mdi-currency-eur", title: "Евро", text: "€" },
    ],
    selectedCurrency: "₺",
    imageURLs: [],
    region: null,
    language: "Turkce",
    sellerType: 0,
    countryPhoneNumberCode: null,
    selectedRegion: {
      id: 0,
      name: "Не выбрано!",
    },
    languages: ["Turkce", "Русский", "Английский"],
    address: null,
    phoneNumber: null,
    loading: false,
    counter: 0,
  }),
  methods: {
    ...mapActions({
      getParametersByCategoryIdFromApi:
        "market/GET_PARAMETERS_BY_CATEGORY_ID_FROM_API",
      addFromApi: "market/ADD_FROM_API",
      commitAddLocation: "market/COMMIT_ADD_LOCATION",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setDialog: "common/SET_DIALOG",
    }),
    async selectCategory(item) {
      this.selectedCategory = { id: item.id, name: item.name };
      await this.getParametersByCategoryIdFromApi({ id: item.id });

      let selectedParameters = this.parameters;
      if (selectedParameters != null && selectedParameters.data != null) {
        let newData = selectedParameters.data.map((z) => ({
          id: z.id,
          isFromCatalog: z.isFromCatalog,
          isMultipleSelect: z.isMultipleSelect,
          isRequired: z.isRequired,
          name: z.name,
          parameterCatalogs: z.parameterCatalogs,
          valueType: z.valueType,
          valueTypeId: z.valueTypeId,
          selectedValues: [],
        }));

        selectedParameters.data = newData;
      }

      this.selectedParameters = selectedParameters;
    },
    selectRegion(item) {
      this.selectedRegion = { id: item.id, name: item.name };
    },
    getSubCategories(item) {
      this.selectCategory(item);
      if (item.subProductCategories.length > 0) {
        this.subCategories = item.subProductCategories;
      }
    },
    getSubRegions(item) {
      this.selectRegion(item);
      if (item.subRegions.length > 0) {
        this.subRegions = item.subRegions;
      }
    },
    reset() {
      this.subCategories = this.productCategories.data;
    },
    resetRegion() {
      this.subRegions = this.regions.data;
    },
    async imagesToBase64(images) {
      if (
        this.imageURLs == null ||
        this.imageURLs == undefined ||
        this.imageURLs.length == 0
      )
        return;

      let mainImageIndex = this.imageURLs.find((x) => x.isMain).index;

      let result = [];
      for (var i = 0; i < images.length; i++) {
        let file = images[i];
        const getBase64 = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        };
        result.push({
          base64: await getBase64(file),
          index: i,
          isMain: mainImageIndex == i ? true : false,
        });
      }

      return result;
    },
    changeMainImage(item) {
      this.imageURLs = this.imageURLs.map((x) => ({
        index: x.index,
        path: x.path,
        isMain: x.path == item.path ? true : false,
      }));
    },
    validateFields() {
      if (
        this.name == null ||
        this.name == undefined ||
        this.name.length == 0
      ) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Введите наименование!",
        });
        return false;
      }

      if (this.selectedCategory == null || this.selectedCategory.id == 0) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Выберите категорию!",
        });
        return false;
      }

      if (
        this.comment == null ||
        this.comment == undefined ||
        this.comment.length == 0
      ) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Введите описание!",
        });
        return false;
      }

      if (this.price == null || this.price == undefined) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Введите цену!",
        });
        return false;
      }

      if (
        this.selectedCurrency == null ||
        this.selectedCurrency == undefined ||
        this.selectedCurrency.length == 0
      ) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Выберите валюту!",
        });
        return false;
      }

      if (this.selectedRegion == null || this.selectedRegion.id == 0) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Выберите регион!",
        });
        return false;
      }

      if (this.language == null || this.language.id == 0) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Выберите предпочитаемый язык общения!",
        });
        return false;
      }

      if (
        this.phoneNumber == null ||
        this.phoneNumber == undefined ||
        this.phoneNumber.length == 0
      ) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Введите номер телефона",
        });
        return false;
      }

      let formattedPhoneNumber =
        "+" + this.countryPhoneNumberCode + " " + this.phoneNumber;
      if (formattedPhoneNumber.length < 10) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Введите корректный номер телефона",
        });
        return false;
      }

      if (
        this.address == null ||
        this.address == undefined ||
        this.address.length == 0
      ) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Введите адрес",
        });
        return false;
      }

      return true;
    },
    async add() {
      this.loading = true;

      if (!this.validateFields()) {
        this.loading = false;
        return;
      }

      let formattedPhoneNumber =
        "+" + this.countryPhoneNumberCode + " " + this.phoneNumber;

      let st = this.sellerType;
      let stt = st + 1;
      let model = {
        name: this.name,
        regionId: this.selectedRegion.id,
        address: this.address,
        comment: this.comment,
        contactNumber: formattedPhoneNumber,
        price: this.price,
        productCategoryId: this.selectedCategory.id,
        currency: this.selectedCurrency,
        prefferedLanguage: this.language,
        sellerTypeId: stt,
        priceTypeId: 1,
        longitude: this.addLocation[1],
        latitude: this.addLocation[0],
      };

      if (
        this.images != null &&
        this.images != undefined &&
        this.images.length > 0
      ) {
        let imagesBase64 = await this.imagesToBase64(this.images);
        imagesBase64 = imagesBase64.map((x) => ({
          value: x.base64.substring(x.base64.indexOf(",") + 1),
          isMain: x.isMain,
        }));
        model.images = imagesBase64;
      } else {
        model.images = [];
      }

      if (
        this.selectedParameters != null &&
        this.selectedParameters.succeeded
      ) {
        if (this.selectedParameters.data != null) {
          let requiredParametersId = this.selectedParameters.data
            .filter((x) => x.isRequired)
            .map((x) => x.id);
          let formatedCatalogSingleParameters =
            this.selectedParameters.data.filter(
              (x) =>
                x.selectedValues > 0 && !x.isMultipleSelect && x.isFromCatalog
            );
          formatedCatalogSingleParameters = formatedCatalogSingleParameters.map(
            (x) => ({
              parameterId: x.id,
              valueId: x.selectedValues,
              value: "lol",
            })
          );

          let catalogMultipleParameters = this.selectedParameters.data.filter(
            (x) =>
              x.selectedValues.length > 0 &&
              x.isMultipleSelect &&
              x.isFromCatalog
          );
          let formattedCatalogMultipleParameters = [];
          for (var i = 0; i < catalogMultipleParameters.length; i++) {
            let parameterId = catalogMultipleParameters[i].id;
            for (
              var j = 0;
              j < catalogMultipleParameters[i].selectedValues.length;
              j++
            ) {
              formattedCatalogMultipleParameters.push({
                parameterId: parameterId,
                valueId: catalogMultipleParameters[i].selectedValues[j],
                value: "lol",
              });
            }
          }

          let manualParameters = this.selectedParameters.data.filter(
            (x) =>
              typeof x.selectedValues == "string" &&
              !x.isMultipleSelect &&
              !x.isFromCatalog
          );
          manualParameters = manualParameters.map((x) => ({
            parameterId: x.id,
            value: x.selectedValues,
            valueId: 0,
          }));

          let resultParameterValues = [
            ...new Set([
              ...formatedCatalogSingleParameters,
              ...formattedCatalogMultipleParameters,
              ...manualParameters,
            ]),
          ];

          model.parameterValues = resultParameterValues;

          let resultParametersId = resultParameterValues.map(
            (x) => x.parameterId
          );
          for (var k = 0; k < requiredParametersId.length; k++) {
            let requiredParametersCheckStatus = resultParametersId.some(
              (x) => x == requiredParametersId[k]
            );
            if (!requiredParametersCheckStatus) {
              this.setSnackBar({
                show: true,
                timeout: 3000,
                color: "purple",
                text: "Заполните все обязательные параметры",
              });
              this.loading = false;
              return;
            }
          }

          await this.addFromApi(model);
          if (this.actionStatus.succeeded) {
            this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "green",
              text: "Ваше объявление добавлено и в скором времени будет рассмотрено!",
            });
          } else {
            this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "purple",
              text: "Ошибка при добавлении объявления!",
            });
          }

          this.loading = false;
        }
      } else {
        model.parameterValues = [];
        await this.addFromApi(model);
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Ваше объявление добавлено и в скором времени будет рассмотрено!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: "Ошибка при добавлении объявления!",
          });
        }
        this.loading = false;
      }
    },
    markOnTheMap() {
      if (this.counter == 0) {
        this.commitAddLocation([
          this.location.latitude,
          this.location.longitude,
        ]);
      }
      this.counter++;
      this.setDialog({
        show: true,
        fullscreen: true,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: "Карта",
        view_id: 8,
        coords: {
          lat: this.addLocation[0],
          lng: this.addLocation[1],
        },
        isView: false,
      });
    },
    countryCodeSelect({ dialCode }) {
      this.countryPhoneNumberCode = dialCode;
      this.phoneNumber = null;
    },
  },
  watch: {
    images: function () {
      this.imageURLs = [];
      for (var i = 0; i < this.images.length; i++) {
        this.imageURLs.push({
          index: i,
          path: URL.createObjectURL(this.images[i]),
          isMain: i == 0 ? true : false,
        });
      }
    },
    phoneNumber: function () {
      var x = this.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,7})/);

      this.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "" + x[3] : "");
    },
  },
  async created() {
    this.subCategories = this.productCategories.data;
    this.subRegions = this.regions.data;
  },
};
</script>
