<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg mt-8 grey lighten-4">
      <v-row v-if="info.succeeded">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="text-center">
              <h4 class="font-weight-regular grey--text">
                <Images />
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="font-weight-bold text-subtitle-1">
                {{ stringValues.description[selectedLanguage.code] }}</span
              >
              <div>
                <span class="font-weight-regular">{{ info.data.comment }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-else>
        <h4 class="font-weight-regular red--text">
          {{ stringValues.selectRC[selectedLanguage.code] }}
        </h4>
      </div>
    </v-card>
    <v-card elevation="0" class="mt-8 rounded-lg">
      <v-card-title>
        {{ stringValues.additionalInformation[selectedLanguage.code] }}
      </v-card-title>
      <v-row v-if="info.succeeded">
        <v-col cols="12">
          <v-row
            v-for="item in info.data.groupedParameterValues"
            :key="item.parameterId"
          >
            <v-col cols="6" class="text-left px-8">
              <h4 class="font-weight-regular grey--text">
                {{ item.parameter.name }}:
              </h4>
            </v-col>
            <v-col cols="6" class="text-left">
              <h4 class="font-weight-bold">
                {{
                  item.pointParameterValueItemList
                    .map((x) => x.parameterCatalog.name)
                    .join()
                }}
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-else>
        <h4 class="font-weight-regular red--text">
          {{ stringValues.selectRC[selectedLanguage.code] }}
        </h4>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Images from "./Images.vue";
export default {
  name: "HouseMore",
  components: {
    Images,
  },
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
};
</script>
