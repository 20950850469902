import market from '../api/market/index'

const GetProductCategories = () => {
    return new Promise((resolve) => {
        resolve(market.market.GetProductCategories())
    })
}

const GetGlobalSearched = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.GetGlobalSearched(query))
    })
}

const GetPagedByCategoryId = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.GetPagedByCategoryId(query))
    })
}

const GetById = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.GetById(query))
    })
}

const GetPagedByUserId = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.GetPagedByUserId(query))
    })
}

const Delete = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.Delete(query))
    })
}

const GetProductCategoriesParametersByCategoryid = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.GetProductCategoriesParametersByCategoryid(query))
    })
}

const MoveToArchive = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.MoveToArchive(query))
    })
}

const Add = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.Add(query))
    })
}

const GetPagedByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.GetPagedByResidentialComplexId(query))
    })
}

const AdminGetPagedByCategoryId = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminGetPagedByCategoryId(query))
    })
}

const AdminGetById = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminGetById(query))
    })
}

const AdminGetPagedFeedback = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminGetPagedFeedback(query))
    })
}

const AdminDelete = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminDelete(query))
    })
}

const AdminApprove = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminApprove(query))
    })
}

const AdminGetByCategoryIdExcel = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminGetByCategoryIdExcel(query))
    })
}

const AdminGetFailures = () => {
    return new Promise((resolve) => {
        resolve(market.market.AdminGetFailures())
    })
}

const AdminGetStatuses = () => {
    return new Promise((resolve) => {
        resolve(market.market.AdminGetStatuses())
    })
}

const AdminUpdateByProductId = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminUpdateByProductId(query))
    })
}

const AdminGetPagedOrder = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminGetPagedOrder(query))
    })
}

const AdminUpdateByOrderId = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminUpdateByOrderId(query))
    })
}

const AdminConfirmByOrderId = (query) => {
    return new Promise((resolve) => {
        resolve(market.market.AdminConfirmByOrderId(query))
    })
}
export default {
    namespaced: true,
    state: {
        productCategories: [],
        globalSearchResults: null,
        products: {
            succeeded: false,
            pageNumber: 1
        },
        product: null,
        actionStatus: null,
        parameters: null,
        favorites: null,
        addLocation: [0, 0],
        excelGuid: null,
        failures: {
            data: []
        },
        statuses: {
            data: []
        }
    },
    getters: {
        GET_PRODUCT_CATEGORIES(state) {
            return state.productCategories;
        },
        GET_GLOBAL_SEARCH_RESULTS(state) {
            return state.globalSearchResults;
        },
        GET_PRODUCTS(state) {
            return state.products;
        },
        GET_PRODUCT(state) {
            return state.product;
        },
        GET_ACTION_STATUS(state) {
            return state.actionStatus;
        },
        GET_PARAMETERS(state) {
            return state.parameters;
        },
        GET_FAVORITES(state) {
            return state.favorites;
        },
        GET_ADD_LOCATION(state) {
            return state.addLocation;
        },
        GET_EXCEL_GUID(state) {
            return state.excelGuid;
        },
        GET_FAILURES(state) {
            return state.failures;
        },
        GET_STATUSES(state) {
            return state.statuses;
        },
    },
    mutations: {
        SET_PRODUCT_CATEGORIES(state, payload) {
            state.productCategories = payload;
        },
        SET_GLOBAL_SEARCH_RESULTS(state, payload) {
            state.globalSearchResults = payload;
        },
        SET_PRODUCTS(state, payload) {
            state.products = payload;
        },
        SET_PRODUCT(state, payload) {
            state.product = payload;
        },
        SET_ACTION_STATUS(state, payload) {
            state.actionStatus = payload;
        },
        SET_PARAMETERS(state, payload) {
            state.parameters = payload;
        },
        SET_FAVORITES(state, payload) {
            state.favorites = payload;
        },
        ADD_FAVORITES(state, payload) {
            if (state.favorites == null)
                state.favorites = [];

            state.favorites.push(payload);
            localStorage.setItem('marketFavorites', JSON.stringify(state.favorites));
        },
        REMOVE_FAVORITES(state, payload) {
            if (state.favorites == null)
                state.favorites = [];

            let index = state.favorites.map(x => x.id).indexOf(payload);
            if (index > -1) {
                state.favorites.splice(index, 1);
            }

            localStorage.setItem('marketFavorites', JSON.stringify(state.favorites));
        },
        SET_ADD_LOCATION(state, payload) {
            state.addLocation = payload;
        },
        SET_EXCEL_GUID(state, payload) {
            state.excelGuid = payload;
        },
        SET_FAILURES(state, payload) {
            state.failures = payload;
        },
        SET_STATUSES(state, payload) {
            state.statuses = payload;
        },
    },
    actions: {
        async GET_PRODUCT_CATEGORIES_FROM_API({ commit }) {
            await GetProductCategories().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCT_CATEGORIES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCT_CATEGORIES', { succeeded: false });
                })
        },
        async GET_GLOBAL_SEARCH_RESULTS_FROM_API({ commit }, payload) {
            await GetGlobalSearched(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_GLOBAL_SEARCH_RESULTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_GLOBAL_SEARCH_RESULTS', { succeeded: false });
                })
        },
        async GET_PRODUCTS_BY_CATEGORY_ID_FROM_API({ commit }, payload) {
            await GetPagedByCategoryId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCTS', { succeeded: false });
                })
        },
        async GET_PRODUCT_BY_ID_FROM_API({ commit }, payload) {
            await GetById(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCT', { succeeded: false });
                })
        },
        async GET_PRODUCTS_BY_USER_ID_FROM_API({ commit }, payload) {
            await GetPagedByUserId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCTS', { succeeded: false });
                })
        },
        async DELETE_FROM_API({ commit }, payload) {
            await Delete(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async MOVE_TO_ARCHIVE_FROM_API({ commit }, payload) {
            await MoveToArchive(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_PARAMETERS_BY_CATEGORY_ID_FROM_API({ commit }, payload) {
            await GetProductCategoriesParametersByCategoryid(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PARAMETERS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PARAMETERS', { succeeded: false });
                })
        },
        async ADD_FROM_API({ commit }, payload) {
            await Add(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        INIT_FAVORITES({ commit }) {
            if (localStorage.getItem('marketFavorites') != null) {
                var obj = JSON.parse(localStorage.getItem('marketFavorites'));
                commit('SET_FAVORITES', obj);
            }
            else {
                commit('SET_FAVORITES', []);
            }
        },
        ADD_TO_FAVORITES({ commit }, payload) {
            commit('ADD_FAVORITES', payload);
        },
        REMOVE_FROM_FAVORITES({ commit }, payload) {
            commit('REMOVE_FAVORITES', payload);
        },
        async GET_PRODUCTS_BY_RESIDENTIAL_COMPLEX_ID_FROM_API({ commit }, payload) {
            await GetPagedByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCTS', { succeeded: false });
                })
        },
        COMMIT_ADD_LOCATION({ commit }, payload) {
            commit('SET_ADD_LOCATION', payload);
        },
        async ADMIN_GET_PRODUCTS_BY_CATEGORY_ID_FROM_API({ commit }, payload) {
            await AdminGetPagedByCategoryId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCTS', { succeeded: false });
                })
        },
        async ADMIN_GET_PRODUCT_BY_ID_FROM_API({ commit }, payload) {
            await AdminGetById(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCT', { succeeded: false });
                })
        },
        async ADMIN_GET_FEEDBACK_FROM_API({ commit }, payload) {
            await AdminGetPagedFeedback(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCTS', { succeeded: false });
                })
        },
        async ADMIN_DELETE_FROM_API({ commit }, payload) {
            await AdminDelete(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_APPROVE_FROM_API({ commit }, payload) {
            await AdminApprove(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_GET_BY_CATEGORY_ID_EXCEL_FROM_API({ commit }, payload) {
            await AdminGetByCategoryIdExcel(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_EXCEL_GUID', { data: data, succeeded: true });
                    }
                }
            )
                .catch(() => {
                    commit('SET_EXCEL_GUID', { succeeded: false });
                })
        },
        async ADMIN_GET_FAILURES_FROM_API({ commit }) {
            await AdminGetFailures().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_FAILURES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_FAILURES', { succeeded: false });
                })
        },
        async ADMIN_GET_STATUSES_FROM_API({ commit }) {
            await AdminGetStatuses().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_STATUSES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_STATUSES', { succeeded: false });
                })
        },
        async ADMIN_UPDATE_BY_PRODUCT_ID_FROM_API({ commit }, payload) {
            await AdminUpdateByProductId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_GET_ORDER_FROM_API({ commit }, payload) {
            await AdminGetPagedOrder(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCTS', { succeeded: false });
                })
        },
        async ADMIN_UPDATE_BY_ORDER_ID_FROM_API({ commit }, payload) {
            await AdminUpdateByOrderId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_CONFIRM_BY_ORDER_ID_FROM_API({ commit }, payload) {
            await AdminConfirmByOrderId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
    }
}