<template>
  <v-container>
    <v-row v-if="info.succeeded">
      <v-col cols="12">
        <v-row
          v-for="item in info.data.groupedParameterValues"
          :key="item.parameterId"
        >
          <v-col cols="6" class="text-left">
            <h4 class="font-weight-regular grey--text">
              {{ item.parameter.name }}:
            </h4>
          </v-col>
          <v-col cols="6" class="text-left">
            <h4 class="font-weight-bold black--text">
              {{
                item.pointParameterValueItemList
                  .map((x) => x.parameterCatalog.name)
                  .join()
              }}
            </h4>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-else>
      <h4 class="font-weight-regular red--text">
        {{ stringValues.selectPoint[selectedLanguage.code] }}
      </h4>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MoreTemplate",
  computed: {
    ...mapGetters({
      getInfo: "collector/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
};
</script>
