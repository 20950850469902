export default function (instance) {
    return {
        GetProductCategories() {
            return instance.get('/ProductCategory/GetAll');
        },
        GetGlobalSearched(payload) {
            return instance.get(`/Product/GetGlobalSearched?Query=${payload.query}&RegionId=${payload.regionId}`)
        },
        GetAllRegions() {
            return instance.get('/Region/GetAll');
        },
        GetPagedByCategoryId(payload) {
            if (payload.query == null || payload.query == undefined) {
                return instance.get(`/Product/GetPagedByCategoryId?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&CategoryId=${payload.categoryId}&RegionId=${payload.regionId}&SortId=${payload.sortId}`)
            }
            else {
                return instance.get(`/Product/GetPagedByCategoryId?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&CategoryId=${payload.categoryId}&RegionId=${payload.regionId}&SortId=${payload.sortId}&Query=${payload.query}`)
            }
        },
        GetById(payload) {
            return instance.get(`/Product/GetById?Id=${payload.id}`)
        },
        GetPagedByUserId(payload) {
            return instance.get(`/Product/GetPagedByUserId?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&StatusId=${payload.statusId}`)
        },
        Delete(payload) {
            return instance.delete(`/Product/Delete?ProductId=${payload.productId}`)
        },
        MoveToArchive(payload) {
            return instance.put(`/Product/MoveToArchive?ProductId=${payload.productId}`)
        },
        GetProductCategoriesParametersByCategoryid(payload) {
            return instance.get(`/ProductCategoriesParameter/GetByCategoryId?ProductCategoryId=${payload.id}`)
        },
        Add(payload) {
            return instance.post(`/Product/Add`, payload);
        },
        GetPagedByResidentialComplexId(payload) {
            return instance.get(`/Product/GetPagedByResidentialComplexId?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&StatusId=${payload.statusId}&ResidentialComplexId=${payload.residentialComplexId}`)
        },
        AdminGetPagedByCategoryId(payload) {
            if (payload.query == null || payload.query == undefined) {
                return instance.get(`/Administrator/GetPagedByCategoryId?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&CategoryId=${payload.categoryId}&RegionId=${payload.regionId}&SortId=${payload.sortId}`)
            }
            else {
                return instance.get(`/Administrator/GetPagedByCategoryId?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&CategoryId=${payload.categoryId}&RegionId=${payload.regionId}&SortId=${payload.sortId}&Query=${payload.query}`)
            }
        },
        AdminGetById(payload) {
            return instance.get(`/Administrator/GetById?Id=${payload.id}`)
        },
        AdminGetPagedFeedback(payload) {
            return instance.get(`/Administrator/GetPagedFeedback?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`)
        },
        AdminDelete(payload) {
            return instance.delete(`/Administrator/Delete?ProductId=${payload.productId}`)
        },
        AdminApprove(payload) {
            return instance.put(`/Administrator/ApproveByProductId?ProductId=${payload.productId}`)
        },
        AdminGetByCategoryIdExcel(payload) {
            return instance.get(`/Administrator/GetByCategoryIdExcel?CategoryId=${payload.categoryId}&RegionId=${payload.regionId}&SortId=${payload.sortId}`)
        },
        AdminGetFailures() {
            return instance.get('/Failure/GetAll');
        },
        AdminGetStatuses() {
            return instance.get('/Status/GetAll');
        },
        AdminUpdateByProductId(payload) {
            return instance.put(`/Administrator/UpdateByProductId?ProductId=${payload.productId}&StatusId=${payload.statusId}&ProductFailureTypeId=${payload.failureId}&ModeratorFeedback=${payload.feedback}`)
        },
        AdminGetPagedOrder(payload) {
            return instance.get(`/Administrator/GetPagedOrder?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`)
        },
        AdminUpdateByOrderId(payload) {
            return instance.put(`/Administrator/UpdateOrderById?Id=${payload.id}&StartDate=${payload.startDate}&EndDate=${payload.endDate}&Comment=${payload.comment}`)
        },
        AdminConfirmByOrderId(payload) {
            return instance.put(`/Administrator/ConfirmOrderById?Id=${payload.id}`)
        },
    }
}
