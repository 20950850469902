import house from '../api/house/index'

const GetByUser = () => {
    return new Promise((resolve) => {
        resolve(house.house.GetByUser())
    })
}
const GetByRegionId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetByRegionId(query))
    })
}
const GetById = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetById(query))
    })
}

const GetReviewsByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetReviewsByResidentialComplexId(query))
    })
}

const GetNewsByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetNewsByResidentialComplexId(query))
    })
}

const GetDocumentsByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetDocumentsByResidentialComplexId(query))
    })
}

const GetParameters = () => {
    return new Promise((resolve) => {
        resolve(house.house.GetParameters())
    })
}

const AddUser = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddUser(query))
    })
}

const AddReview = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddReview(query))
    })
}

const CheckUserExists = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.CheckUserExists(query))
    })
}

const AdminGetPagedResidentialComplexByUserId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminGetPagedResidentialComplexByUserId(query))
    })
}

const AdminGetPagedApplianceByUserIdAndResidentialComplex = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminGetPagedApplianceByUserIdAndResidentialComplex(query))
    })
}

const AdminUpdateAppliance = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminUpdateAppliance(query))
    })
}

const AdminGetPagedNewsByUserIdAndResidentialComplex = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminGetPagedNewsByUserIdAndResidentialComplex(query))
    })
}

const AdminUpdateNews = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminUpdateNews(query))
    })
}

const AdminDeleteNews = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminDeleteNews(query))
    })
}

const AdminAddNews = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminAddNews(query))
    })
}

const AdminGetPagedDocumentsByUserIdAndResidentialComplex = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminGetPagedDocumentsByUserIdAndResidentialComplex(query))
    })
}

const AdminUpdateDocuments = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminUpdateDocuments(query))
    })
}

const AdminDeleteDocuments = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminDeleteDocuments(query))
    })
}

const AdminAddDocuments = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminAddDocuments(query))
    })
}

const AddSystemReview = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddSystemReview(query))
    })
}

const AdminGetPagedOrder = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminGetPagedOrder(query))
    })
}

const AdminUpdateByOrderId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminUpdateByOrderId(query))
    })
}

const AdminConfirmByOrderId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminConfirmByOrderId(query))
    })
}

const AdminAddPointToTop = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AdminAddPointToTop(query))
    })
}

export default {
    namespaced: true,
    state: {
        list: [],
        appliancesList: {
            pageNumber: 1,
            totalRecords: 0,
        },
        info: {
            succeeded: false
        },
        reviews: {
            pageNumber: 1,
            totalRecords: 0,
        },
        news: {
            pageNumber: 1,
            totalRecords: 0,
        },
        documents: {
            pageNumber: 1,
            totalRecords: 0,
        },
        parameters: [],
        selectedParameters: [],
        addUserStatus: null,
        addReviewStatus: null,
        isUserResidentialComplex: {
            data: false
        },
        favorites: null,
        actionStatus: null,
    },
    getters: {
        GET_LIST(state) {
            return state.list;
        },
        GET_APPLIANCES_LIST(state) {
            return state.appliancesList;
        },
        GET_INFO(state) {
            return state.info;
        },
        GET_REVIEWS(state) {
            return state.reviews;
        },
        GET_NEWS(state) {
            return state.news;
        },
        GET_DOCUMENTS(state) {
            return state.documents;
        },
        GET_PARAMETERS(state) {
            return state.parameters;
        },
        GET_SELECTED_PARAMETERS(state) {
            return state.selectedParameters;
        },
        GET_ADD_USER_STATUS(state) {
            return state.addUserStatus;
        },
        GET_ADD_REVIEW_STATUS(state) {
            return state.addReviewStatus;
        },
        GET_IS_USER_RESIDENTIAL_COMPLEX(state) {
            return state.isUserResidentialComplex;
        },
        GET_FAVORITES(state) {
            return state.favorites;
        },
        GET_ACTION_STATUS(state) {
            return state.actionStatus;
        },
    },
    mutations: {
        SET_INFO(state, payload) {
            state.info = payload;
        },
        SET_LIST(state, payload) {
            state.list = payload;
        },
        SET_APPLIANCES_LIST(state, payload) {
            state.appliancesList = payload;
        },
        SET_REVIEWS(state, payload) {
            state.reviews = payload;
        },
        SET_NEWS(state, payload) {
            state.news = payload;
        },
        SET_DOCUMENTS(state, payload) {
            state.documents = payload;
        },
        SET_PARAMETERS(state, payload) {
            state.parameters = payload;
        },
        SET_SELECTED_PARAMETERS(state, payload) {
            state.selectedParameters = payload;
        },
        SET_ADD_USER_STATUS(state, payload) {
            state.addUserStatus = payload;
        },
        SET_ADD_REVIEW_STATUS(state, payload) {
            state.addReviewStatus = payload;
        },
        SET_IS_USER_RESIDENTIAL_COMPLEX(state, payload) {
            state.isUserResidentialComplex = payload;
        },
        SET_FAVORITES(state, payload) {
            state.favorites = payload;
        },
        ADD_FAVORITES(state, payload) {
            if (state.favorites == null)
                state.favorites = [];

            state.favorites.push(payload);
            localStorage.setItem('houseFavorites', JSON.stringify(state.favorites));
        },
        REMOVE_FAVORITES(state, payload) {
            if (state.favorites == null)
                state.favorites = [];

            let index = state.favorites.map(x => x.id).indexOf(payload);
            if (index > -1) {
                state.favorites.splice(index, 1);
            }

            localStorage.setItem('houseFavorites', JSON.stringify(state.favorites));
        },
        SET_ACTION_STATUS(state, payload) {
            state.actionStatus = payload;
        },
    },
    actions: {
        async GET_LIST_BY_REGION_ID_FROM_API({ commit }, payload) {
            await GetByRegionId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LIST', { succeeded: false });
                })
        },
        async GET_INFO_BY_ID_FROM_API({ commit }, payload) {
            await GetById(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_INFO', { succeeded: false });
                })
        },
        async GET_INFO_BY_USER_FROM_API({ commit }) {
            await GetByUser().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_INFO', { succeeded: false });
                })
        },
        async GET_REVIEWS_BY_RESIDENTIAL_COMPLEX_ID_FROM_API({ commit }, payload) {
            await GetReviewsByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_REVIEWS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REVIEWS', { succeeded: false, totalRecords: 0 });
                })
        },
        async GET_NEWS_BY_RESIDENTIAL_COMPLEX_ID_FROM_API({ commit }, payload) {
            await GetNewsByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_NEWS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_NEWS', { succeeded: false, totalRecords: 0 });
                })
        },
        async GET_DOCUMENTS_BY_RESIDENTIAL_COMPLEX_ID_FROM_API({ commit }, payload) {
            await GetDocumentsByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_DOCUMENTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_DOCUMENTS', { succeeded: false, totalRecords: 0 });
                })
        },
        async GET_PARAMETERS_FROM_API({ commit }, payload) {
            await GetParameters(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PARAMETERS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PARAMETERS', { succeeded: false });
                })
        },
        COMMIT_SELECTED_PARAMETERS({ commit }, payload) {
            commit('SET_SELECTED_PARAMETERS', payload);
        },
        async ADD_USER_FROM_API({ commit }, payload) {
            await AddUser(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ADD_USER_STATUS', data);
                    }
                }
            )
                .catch((error) => {
                    commit('SET_ADD_USER_STATUS', { succeeded: false, data: error.response.data.data });
                })
        },
        async ADD_REVIEW_FROM_API({ commit }, payload) {
            await AddReview(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ADD_REVIEW_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ADD_REVIEW_STATUS', { succeeded: false, data: "Error!" });
                })
        },
        async GET_IS_USER_RESIDENTIAL_COMPLEX_FROM_API({ commit }, payload) {
            await CheckUserExists(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_IS_USER_RESIDENTIAL_COMPLEX', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_IS_USER_RESIDENTIAL_COMPLEX', { data: false });
                })
        },
        INIT_FAVORITES({ commit }) {
            if (localStorage.getItem('houseFavorites') != null) {
                var obj = JSON.parse(localStorage.getItem('houseFavorites'));
                commit('SET_FAVORITES', obj);
            }
            else {
                commit('SET_FAVORITES', []);
            }
        },
        ADD_TO_FAVORITES({ commit }, payload) {
            commit('ADD_FAVORITES', payload);
        },
        REMOVE_FROM_FAVORITES({ commit }, payload) {
            commit('REMOVE_FAVORITES', payload);
        },
        async ADMIN_GET_LIST_BY_USER_ID_FROM_API({ commit }, payload) {
            await AdminGetPagedResidentialComplexByUserId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LIST', data);
                    }
                }
            )
                .catch((error) => {
                    if (error.response.status) {
                        window.location.assign('/city');
                    }
                    commit('SET_LIST', { succeeded: false });
                })
        },
        async ADMIN_GET_APPLICANCES_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await AdminGetPagedApplianceByUserIdAndResidentialComplex(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_APPLIANCES_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_APPLIANCES_LIST', { succeeded: false });
                })
        },
        async ADMIN_UPDATE_APPLIANCE_FROM_API({ commit }, payload) {
            await AdminUpdateAppliance(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_GET_NEWS_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await AdminGetPagedNewsByUserIdAndResidentialComplex(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_APPLIANCES_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_APPLIANCES_LIST', { succeeded: false });
                })
        },
        async ADMIN_UPDATE_NEWS_FROM_API({ commit }, payload) {
            await AdminUpdateNews(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_DELETE_NEWS_FROM_API({ commit }, payload) {
            await AdminDeleteNews(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_ADD_NEWS_FROM_API({ commit }, payload) {
            await AdminAddNews(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_GET_DOCUMENTS_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await AdminGetPagedDocumentsByUserIdAndResidentialComplex(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_APPLIANCES_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_APPLIANCES_LIST', { succeeded: false });
                })
        },
        async ADMIN_UPDATE_DOCUMENTS_FROM_API({ commit }, payload) {
            await AdminUpdateDocuments(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_DELETE_DOCUMENTS_FROM_API({ commit }, payload) {
            await AdminDeleteDocuments(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_ADD_DOCUMENTS_FROM_API({ commit }, payload) {
            await AdminAddDocuments(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADD_SYSTEM_REVIEW_FROM_API({ commit }, payload) {
            await AddSystemReview(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_GET_ORDER_FROM_API({ commit }, payload) {
            await AdminGetPagedOrder(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LIST', { succeeded: false });
                })
        },
        async ADMIN_UPDATE_BY_ORDER_ID_FROM_API({ commit }, payload) {
            await AdminUpdateByOrderId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_CONFIRM_BY_ORDER_ID_FROM_API({ commit }, payload) {
            await AdminConfirmByOrderId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_ADD_POINT_TO_TOP_FROM_API({ commit }, payload) {
            await AdminAddPointToTop(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
    }
}