<template>
  <v-container>
    <v-card elevation="0" class="my-8">
      <v-card-title
        >{{ stringValues.changeAvatar[selectedLanguage.code] }}
      </v-card-title>
      <v-row class="d-flex align-center justify-center">
        <v-col cols="10" class="text-center">
          <v-file-input
            v-model="image"
            accept="image/png, image/jpeg, image/bmp, image/jpg"
            placeholder="Выберите изображения"
            prepend-icon="mdi-camera"
            :label="stringValues.image[selectedLanguage.code]"
            color="purple"
            chips
            outlined
            dense
          ></v-file-input>
          <v-btn
            class="
              mt-2
              purple
              white--text
              font-weight-regular
              rounded-lg
              text-capitalize
            "
            @click="change()"
            elevation="0"
            :loading="loading"
            :disabled="loading"
            small
          >
            {{ stringValues.apply[selectedLanguage.code] }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ChangeAvatar",
  components: {},
  computed: {
    ...mapGetters({
      getChangeStatus: "account/GET_CHANGE_STATUS",
      getLoginInfo: "account/GET_LOGIN_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    changeStatus: {
      get: function () {
        return this.getChangeStatus;
      },
    },
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
      set: function (newValue) {
        this.setLoginInfo(newValue);
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    image: null,
    imageURL: null,
    loading: false,
  }),
  methods: {
    ...mapActions({
      changeAvatarFromApi: "account/CHANGE_AVATAR_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setDialog: "common/SET_DIALOG",
      setLoginInfo: "account/SET_LOGIN_INFO",
    }),
    async imageToBase64(image) {
      const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };
      return await getBase64(image);
    },
    async change() {
      this.loading = true;
      if (this.image != null) {
        let imageBase64 = await this.imageToBase64(this.image);
        await this.changeAvatarFromApi({
          value: imageBase64.substring(imageBase64.indexOf(",") + 1),
        });
        if (this.changeStatus.succeeded) {
          this.setDialog({
            show: false,
            fullscreen: false,
            hide_overlay: true,
            transition: "dialog-bottom-transition",
            title: "Изменить фото",
            view_id: 11,
            width: 800,
          });
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          this.loginInfo.data.user.imageLink = this.changeStatus.data;
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.loading = false;
    },
  },
  async created() {},
};
</script>
