<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8">
      <v-card-title>
        <v-spacer />
        <v-btn
          small
          outlined
          color="green"
          @click="generateExcel()"
          :loading="excelLoading"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>
          Excel
        </v-btn>
      </v-card-title>
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="list.data"
            :items-per-page="20"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
          >
            <template slot="progress">
              <v-progress-linear
                color="purple"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>

            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.comment"
                            :label="
                              headers[selectedLanguage.code].find(
                                (x) => x.value == 'comment'
                              ).text
                            "
                            color="purple"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.commentTr"
                            :label="
                              headers[selectedLanguage.code].find(
                                (x) => x.value == 'commentTr'
                              ).text
                            "
                            color="purple"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.commentEn"
                            :label="
                              headers[selectedLanguage.code].find(
                                (x) => x.value == 'commentEn'
                              ).text
                            "
                            color="purple"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="purple" text @click="close">
                      {{ stringValues.cancel[selectedLanguage.code] }}
                    </v-btn>
                    <v-btn color="purple" text @click="save">
                      {{ stringValues.apply[selectedLanguage.code] }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
          <v-pagination
            circle
            v-model="list.pageNumber"
            :length="list.totalPages"
            color="purple"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import collectorInstance from "../../../api/collector/instance";
export default {
  name: "AdminCollectorPoints",
  computed: {
    ...mapGetters({
      getList: "collector/GET_LIST",
      getActionStatus: "collector/GET_ACTION_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getExcelGuid: "collector/GET_EXCEL_GUID",
    }),
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.stringValues.add[this.selectedLanguage.code]
        : this.stringValues.change[this.selectedLanguage.code];
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    excelGuid: {
      get: function () {
        return this.getExcelGuid;
      },
    },
  },
  data: () => ({
    instance: collectorInstance,
    excelLoading: false,
    loading: false,
    headers: {
      en: [
        {
          text: "Sender",
          value: "user.name",
          align: "center",
          sortable: false,
        },
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: "Category",
          value: "pointCategory.name",
          align: "center",
          sortable: false,
        },
        {
          text: "Region",
          value: "region.name",
          align: "center",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          align: "center",
          sortable: false,
        },
        {
          text: "Address",
          value: "address",
          align: "center",
          sortable: false,
        },
        {
          text: "Comment(RU)",
          value: "comment",
          align: "center",
          sortable: false,
        },
        {
          text: "Comment(TR)",
          value: "commentTr",
          align: "center",
          sortable: false,
        },
        {
          text: "Comment(EN)",
          value: "commentEn",
          align: "center",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      ru: [
        {
          text: "Отправитель",
          value: "user.name",
          align: "center",
          sortable: false,
        },
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: "Категория",
          value: "pointCategory.name",
          align: "center",
          sortable: false,
        },
        {
          text: "Регион",
          value: "region.name",
          align: "center",
          sortable: false,
        },
        {
          text: "Наименование",
          value: "name",
          align: "center",
          sortable: false,
        },
        {
          text: "Адрес",
          value: "address",
          align: "center",
          sortable: false,
        },
        {
          text: "Комментарий(RU)",
          value: "comment",
          align: "center",
          sortable: false,
        },
        {
          text: "Комментарий(TR)",
          value: "commentTr",
          align: "center",
          sortable: false,
        },
        {
          text: "Комментарий(EN)",
          value: "commentEn",
          align: "center",
          sortable: false,
        },
        {
          text: "Действия",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      tr: [
        {
          text: "Gönderen",
          value: "user.name",
          align: "center",
          sortable: false,
        },
        {
          text: "ID",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: "Kategori",
          value: "pointCategory.name",
          align: "center",
          sortable: false,
        },
        {
          text: "Bölge",
          value: "region.name",
          align: "center",
          sortable: false,
        },
        {
          text: "İsim",
          value: "name",
          align: "center",
          sortable: false,
        },
        {
          text: "Adres",
          value: "address",
          align: "center",
          sortable: false,
        },
        {
          text: "Bir yorum(RU)",
          value: "comment",
          align: "center",
          sortable: false,
        },
        {
          text: "Bir yorum(TR)",
          value: "commentTr",
          align: "center",
          sortable: false,
        },
        {
          text: "Bir yorum(EN)",
          value: "commentEn",
          align: "center",
          sortable: false,
        },
        {
          text: "Hareketler",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    },
    dialog: false,
    dialogDelete: false,
    editedItem: {
      id: 0,
      comment: "",
      commentTr: "",
      commentEn: "",
    },
    defaultItem: {
      id: 0,
      comment: "",
      commentTr: "",
      commentEn: "",
    },
    editedIndex: -1,
  }),
  methods: {
    ...mapActions({
      getListFromApi: "collector/ADMIN_GET_POINTS_FROM_API",
      updateFromApi: "collector/ADMIN_UPDATE_POINTS_FROM_API",
      getPointsExcelFromApi: "collector/ADMIN_GET_POINTS_EXCEL_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "collector/SET_APPLIANCES_LIST",
    }),
    async getItemsFromApi(pageNumber, pageSize) {
      this.loading = true;
      await this.getListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
      });
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.updateFromApi({
          id: this.editedItem.id,
          comment: this.editedItem.comment,
          commentTr: this.editedItem.commentTr,
          commentEn: this.editedItem.commentEn,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          Object.assign(this.list.data[this.editedIndex], this.editedItem);
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      }
      this.close();
    },
    async generateExcel() {
      this.excelLoading = true;
      await this.getPointsExcelFromApi();
      if (this.excelGuid.succeeded) {
        document.location.href = `${this.instance.defaults.baseURL}/Admin/Download?fileGuid=${this.excelGuid.data}`;
      }
      this.excelLoading = false;
    },
  },
  watch: {
    "list.pageNumber": function () {
      this.getItemsFromApi(this.list.pageNumber, 20);
    },
    dialog(val) {
      val || this.close();
    },
  },
  async created() {
    await this.getItemsFromApi(1, 20);
  },
};
</script>
