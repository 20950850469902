<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg">
      <v-row>
        <v-col cols="12">
          <Images />
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0" class="rounded-lg my-8 px-8 py-8">
      <v-row>
        <v-col cols="12">
          <span>{{ info.data.comment }}</span>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Images from "../Images.vue";
export default {
  name: "HouseAbout",
  components: {
    Images,
  },
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
  },
  data: () => ({}),
};
</script>
