<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title> Основная информация </v-card-title>
      <v-row v-if="info.succeeded">
        <v-col cols="12">
          <v-row>
            <v-col cols="6" class="text-center">
              <h4 class="font-weight-regular grey--text">Регион:</h4>
            </v-col>
            <v-col cols="6" class="text-left">
              <h4 class="font-weight-regular">{{ info.data.regionId }}</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-center">
              <h4 class="font-weight-regular grey--text">Адрес:</h4>
            </v-col>
            <v-col cols="6" class="text-left">
              <h4 class="font-weight-regular">{{ info.data.address }}</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-center">
              <h4 class="font-weight-regular grey--text">Описание:</h4>
            </v-col>
            <v-col cols="6" class="text-left">
              <h4 class="font-weight-regular">{{ info.data.comment }}</h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-else>
        <h4 class="font-weight-regular red--text">Выберите ЖК</h4>
      </div>
    </v-card>
    <v-card elevation="0" class="mt-8">
      <v-card-title> Дополнительная информация </v-card-title>
      <v-row v-if="info.succeeded">
        <v-col cols="12">
          <v-row
            v-for="item in info.data.groupedParameterValues"
            :key="item.parameterId"
          >
            <v-col cols="6" class="text-center">
              <h4 class="font-weight-regular grey--text">
                {{ item.parameter.name }}:
              </h4>
            </v-col>
            <v-col cols="6" class="text-left">
              <h4 class="font-weight-regular">
                {{
                  item.pointParameterValueItemList
                    .map((x) => x.parameterCatalog.name)
                    .join()
                }}
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-else>
        <h4 class="font-weight-regular red--text">Выберите ЖК</h4>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HouseMoreExtended",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getInfoByIdFromApi: "house/GET_INFO_BY_ID_FROM_API",
    }),
  },
  async created() {
    let id = parseInt(this.$route.params.id, 10);
    await this.getInfoByIdFromApi({ id: id });
  },
};
</script>
