<template>
  <v-container>
    <!-- Desktop -->
    <v-card
      elevation="0"
      class="rounded-lg my-8 grey lighten-4 hidden-sm-and-down"
    >
      <v-row>
        <v-col cols="12" class="text-center">
          <span class="purple--text darken-1 text-h6 font-weight-bold">{{
            lang == "ru" ? text.easyToFind : textEn.easyToFind
          }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col cols="12">
              <span
                class="purple--text darken-1 text-subtitle-2"
                style="font-weight: 600"
                >{{ lang == "ru" ? text.elCity : textEn.elCity }} -
              </span>
              <span class="text-subtitle-2" style="font-weight: 600">
                {{ lang == "ru" ? text.about : textEn.about }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img :src="image.search" max-height="60vh" contain> </v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="12">
              <v-img
                :src="lang == 'ru' ? image.menuRu : image.menuEn"
                max-height="25vh"
                contain
              >
              </v-img>
            </v-col>
            <v-col cols="12">
              <v-row
                class="d-flex align-center justify-center"
                v-for="(item, index) in lang == 'ru' ? listItems : listItemsEn"
                :key="index"
              >
                <v-col cols="2">
                  <v-img :src="image.ok" max-height="3vh" contain> </v-img>
                </v-col>
                <v-col cols="10">
                  <span class="text-subtitle-2" style="font-weight: 600">{{
                    item.title
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12">
              <Download :lang="lang" class="mx-auto" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- Mobile -->
    <v-card
      elevation="0"
      class="rounded-lg hidden-md-and-up"
      color="transparent"
    >
      <v-row no-gutters>
        <v-col cols="12" class="text-center">
          <span class="purple--text darken-1 text-h6 font-weight-bold">{{
            lang == "ru" ? text.easyToFind : textEn.easyToFind
          }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col cols="12">
              <span
                class="purple--text darken-1 text-subtitle-2"
                style="font-weight: 600"
                >{{ lang == "ru" ? text.elCity : textEn.elCity }} -
              </span>
              <span class="text-subtitle-2" style="font-weight: 600">
                {{ lang == "ru" ? text.about : textEn.about }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img
                :src="lang == 'ru' ? image.findRu : image.findEn"
                max-width="100%"
                contain
              >
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img :src="image.search" max-height="60vh" contain> </v-img>
            </v-col>
            <v-col cols="12">
              <v-row
                class="d-flex align-center justify-center"
                v-for="(item, index) in lang == 'ru' ? listItems : listItemsEn"
                :key="index"
              >
                <v-col cols="2">
                  <v-img :src="image.ok" max-height="4vh" contain> </v-img>
                </v-col>
                <v-col cols="10">
                  <span class="text-subtitle-2" style="font-weight: 600">{{
                    item.title
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row no-gutters class="mt-2">
            <v-col cols="12">
              <Download :lang="lang" class="mx-auto" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import Download from "./Download.vue";
export default {
  name: "PromoSearch",
  components: {
    Download,
  },
  props: {
    lang: {
      type: String,
      default() {
        return "ru";
      },
    },
  },
  data: () => ({
    image: {
      ok: require("../../assets/promo/ok.png"),
      menuRu: require("../../assets/promo/menuRu.svg"),
      menuEn: require("../../assets/promo/menuEn.svg"),
      findRu: require("../../assets/promo/findRu.svg"),
      findEn: require("../../assets/promo/findEn.svg"),
      search: require("../../assets/promo/search.png"),
      downloadRu: require("../../assets/promo/downloadRu.svg"),
      downloadEn: require("../../assets/promo/downloadEn.svg"),
    },
    listItems: [
      {
        title:
          "Находи любое место (магазин, ресторан, достопримечательность или жилой комплекс) по названию, категории или рядом с тобой.",
      },
      {
        title:
          "Смотри описание и режим работы, посещай соц сети заведения, пиши сообщения и звони.",
      },
      { title: "Ищи на карте и прокладывай маршрут." },
      {
        title:
          "Читай и оставляй отзывы, ставь оценки, рекомендуй друзьям и знакомым, делись точкой прямо из приложения.",
      },
    ],
    listItemsEn: [
      {
        title:
          "Find any place (store, restaurant, landmark, or apartment complex) by name, category, or near you.",
      },
      {
        title:
          "See the description and mode of operation, visit the institution's social networks, write messages and call.",
      },
      { title: "Look on the map and create a route." },
      {
        title:
          "Read and leave reviews, rate, recommend to friends and acquaintances, share the point directly from the app.",
      },
    ],
    text: {
      elCity: "EL-City",
      about:
        "структурированный геоинформационный справочник объектов интереса, от магазинов до музеев и достопримечательностей.",
      easyToFind: "Легко найти",
    },
    textEn: {
      elCity: "EL-City",
      about:
        "structured geoinformation directory of points of interest, from stores to museums and attractions.",
      easyToFind: "Easy to FIND",
    },
  }),
};
</script>