<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs grow center-active color="purple">
          <v-tab @click="selectStatus(1)" class="text-capitalize">{{
            stringValues.active[selectedLanguage.code]
          }}</v-tab>
          <v-tab @click="selectStatus(2)" class="text-capitalize">{{
            stringValues.pending[selectedLanguage.code]
          }}</v-tab>
          <v-tab @click="selectStatus(3)" class="text-capitalize">{{
            stringValues.archived[selectedLanguage.code]
          }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
          v-if="productList.succeeded && productList.data.length > 0"
          elevation="0"
          class="grey lighten-4"
        >
          <v-card-title>
            {{ stringValues.list[selectedLanguage.code] }}
          </v-card-title>
          <v-row v-if="loading" no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" v-for="n in 6" :key="n">
              <v-skeleton-loader
                class="mx-1 my-1 rounded-lg"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center" v-else no-gutters>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  v-for="item in productList.data"
                  :key="item.id"
                >
                  <v-card
                    @click="openAd(item.id)"
                    class="mx-1 my-1 rounded-lg"
                    elevation="0"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col cols="6" md="4" lg="4" xl="4">
                          <v-img
                            lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                            :src="
                              item.productImages.length > 0
                                ? 'https://images.el-turk.com/Resize/GetThumbnail?link=' + item.productImages[0].path
                                : 'https://cdn.el-turk.com/market/no-photo.png'
                            "
                            class="rounded-lg"
                            max-width="100%"
                            height="17vh"
                          >
                          </v-img>
                        </v-col>
                        <v-col cols="6" md="8" lg="8" xl="8">
                          <v-card elevation="0" max-height="4vh">
                            <v-card-title class="py-0 px-0">
                              <span
                                class="
                                  font-weight-bold
                                  text-caption
                                  purple--text
                                  d-inline-block
                                  text-truncate
                                "
                                style="max-width: 80%"
                              >
                                <span v-if="item.priceTypeId == 1">
                                  {{ item.price }} {{ item.currency }}
                                </span>
                                <span v-else>
                                  {{ item.priceType.name }}
                                </span></span
                              >
                            </v-card-title>
                          </v-card>
                          <v-card elevation="0" max-height="4vh">
                            <span
                              class="
                                font-weight-bold
                                d-inline-block
                                text-truncate text-caption
                              "
                              style="max-width: 100%"
                              >{{ item.name }}</span
                            >
                          </v-card>
                          <v-card elevation="0" max-height="4vh">
                            <span
                              class="
                                font-weight-regular
                                d-inline-block
                                text-truncate text-caption
                              "
                              style="max-width: 100%"
                              >{{ item.productCategory.name }}</span
                            >
                          </v-card>
                          <v-card elevation="0" max-height="4vh">
                            <span class="text-caption">{{
                              item.region.name
                            }}</span>
                          </v-card>
                          <v-card elevation="0" max-height="4vh">
                            <span class="text-caption">{{
                              new Date(item.insertedDate).toLocaleString()
                            }}</span>
                          </v-card>
                          <v-card elevation="0" max-height="4vh">
                            <v-card-actions class="pt-0">
                              <div v-if="item.sellerTypeId == 2">
                                <span>
                                  <code>{{
                                    stringValues.business[selectedLanguage.code]
                                  }}</code>
                                </span>
                              </div>
                              <v-spacer />
                              <div>
                                <v-icon small>mdi-eye</v-icon>
                                <span class="font-weight-bold text-caption">{{
                                  " " + item.viewsCount
                                }}</span>
                              </div>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        class="purple white--text rounded-lg text-capitalize"
                        x-small
                        v-if="statusId == 1"
                        elevation="0"
                        >{{ stringValues.toTop[selectedLanguage.code] }}</v-btn
                      >
                      <v-btn
                        class="red white--text rounded-lg text-capitalize"
                        x-small
                        @click="deleteMethod(item.id)"
                        elevation="0"
                        >{{ stringValues.delete[selectedLanguage.code] }}</v-btn
                      >
                      <v-btn
                        class="grey white--text rounded-lg text-capitalize"
                        x-small
                        v-if="statusId == 1"
                        @click="moveToArchiveMethod(item.id)"
                        elevation="0"
                        >{{
                          stringValues.toArchive[selectedLanguage.code]
                        }}</v-btn
                      >
                      <v-btn
                        class="green white--text rounded-lg text-capitalize"
                        x-small
                        v-if="statusId == 3"
                        elevation="0"
                        >{{
                          stringValues.restore[selectedLanguage.code]
                        }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-center">
                <v-pagination
                  circle
                  v-model="productList.pageNumber"
                  :length="productList.totalPages"
                  color="purple"
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-else elevation="0">
          <h4 class="red--text font-weight-regular text-center">
            {{ stringValues.notElements[selectedLanguage.code] }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MarketUserList",
  components: {},
  computed: {
    ...mapGetters({
      getProductList: "market/GET_PRODUCTS",
      getActionStatus: "market/GET_ACTION_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    productList: {
      get: function () {
        return this.getProductList;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    statusId: 1,
    loading: true,
  }),
  methods: {
    ...mapActions({
      getProductListByUserIdFromApi: "market/GET_PRODUCTS_BY_USER_ID_FROM_API",
      deleteFromApi: "market/DELETE_FROM_API",
      moveToArchiveFromApi: "market/MOVE_TO_ARCHIVE_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async getItemsFromApi(pageNumber, pageSize) {
      this.loading = true;
      await this.getProductListByUserIdFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        statusId: this.statusId,
      });
      this.loading = false;
    },
    selectStatus(id) {
      this.statusId = id;
      this.getItemsFromApi(1, 20);
    },
    openAd(productId) {
      this.$router.push({ path: `/market/ad/${productId}` });
    },
    async deleteMethod(item) {
      await this.deleteFromApi({
        productId: item,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
    },
    async moveToArchiveMethod(item) {
      await this.moveToArchiveFromApi({
        productId: item,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
    },
  },
  watch: {
    "productList.pageNumber": function () {
      this.getItemsFromApi(this.productList.pageNumber, 20);
    },
  },
  async created() {
    await this.getItemsFromApi(1, 20);
  },
};
</script>
