<template>
  <v-app>
    <v-app-bar app class="grey lighten-4" elevation="0" v-if="appBarShow">
      <v-menu offset-y v-if="profile != null && profile.succeeded">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            color="purple"
            size="36"
            class="mx-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-img :src="profile.data.user.imageLink"></v-img>
          </v-avatar>
        </template>
        <v-card> </v-card>
        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar>
              <img :src="profile.data.user.imageLink" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                profile.data.user.phoneNumber
              }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ profile.data.user.firstName }}
                {{ profile.data.user.lastName }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-group>
            <v-list-item @click="logOut()">
              <v-list-item-icon>
                <v-icon>mdi-account-remove-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  stringValues.exit[selectedLanguage.code]
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn v-else text fab @click="goToLogOn()">
        <v-icon color="purple">mdi-account</v-icon>
      </v-btn>
      <v-autocomplete
        v-model="selectedSearchedItem"
        :items="formattedSearchResults"
        :label="stringValues.search[selectedLanguage.code] + '...'"
        color="purple"
        type="text"
        outlined
        dense
        hide-details
        class="rounded-lg white"
        return-object
        item-text="name"
        item-value="id"
        :search-input.sync="search"
        :loading="isLoading"
        no-filter
      >
        <template slot="prepend-inner">
          <v-icon color="grey">mdi-magnify</v-icon>
        </template>
        <template v-slot:item="data">
          <template v-if="data.item.id == 0">
            <v-list-item-content>
              <v-list-item-title>
                <span
                  >{{ stringValues.allResults[selectedLanguage.code] }}:
                </span>
                <span class="purple--text">{{ data.item.name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-btn text fab @click="goToFavorites()">
        <v-icon color="purple">mdi-cards-heart-outline</v-icon>
      </v-btn>
      <v-btn text fab @click="openSelectRegionDialog()">
        <v-icon color="green">mdi-map-marker-outline</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text fab v-bind="attrs" v-on="on">
            <v-badge
              bordered
              overlap
              color="grey"
              :content="selectedLanguage.code"
            >
              <v-icon color="purple">mdi-web</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="item in languages.data"
              :key="item.id"
              @click="selectLanguage(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="grey lighten-4">
      <router-view></router-view>
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        bottom
        :color="snackbar.color"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-main>
    <v-bottom-navigation
      v-model="selectedNavigationItem"
      app
      grow
      color="purple"
      class="elevation-0"
      horizontal
      v-if="appBarShow"
    >
      <v-btn
        v-for="item in navigationItems"
        :key="item.title[selectedLanguage.code]"
        x-large
        fab
        class="white"
        :to="item.link"
      >
        <span class="text-caption font-weight-bold">{{
          item.title[selectedLanguage.code]
        }}</span>
        <v-icon color="black">{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-dialog
      v-model="dialog.show"
      :fullscreen="dialog.fullscreen"
      :hide-overlay="dialog.hide_overlay"
      :transition="dialog.transition"
      :max-width="dialog.width"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="grey">
            <v-btn icon dark @click="dialog.show = false" color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ dialog.title }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="px-3">
          <v-row class="grey lighten-4">
            <v-col cols="12">
              <HouseMore v-if="dialog.view_id == 1" />
              <HouseReviews v-else-if="dialog.view_id == 2" />
              <HouseEnter v-else-if="dialog.view_id == 3" />
              <HouseFilter v-else-if="dialog.view_id == 4" />
              <FilterTemplate v-else-if="dialog.view_id == 5" />
              <HouseApply v-else-if="dialog.view_id == 6" />
              <SelectRegion v-else-if="dialog.view_id == 7" />
              <MapCommon
                v-else-if="dialog.view_id == 8"
                :coords="dialog.coords"
                :isView="dialog.isView"
              />
              <HouseAds v-else-if="dialog.view_id == 9" />
              <ChangePassword v-else-if="dialog.view_id == 10" />
              <ChangeAvatar v-else-if="dialog.view_id == 11" />
              <ChangePersonalData v-else-if="dialog.view_id == 12" />
              <div v-else></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import HouseMore from "../src/components/House/More.vue";
import HouseReviews from "../src/components/House/Reviews.vue";
import HouseEnter from "../src/components/House/Enter.vue";
import HouseFilter from "../src/components/House/Filter.vue";
import HouseApply from "../src/components/House/Apply.vue";
import FilterTemplate from "../src/components/Collector/FilterTemplate.vue";
import stringValuesData from "../src/assets/string_values.json";
import SelectRegion from "../src/components/Common/SelectRegion.vue";
import MapCommon from "./components/Common/MapCommon.vue";
import HouseAds from "./components/House/Enter/Ads.vue";
import ChangePassword from "./components/Menu/ChangePassword.vue";
import ChangeAvatar from "./components/Menu/ChangeAvatar.vue";
import ChangePersonalData from "./components/Menu/ChangePersonalData.vue";
export default {
  name: "App",
  components: {
    HouseMore,
    HouseReviews,
    HouseEnter,
    HouseFilter,
    HouseApply,
    FilterTemplate,
    SelectRegion,
    MapCommon,
    HouseAds,
    ChangePassword,
    ChangeAvatar,
    ChangePersonalData,
  },
  computed: {
    ...mapGetters({
      getSnackbar: "common/GET_SNACKBAR",
      getLocation: "common/GET_LOCATION",
      getDialog: "common/GET_DIALOG",
      getProfile: "account/GET_LOGIN_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getLanguages: "common/GET_LANGUAGES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getGlobalSearchResults: "market/GET_GLOBAL_SEARCH_RESULTS",
      getRegions: "common/GET_REGIONS",
      getSelectedRegion: "common/GET_SELECTED_REGION",
      getAppBarShow: "common/GET_APP_BAR_SHOW",
    }),
    snackbar: {
      get: function () {
        return this.getSnackbar;
      },
    },
    location: {
      get: function () {
        return this.getLocation;
      },
      set: function (newVal) {
        this.setLocation(newVal);
      },
    },
    dialog: {
      get: function () {
        return this.getDialog;
      },
    },
    profile: {
      get: function () {
        return this.getProfile;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    languages: {
      get: function () {
        return this.getLanguages;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    globalSearchResults: {
      get: function () {
        return this.getGlobalSearchResults;
      },
    },
    regions: {
      get: function () {
        return this.getRegions;
      },
    },
    selectedRegion: {
      get: function () {
        return this.getSelectedRegion;
      },
    },
    appBarShow: {
      get: function () {
        return this.getAppBarShow;
      },
    },
  },
  data: () => ({
    navigationItems: [
      {
        title: {
          ru: "EL",
          tr: "EL",
          en: "EL",
        },
        icon: "$customEl",
        link: "/city",
      },
      {
        title: {
          ru: "Маркет",
          tr: "Pazar",
          en: "Market",
        },
        icon: "$customShoppingBag",
        link: "/market",
      },
      {
        title: {
          ru: "Дом",
          tr: "Ev",
          en: "House",
        },
        icon: "$customHome",
        link: "/house",
      },
      {
        title: {
          ru: "Меню",
          tr: "Menü",
          en: "Menu",
        },
        icon: "$customMenu",
        link: "/menu",
      },
    ],
    selectedNavigationItem: null,
    stringValuesComp: stringValuesData,
    selectedSearchedItem: null,
    formattedSearchResults: null,
    search: null,
    isLoading: false,
  }),
  methods: {
    ...mapActions({
      generateSnackbar: "common/GENERATE_SNACKBAR",
      generateLocation: "common/GENERATE_LOCATION",
      generateCollectorItems: "common/GENERATE_COLLECTOR_ITEMS",
      getLanguagesFromApi: "common/GET_LANGUAGES_FROM_API",
      commitStringValues: "common/COMMIT_STRING_VALUES",
      commitSelectedLanguage: "common/COMMIT_SELECTED_LANGUAGE",
      getGlobalSearchResultsFromApi:
        "market/GET_GLOBAL_SEARCH_RESULTS_FROM_API",
      getRegionsFromApi: "common/GET_REGIONS_FROM_API",
      getProductCategoriesFromApi: "market/GET_PRODUCT_CATEGORIES_FROM_API",
      initPointFavorites: "collector/INIT_FAVORITES",
      initMarketFavorites: "market/INIT_FAVORITES",
      initHouseFavorites: "house/INIT_FAVORITES",
      commitAppBarShow: "common/COMMIT_APP_BAR_SHOW",
    }),
    ...mapMutations({
      setLocation: "common/SET_LOCATION",
      setDialog: "common/SET_DIALOG",
    }),
    async getLocationMethod() {
      if (navigator.geolocation) {
        try {
          const getCoords = async () => {
            const pos = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            return {
              longitude: pos.coords.longitude,
              latitude: pos.coords.latitude,
            };
          };
          this.generateLocation(await getCoords());
        } catch {
          this.generateLocation({
            longitude: 0,
            latitude: 0,
          });
        }
      } else {
        this.generateLocation({
          longitude: 0,
          latitude: 0,
        });
      }
    },
    logOut() {
      localStorage.removeItem("identity");
      document.location.reload();
    },
    goToLogOn() {
      this.$router.push({ path: `/account/login` });
    },
    selectLanguage(item) {
      this.commitSelectedLanguage(item);
      document.location.reload();
    },
    async searchMethod(val) {
      this.isLoading = true;
      await this.getGlobalSearchResultsFromApi({
        query: val,
        regionId: this.selectedRegion.id,
      });

      if (this.globalSearchResults.succeeded) {
        let formattedAdsModel = this.globalSearchResults.data.ads.map((x) => ({
          id: x.adId,
          name: x.name,
          categoryName: x.productCategoryName,
          groupId: 1,
        }));

        if (formattedAdsModel.length > 0) {
          formattedAdsModel.unshift({ id: 0, name: val, groupId: 4 });
          formattedAdsModel.unshift({
            header: this.stringValues.ads[this.selectedLanguage.code],
          });
          formattedAdsModel.push({ divider: true });
        }

        let formattedRsModel = this.globalSearchResults.data.rs.map((x) => ({
          id: x.id,
          name: x.name,
          groupId: 2,
        }));

        if (formattedRsModel.length > 0) {
          formattedRsModel.unshift({
            header: this.stringValues.rc[this.selectedLanguage.code],
          });
          formattedRsModel.push({ divider: true });
        }

        let formattedPointsModel = this.globalSearchResults.data.points.map(
          (x) => ({
            id: x.pointId,
            name: x.name,
            categoryName: x.pointCategoryName,
            groupId: 3,
          })
        );

        if (formattedPointsModel.length > 0) {
          formattedPointsModel.unshift({
            header: this.stringValues.points[this.selectedLanguage.code],
          });
        }

        this.formattedSearchResults = [
          ...new Set([
            ...formattedAdsModel,
            ...formattedRsModel,
            ...formattedPointsModel,
          ]),
        ];
      }

      this.isLoading = false;
    },
    openSelectRegionDialog() {
      this.setDialog({
        show: true,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.chooseRegion[this.selectedLanguage.code],
        view_id: 7,
        width: 800,
      });
    },
    goToFavorites() {
      this.$router.push({
        path: `/favorites/point`,
      });
    },
  },
  watch: {
    search(val) {
      if (val == null || val.length == 0) return;

      this.searchMethod(val);
    },
    selectedSearchedItem() {
      switch (this.selectedSearchedItem.groupId) {
        case 1:
          this.$router.push({
            path: `/market/ad/${this.selectedSearchedItem.id}`,
          });
          break;
        case 2:
          this.$router.push({
            path: `/house/${this.selectedSearchedItem.id}`,
          });
          break;
        case 3:
          this.$router.push({
            path: `/city/all/${this.selectedSearchedItem.id}`,
          });
          break;
        case 4:
          this.$router.push({
            path: `/market/list/all/${this.selectedSearchedItem.name}`,
          });
          break;
        default:
          break;
      }
    },
  },
  async created() {
    this.commitAppBarShow(true);
    await this.getLocationMethod();
    await this.generateCollectorItems({
      food: 1,
      shop: 2,
      beauty: 3,
      government: 4,
      tourism: 5,
      hospital: 6,
      finance: 7,
      services: 8,
      carRent: 9,
      realEstate: 10,
      post: 11,
      transport: 12,
      education: 13,
      insurance: 14,
      hotels: 15,
      other: 16,
    });
    await this.getLanguagesFromApi();
    await this.getRegionsFromApi();
    await this.getProductCategoriesFromApi();
    this.commitStringValues(this.stringValuesComp);
    this.initPointFavorites();
    this.initMarketFavorites();
    this.initHouseFavorites();
  },
};
</script>