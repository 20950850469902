<template>
  <v-container>
    <v-card-text>
      <v-row>
        <v-col cols="6" class="py-0 text-center">
          <div>
            <span class="text-caption text-truncate" style="max-width: 100%">{{
              stringValues.city[selectedLanguage.code]
            }}</span>
          </div>
        </v-col>
        <v-col cols="6" class="py-0 text-center">
          <div>
            <span class="text-caption text-truncate" style="max-width: 100%">{{
              stringValues.weather[selectedLanguage.code]
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="py-0 text-center">
          <div>
            <span
              class="
                text-subtitle-2
                font-weight-bold
                d-inline-block
                text-truncate
              "
              style="max-width: 100%"
              >{{
                weather != null
                  ? weather.name
                  : stringValues.na[selectedLanguage.code]
              }}</span
            >
          </div>
          <div>
            <span class="text-caption grey--text"
              >{{ stringValues.max[selectedLanguage.code] }}:</span
            >
            <span class="purple--text text-caption">
              {{
                weather != null
                  ? parseInt(weather.main.temp_max, 10) + "°"
                  : stringValues.na[selectedLanguage.code]
              }}
            </span>
            <span class="text-caption grey--text"
              >{{ stringValues.min[selectedLanguage.code] }}.:</span
            >
            <span class="purple--text text-caption">
              {{
                weather != null
                  ? parseInt(weather.main.temp_min, 10) + "°"
                  : stringValues.na[selectedLanguage.code]
              }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" class="py-0 d-flex align-center justify-center">
          <div>
            <span class="text-h4 purple--text">{{
              weather != null
                ? parseInt(weather.main.temp, 10) + "°"
                : stringValues.na[selectedLanguage.code]
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="py-0 text-center">
          <div>
            <span class="text-caption text-truncate" style="max-width: 100%">{{
              stringValues.today[selectedLanguage.code]
            }}</span>
          </div>
        </v-col>
        <v-col cols="6" class="py-0 text-center">
          <div>
            <span class="text-caption text-truncate" style="max-width: 100%">{{
              stringValues.now[selectedLanguage.code]
            }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Weather",
  computed: {
    ...mapGetters({
      getWeather: "weather/GET_WEATHER",
      getLocation: "common/GET_LOCATION",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    weather: {
      get: function () {
        return this.getWeather;
      },
    },
    location: {
      get: function () {
        return this.getLocation;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getWeatherFromApi: "weather/GET_WEATHER_FROM_API",
    }),
    async getWeatherMethod() {
      await this.getWeatherFromApi({
        latitude: this.location.latitude,
        longitude: this.location.longitude,
      });
    },
  },
  watch: {
    location: function () {
      if (this.location != null) {
        this.getWeatherMethod();
      }
    },
  },
  async created() {
    if (this.location != null) {
      await this.getWeatherMethod();
    }
  },
};
</script>