import chat from '../api/chat/houseChat/index'

const GetPreviewMessages = (query) => {
    return new Promise((resolve) => {
        resolve(chat.chat.GetPreviewMessages(query))
    })
}

const GetPagedChat = (query) => {
    return new Promise((resolve) => {
        resolve(chat.chat.GetPagedChat(query))
    })
}

export default {
    namespaced: true,
    state: {
        previewMessages: null,
        chat: null,
        pagingStatus: true
    },
    getters: {
        GET_PREVIEW_MESSAGES(state) {
            return state.previewMessages;
        },
        GET_CHAT(state) {
            if (state.chat != null) {
                state.chat.data.messages.sort(function (a, b) {
                    if (a.timeOfMessage > b.timeOfMessage) {
                        return 1;
                    }
                    if (a.timeOfMessage < b.timeOfMessage) {
                        return -1;
                    }
                    return 0;
                })
            }
            return state.chat;
        },
        GET_PAGING_STATUS(state) {
            return state.pagingStatus;
        }
    },
    mutations: {
        SET_PREVIEW_MESSAGES(state, payload) {
            state.previewMessages = payload;
        },
        SET_CHAT(state, payload) {
            state.chat = payload;
            if (payload.succeeded) {
                if (payload.data.messages.length != 0) {
                    if (payload.data.messages.length == 100)
                        state.pagingStatus = true;
                    else
                        state.pagingStatus = false;
                }
                else {
                    state.pagingStatus = false;
                }
            }
        },
        ADD_TO_CHAT(state, payload) {
            if (payload.succeeded) {
                if (payload.data.messages.length != 0) {
                    state.chat.data.messages = [
                        ...new Set([
                            ...state.chat.data.messages,
                            ...payload.data.messages,
                        ]),
                    ];
                    if (payload.data.messages.length == 100)
                        state.pagingStatus = true;
                    else
                        state.pagingStatus = false;
                }
                else {
                    state.pagingStatus = false;
                }
            }
        },

    },
    actions: {
        async GET_PREVIEW_MESSAGES_FROM_API({ commit }, payload) {
            await GetPreviewMessages(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PREVIEW_MESSAGES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PREVIEW_MESSAGES', { succeeded: false });
                })
        },
        async GET_CHAT_FROM_API({ commit }, payload) {
            await GetPagedChat(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_CHAT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_CHAT', { succeeded: false });
                })
        },
        async GET_CHAT_PAGING_FROM_API({ commit }, payload) {
            await GetPagedChat(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('ADD_TO_CHAT', data);
                    }
                }
            )
                .catch(() => {
                    commit('ADD_TO_CHAT', { succeeded: false });
                })
        },
    }
}
