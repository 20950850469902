<template>
  <v-container>
    <v-card
      v-if="productList.succeeded && productList.data.length > 0"
      elevation="0"
      class="grey lighten-4"
    >
      <v-card-title>
        {{ stringValues.hotDeals[selectedLanguage.code] }}
      </v-card-title>
      <v-row v-if="loading" no-gutters>
        <v-col cols="12" md="6" lg="6" xl="6" v-for="n in 6" :key="n">
          <v-skeleton-loader
            class="mx-1 my-1 rounded-lg"
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center" v-else no-gutters>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              v-for="item in productList.data"
              :key="item.id"
            >
              <v-card
                @click="openAd(item.id)"
                class="mx-1 my-1 rounded-lg"
                elevation="0"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="6" md="4" lg="4" xl="4">
                      <v-img
                        lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                        :src="
                          item.productImages.length > 0
                            ? 'https://images.el-turk.com/Resize/GetThumbnail?link=' + item.productImages[0].path
                            : 'https://cdn.el-turk.com/market/no-photo.png'
                        "
                        class="rounded-lg"
                        max-width="100%"
                        height="17vh"
                      >
                      </v-img>
                    </v-col>
                    <v-col cols="6" md="8" lg="8" xl="8">
                      <v-card elevation="0" max-height="4vh">
                        <v-card-title class="py-0 px-0">
                          <span
                            class="
                              font-weight-bold
                              text-caption
                              purple--text
                              d-inline-block
                              text-truncate
                            "
                            style="max-width: 80%"
                          >
                            <span v-if="item.priceTypeId == 1">
                              {{ item.price }} {{ item.currency }}
                            </span>
                            <span v-else>
                              {{ item.priceType.name }}
                            </span></span
                          >
                          <v-spacer />
                          <v-icon
                            color="purple"
                            @click.stop="addToFavorites(item)"
                            v-if="!favorites.some((x) => x.id == item.id)"
                            >mdi-cards-heart-outline</v-icon
                          >
                          <v-icon
                            color="purple"
                            @click.stop="removeFromFavorites(item.id)"
                            v-else
                            >mdi-cards-heart</v-icon
                          >
                        </v-card-title>
                      </v-card>
                      <v-card elevation="0" max-height="4vh">
                        <span
                          class="
                            font-weight-bold
                            d-inline-block
                            text-truncate text-caption
                          "
                          style="max-width: 90%"
                          >{{ item.name }}</span
                        >
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 10%"
                          v-if="item.isInTop"
                        >
                          <v-icon small color="red">mdi-fire</v-icon>
                        </span>
                      </v-card>
                      <v-card elevation="0" max-height="4vh">
                        <span
                          class="
                            font-weight-regular
                            d-inline-block
                            text-truncate text-caption
                          "
                          style="max-width: 100%"
                          >{{ item.productCategory.name }}</span
                        >
                      </v-card>
                      <v-card elevation="0" max-height="4vh">
                        <span class="text-caption">{{ item.region.name }}</span>
                      </v-card>
                      <v-card elevation="0" max-height="4vh">
                        <span class="text-caption">{{
                          new Date(item.insertedDate).toLocaleString()
                        }}</span>
                      </v-card>
                      <v-card elevation="0" max-height="4vh">
                        <v-card-actions class="pt-0">
                          <div v-if="item.sellerTypeId == 2">
                            <span>
                              <code>{{
                                stringValues.business[selectedLanguage.code]
                              }}</code>
                            </span>
                          </div>
                          <v-spacer />
                          <div>
                            <v-icon small>mdi-eye</v-icon>
                            <span class="font-weight-bold text-caption">{{
                              " " + item.viewsCount
                            }}</span>
                          </div>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else elevation="0">
      <h4 class="red--text font-weight-regular text-center">
        {{ stringValues.notElements[selectedLanguage.code] }}
      </h4>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CityAds",
  props: {
    productCategoryId: {
      type: Number,
      default() {
        return 8;
      },
    },
  },
  components: {},
  computed: {
    ...mapGetters({
      getProductList: "market/GET_PRODUCTS",
      getSelectedRegion: "common/GET_SELECTED_REGION",
      getFavorites: "market/GET_FAVORITES",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    productList: {
      get: function () {
        return this.getProductList;
      },
    },
    selectedRegion: {
      get: function () {
        return this.getSelectedRegion;
      },
    },
    favorites: {
      get: function () {
        return this.getFavorites;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    sortId: 1,
    loading: true,
  }),
  methods: {
    ...mapActions({
      getProductListByCategoryIdFromApi:
        "market/GET_PRODUCTS_BY_CATEGORY_ID_FROM_API",
      addToFavoritesApi: "market/ADD_TO_FAVORITES",
      removeFromFavoritesApi: "market/REMOVE_FROM_FAVORITES",
    }),
    openAd(productId) {
      this.$router.push({ path: `/market/ad/${productId}` });
    },
    async getItemsFromApi(pageNumber, pageSize) {
      this.loading = true;
      await this.getProductListByCategoryIdFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        categoryId: this.productCategoryId,
        regionId: this.selectedRegion.id,
        sortId: this.sortId,
      });
      this.loading = false;
    },
    addToFavorites(item) {
      let model = {
        id: item.id,
        name: item.name,
        price: item.price,
        currency: item.currency,
        productImage:
          item.productImages.length > 0
            ? item.productImages[0].path
            : "https://cdn.el-turk.com/market/no-photo.png",
        priceTypeId: item.priceTypeId,
        priceType: { name: item.priceType.name },
        productCategory: { name: item.productCategory.name },
        region: { name: item.region.name },
        insertedDate: item.insertedDate,
        sellerTypeId: item.sellerTypeId,
        viewsCount: item.viewsCount,
      };
      this.addToFavoritesApi(model);
    },
    removeFromFavorites(id) {
      this.removeFromFavoritesApi(id);
    },
  },
  async created() {
    this.getItemsFromApi(1, 10);
  },
};
</script>