<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          class="purple white--text mx-2 my-2 rounded-lg"
          @click="goToUserList()"
          width="170"
          small
          elevation="0"
          ><span class="text-capitalize">{{
            stringValues.myAds[selectedLanguage.code]
          }}</span>
        </v-btn>
        <v-btn
          class="purple white--text mx-2 my-2 text-capitalize rounded-lg"
          @click="goToInsertAdd()"
          width="200"
          small
          elevation="0"
          >{{ stringValues.newAd[selectedLanguage.code] }}</v-btn
        >
        <v-btn
          class="green white--text mx-2 my-2 text-capitalize rounded-lg"
          width="150"
          @click="goToMessages()"
          small
          elevation="0"
          >{{ stringValues.messages[selectedLanguage.code] }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="rounded-lg">
          <ProductCategoryList />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="rounded-lg mt-2 mb-8 grey lighten-4">
          <List :productCategoryId="8" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import ProductCategoryList from "./ProductCategoryList.vue";
import List from "./List.vue";
export default {
  name: "MarketMain",
  components: {
    ProductCategoryList,
    List,
  },
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    fab: false,
  }),
  metaInfo() {
    return {
      title: this.stringValues.market[this.selectedLanguage.code],
      titleTemplate: "%s | EasyLife",
    };
  },
  methods: {
    goToUserList() {
      this.$router.push({
        path: "/market/userList/all",
      });
    },
    goToInsertAdd() {
      this.$router.push({
        path: "/market/userList/add",
      });
    },
    goToMessages() {
      this.$router.push({
        path: "/market/chat/all",
      });
    },
  },
  async created() {},
};
</script>
