<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8 grey lighten-4">
      <v-card-title>
        <span>{{ stringValues.pointsAdminCP[selectedLanguage.code] }}</span>
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-tabs grow center-active color="purple" v-model="selectedItem">
            <v-tab>{{ stringValues.points[selectedLanguage.code] }}</v-tab>
            <v-tab>{{ stringValues.secure[selectedLanguage.code] }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Points v-if="selectedItem == 0" :key="selectedItem" />
          <Orders v-if="selectedItem == 1" :key="selectedItem" />
          <div v-else></div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Points from "./Points.vue";
import Orders from "./Orders.vue";
export default {
  name: "MainAdministratorPoints",
  components: {
    Points,
    Orders,
  },
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    selectedItem: 0,
  }),
  methods: {},
  async created() {},
};
</script>
