<template>
  <v-container>
    <!-- Desktop -->
    <v-card elevation="0" class="rounded-xl my-4 hidden-sm-and-down">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="4" md="2" lg="2" xl="2">
          <v-img :src="image.logo" max-height="10vh" contain> </v-img>
        </v-col>
        <v-col cols="8" md="2" lg="2" xl="2">
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <span class="text-h5 font-weight-bold" style="color: #4dcc1d">{{
                text.title
              }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <span class="purple--text text-subtitle-2 font-weight-bold">{{
                lang == "ru" ? text.subtitle : text.subtitleEn
              }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="0" md="5" lg="5" xl="5" class="hidden-sm-and-down">
          <v-row>
            <v-col cols="6">
              <v-list-item @click="goToInstagram(text.instagram)">
                <v-list-item-icon>
                  <v-img :src="image.instagram" contain></v-img>
                </v-list-item-icon>
                <v-list-item-title>
                  <span class="text-caption" style="font-weight: 600">{{
                    text.instagram
                  }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item @click="goToSite(text.url)">
                <v-list-item-icon>
                  <v-img :src="image.url" contain></v-img>
                </v-list-item-icon>
                <v-list-item-title>
                  <span class="text-caption" style="font-weight: 600">{{
                    text.url
                  }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="0" md="3" lg="3" xl="3" class="hidden-sm-and-down">
          <Download :lang="lang" />
        </v-col>
      </v-row>
    </v-card>

    <!-- Mobile -->
    <v-card
      elevation="0"
      class="rounded-xl hidden-md-and-up"
      color="transparent"
    >
      <v-row class="d-flex align-center justify-center">
        <v-col cols="4" md="2" lg="2" xl="2">
          <v-img :src="image.logo" max-height="15vh" contain> </v-img>
        </v-col>
        <v-col cols="8" md="2" lg="2" xl="2">
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <span class="text-h5 font-weight-bold" style="color: #4dcc1d">{{
                text.title
              }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <span class="purple--text text-subtitle-2 font-weight-bold">{{
                lang == "ru" ? text.subtitle : text.subtitleEn
              }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import Download from "./Download.vue";
export default {
  name: "PromoHeader",
  components: {
    Download,
  },
  props: {
    lang: {
      type: String,
      default() {
        return "ru";
      },
    },
  },
  data: () => ({
    image: {
      logo: require("../../assets/promo/logo.png"),
      downloadRu: require("../../assets/promo/downloadRu.svg"),
      downloadEn: require("../../assets/promo/downloadEn.svg"),
      instagram: require("../../assets/promo/instagram.svg"),
      url: require("../../assets/promo/url.svg"),
      email: require("../../assets/promo/email.svg"),
    },
    text: {
      title: "EasyLife",
      subtitle: "Лучшее приложение для иностранцев в Турции",
      subtitleEn: "The Best App for foreigners in Turkey",
      instagram: "easylife_turkiye",
      url: "https://el-turk.com",
      email: "info@el-turk.com",
    },
  }),
  methods: {
    goToInstagram(login) {
      window.open(`https://instagram.com/${login}`, "_blank");
    },
    goToSite(url) {
      window.open(url, "_blank");
    },
  },
};
</script>