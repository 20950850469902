<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8 grey lighten-4">
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <div v-if="favorites.length > 0">
            <v-row no-gutters>
              <v-col
                cols="12"
                md="6"
                lg="6"
                xl="6"
                v-for="item in favorites"
                :key="item.id"
              >
                <v-card
                  @click="open(item.id)"
                  class="mx-1 my-1 rounded-lg"
                  elevation="0"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" md="4" lg="4" xl="4">
                        <v-img
                          lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                          :src="item.productImage"
                          class="rounded-lg"
                          max-width="100%"
                          height="17vh"
                        >
                        </v-img>
                      </v-col>
                      <v-col cols="6" md="8" lg="8" xl="8">
                        <v-card elevation="0" max-height="3vh">
                          <v-card-title class="py-0 px-0">
                            <span
                              class="
                                font-weight-bold
                                text-caption
                                purple--text
                                d-inline-block
                                text-truncate
                              "
                              style="max-width: 80%"
                            >
                              <span v-if="item.priceTypeId == 1">
                                {{ item.price }} {{ item.currency }}
                              </span>
                              <span v-else>
                                {{ item.priceType.name }}
                              </span></span
                            >
                            <v-spacer />
                            <v-icon
                              color="purple"
                              @click.stop="removeFromFavorites(item.id)"
                              >mdi-cards-heart</v-icon
                            >
                          </v-card-title>
                        </v-card>
                        <v-card elevation="0" max-height="4vh">
                          <span
                            class="
                              font-weight-bold
                              d-inline-block
                              text-truncate text-caption
                            "
                            style="max-width: 100%"
                            >{{ item.name }}</span
                          >
                        </v-card>
                        <v-card elevation="0" max-height="4vh">
                          <span
                            class="
                              font-weight-regular
                              d-inline-block
                              text-truncate text-caption
                            "
                            style="max-width: 100%"
                            >{{ item.productCategory.name }}</span
                          >
                        </v-card>
                        <v-card elevation="0" max-height="4vh">
                          <span class="text-caption">{{
                            item.region.name
                          }}</span>
                        </v-card>
                        <v-card elevation="0" max-height="4vh">
                          <span class="text-caption">{{
                            new Date(item.insertedDate).toLocaleString()
                          }}</span>
                        </v-card>
                        <v-card elevation="0" max-height="4vh">
                          <v-card-actions class="pt-0">
                            <div v-if="item.sellerTypeId == 2">
                              <span>
                                <code>{{
                                  stringValues.business[selectedLanguage.code]
                                }}</code>
                              </span>
                            </div>
                            <v-spacer />
                            <div>
                              <v-icon small>mdi-eye</v-icon>
                              <span class="font-weight-bold text-caption">{{
                                " " + item.viewsCount
                              }}</span>
                            </div>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-else class="text-center">
            <h4 class="red--text font-weight-regular">
              {{ stringValues.notElements[selectedLanguage.code] }}
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FavoritesMarket",
  components: {},
  computed: {
    ...mapGetters({
      getFavorites: "market/GET_FAVORITES",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    favorites: {
      get: function () {
        return this.getFavorites;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      addToFavoritesApi: "market/ADD_TO_FAVORITES",
      removeFromFavoritesApi: "market/REMOVE_FROM_FAVORITES",
    }),
    removeFromFavorites(id) {
      this.removeFromFavoritesApi(id);
    },
    open(id) {
      this.$router.push({ path: `/market/ad/${id}` });
    },
  },
  async created() {},
};
</script>
