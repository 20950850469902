<template>
  <v-container>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div v-if="parameters.succeeded">
            <!-- <div class="text-center">
              <v-btn
                small
                elevation="1"
                tile
                class="purple white--text my-1"
                @click="apply()"
              >
                Применить
              </v-btn>
            </div> -->
            <template v-for="parameter in parameters.data">
              <v-card elevation="0" :key="parameter.id">
                <v-card-subtitle class="font-weight-bold black--text">
                  {{ parameter.name }}
                </v-card-subtitle>
                <v-card-text>
                  <v-list-item
                    dense
                    v-for="item in parameter.parameterCatalogs"
                    :key="item.id"
                  >
                    <v-list-item-title>
                      <span
                        class="text-subtitle-2 font-weight-regular black--text"
                        >{{ item.name }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-checkbox
                        on-icon="mdi-hand-peace"
                        off-icon="mdi-hand-peace"
                        color="green"
                        hide-details
                        :value="item"
                        v-model="selected"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card-text>
              </v-card>
              <v-divider :key="'dvd_' + parameter.id" />
            </template>
            <div class="text-center">
              <v-btn
                width="100%"
                small
                elevation="0"
                class="purple white--text my-1 text-capitalize"
                @click="apply()"
              >
                {{ stringValues.apply[selectedLanguage.code] }}
              </v-btn>
            </div>
          </div>
          <div v-else>{{ stringValues.error[selectedLanguage.code] }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "HouseFilter",
  computed: {
    ...mapGetters({
      getParameters: "house/GET_PARAMETERS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    parameters: {
      get: function () {
        return this.getParameters;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    selected: [],
  }),
  methods: {
    ...mapActions({
      getParametersFromApi: "house/GET_PARAMETERS_FROM_API",
      commitSelectedParameters: "house/COMMIT_SELECTED_PARAMETERS",
    }),
    ...mapMutations({
      setDialog: "common/SET_DIALOG",
    }),
    apply() {
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      let uniqueParameterIds = this.selected
        .map((x) => x.parameterId)
        .filter(onlyUnique);

      let resultModel = [];
      for (var i = 0; i < uniqueParameterIds.length; i++) {
        let model = {
          id: uniqueParameterIds[i],
          parameterCatalogs: this.selected.filter(
            (x) => x.parameterId == uniqueParameterIds[i]
          ),
        };
        resultModel.push(model);
      }

      this.commitSelectedParameters(resultModel);

      this.setDialog({
        show: false,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: "Фильтры",
        view_id: 4,
        width: 800,
      });
    },
  },
  async created() {
    await this.getParametersFromApi();
  },
};
</script>