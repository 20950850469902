<template>
  <v-container>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-autocomplete
            v-model="selectedId"
            :items="list.data"
            :label="stringValues.changeSelection[selectedLanguage.code]"
            item-text="name"
            item-value="id"
            hide-details
            color="purple"
          >
            <template v-slot:append-outer>
              <v-btn
                small
                @click="openFilterDialog()"
                fab
                text
                color="purple"
                elevation="0"
              >
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ResidentialComplexSelect",
  computed: {
    ...mapGetters({
      getList: "house/GET_LIST",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    list: {
      get: function () {
        return this.getList;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    selectedId: null,
  }),
  methods: {
    ...mapActions({
      getInfoByIdFromApi: "house/GET_INFO_BY_ID_FROM_API",
    }),
    ...mapMutations({
      setDialog: "common/SET_DIALOG",
    }),
    openFilterDialog() {
      this.setDialog({
        show: true,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.filters[this.selectedLanguage.code],
        view_id: 4,
        width: 800,
      });
    },
  },
  watch: {
    selectedId: function () {
      this.getInfoByIdFromApi({ id: this.selectedId });
    },
  },
};
</script>