<template>
  <v-container>
    <v-row v-if="info.succeeded">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="item in info.data.workHours" :key="item.dayId">
                <td class="text-center">{{ item.day.name }}</td>
                <td class="text-center">
                  <div>{{ item.startTime }}</div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <div v-else>
      <h4 class="font-weight-regular red--text">
        {{ stringValues.selectPoint[selectedLanguage.code] }}
      </h4>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "WorkhourTemplate",
  computed: {
    ...mapGetters({
      getInfo: "collector/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
};
</script>
