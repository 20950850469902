<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg" v-if="chat != null">
      <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
          <!-- Active Chat -->
          <v-card elevation="0">
            <!-- User Info  -->
            <v-card-title>
              <span>{{ chat.data.companionName }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <!-- Chat  -->
            <v-card
              height="500"
              style="overflow-y: auto"
              id="chatHistory"
              class="pr-2 pl-2"
              @scroll.passive="OnScroll"
              fill-height
            >
              <v-row v-if="pagingStatus">
                <v-col cols="12">
                  <v-hover v-slot="{ hover }">
                    <v-alert
                      dense
                      class="purple white--text rounded-lg"
                      tile
                      :elevation="hover ? 8 : 0"
                      @click="getMessagesByPage()"
                    >
                      {{ stringValues.loadMore[selectedLanguage.code] }}
                    </v-alert>
                  </v-hover>
                </v-col>
              </v-row>
              <v-timeline>
                <v-timeline-item
                  v-for="(msg, index) in chat.data.messages"
                  :key="index"
                  :left="!msg.isFromMe"
                  :right="msg.isFromMe"
                  hide-dot
                >
                  <v-alert class="text--purple" elevation="2" dense>
                    <div class="text-caption">
                      {{ msg.msg }}
                    </div>
                    <div class="text-right">
                      <v-chip x-small>
                        {{
                          new Date(msg.timeOfMessage).toLocaleTimeString([], {
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }}
                      </v-chip>
                    </div>
                  </v-alert>
                </v-timeline-item>
              </v-timeline>
            </v-card>
            <!-- Send Message  -->
            <v-card-actions>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="stringValues.enterMessage[selectedLanguage.code]"
                    outlined
                    append-icon="mdi-send-outline"
                    clearable
                    counter="125"
                    v-model="outMessage"
                    @click:append="SendMessage()"
                    @keyup.enter="SendMessage()"
                    color="purple"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MarketDialog",
  components: {},
  computed: {
    ...mapGetters({
      getChat: "marketChat/GET_CHAT",
      getConnection: "hub/GET_CONNECTION",
      getPagingStatus: "marketChat/GET_PAGING_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    chat: {
      get: function () {
        return this.getChat;
      },
      set: function (newVal) {
        this.setChat(newVal);
      },
    },
    connection: {
      get: function () {
        return this.getConnection;
      },
    },
    pagingStatus: {
      get: function () {
        return this.getPagingStatus;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    outMessage: null,
    fab: false,
    pageNumber: 1,
    companionId: null,
  }),
  methods: {
    ...mapActions({
      getChatFromApi: "marketChat/GET_CHAT_FROM_API",
      connectToHub: "hub/CONNECT",
      getChatPagingFromApi: "marketChat/GET_CHAT_PAGING_FROM_API",
    }),
    ...mapMutations({
      setChat: "marketChat/SET_CHAT",
    }),
    async openDialog() {
      await this.getChatFromApi({
        pageNumber: 1,
        pageSize: 100,
        toUserId: this.companionId,
      });
      this.ScrollToEnd();
      this.pageNumber = 1;
    },
    SendMessage() {
      if (this.outMessage != null && this.outMessage.length > 0) {
        this.connection.invoke("Send", this.outMessage, this.companionId);
        let timeMessage = new Date();
        let newMessage = {
          isFromMe: true,
          msg: this.outMessage,
          timeOfMessage: timeMessage,
        };
        this.chat.data.messages.push(newMessage);

        this.outMessage = "";
        this.ScrollToEnd();
      }
    },
    ReceiveMessage(message, userName) {
      if (this.chat.data.companionName == userName) {
        let timeMessage = new Date();
        let newMessage = {
          isFromMe: false,
          msg: message,
          timeOfMessage: timeMessage,
        };

        this.chat.data.messages.push(newMessage);
        this.ScrollToEnd();
      }
    },
    OnScroll() {
      var container = this.$el.querySelector("#chatHistory");
      this.fab = container.scrollHeight - container.scrollTop > 500;
    },

    ScrollToEnd() {
      setTimeout(() => {
        var container = this.$el.querySelector("#chatHistory");
        container.scrollTop = container.scrollHeight;
      });
    },
    async getMessagesByPage() {
      await this.getChatPagingFromApi({
        pageNumber: ++this.pageNumber,
        pageSize: 100,
        toUserId: this.companionId,
      });
    },
  },
  async created() {
    this.companionId = this.$route.params.companionId;

    await this.openDialog();

    this.connectToHub("https://marketChat.el-turk.com/chat");
    this.connection.on("Receive", this.ReceiveMessage);
  },
};
</script>
