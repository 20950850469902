<template>
  <v-container>
    <div v-if="product.succeeded">
      <v-card outlined tile>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="5" lg="5" xl="5">
              <div>
                <v-carousel>
                  <v-carousel-item
                    v-for="(item, i) in product.data.productImages"
                    :key="i"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      :src="item.path"
                      contain
                      height="100%"
                      width="100%"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>
            <v-col cols="12" md="7" lg="7" xl="7">
              <v-card class="mx-2 my-2 rounded-lg" :elevation="0" width="100%">
                <v-card-text>
                  <div>
                    <span class="font-weight-bold purple--text">
                      <div v-if="product.data.priceTypeId == 1">
                        {{ product.data.price }} {{ product.data.currency }}
                      </div>
                      <div v-else>
                        {{ product.data.priceType.name }}
                      </div>
                    </span>
                  </div>
                  <div class="my-1">
                    <span class="font-weight-bold">{{
                      product.data.name
                    }}</span>
                  </div>
                  <div class="my-1">
                    <div>
                      <v-list>
                        <v-list-item two-line>
                          <v-list-item-avatar>
                            <img :src="product.data.user.imageLink" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="text-caption">{{
                              stringValues.salesMan[selectedLanguage.code]
                            }}</v-list-item-title>
                            <v-list-item-subtitle class="font-weight-bold"
                              >{{ product.data.user.firstName }}
                              {{
                                product.data.user.lastName
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>
                  <div class="my-1">
                    <v-icon>mdi-phone</v-icon>
                    <span>{{ product.data.user.phoneNumber }}</span>
                  </div>
                  <div class="my-1">
                    <v-icon>mdi-map-marker</v-icon>
                    <span>{{ product.data.region.name }}</span>
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span>{{ stringValues.options[selectedLanguage.code] }}</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-row
                        v-for="item in product.data.groupedParameterValues"
                        :key="item.parameterId"
                      >
                        <v-col cols="6" class="text-left">
                          <h4 class="font-weight-regular grey--text">
                            {{ item.parameter.name }}:
                          </h4>
                        </v-col>
                        <v-col cols="6" class="text-left">
                          <h4 class="font-weight-bold black--text">
                            {{
                              item.productParameterValueItemList
                                .map((x) => x.parameterCatalog.name)
                                .join()
                            }}
                          </h4>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span>{{
                    stringValues.description[selectedLanguage.code]
                  }}</span>
                </v-card-title>
                <v-card-text>
                  <div v-if="!viewFullComment">
                    <span
                      class="font-weight-regular d-inline-block text-truncate"
                      style="max-width: 100%"
                    >
                      {{ product.data.comment }}
                    </span>
                    <div>
                      <v-btn
                        x-small
                        text
                        color="purple"
                        class="px-0"
                        @click="viewFullComment = !viewFullComment"
                        >{{
                          stringValues.showAll[selectedLanguage.code]
                        }}...</v-btn
                      >
                    </div>
                  </div>
                  <div v-else>
                    <span class="font-weight-regular">
                      {{ product.data.comment }}
                    </span>
                    <div>
                      <v-btn
                        x-small
                        text
                        color="purple"
                        class="px-0"
                        @click="viewFullComment = !viewFullComment"
                        >{{
                          stringValues.showPartially[selectedLanguage.code]
                        }}...</v-btn
                      >
                    </div>
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span>{{
                    stringValues.location[selectedLanguage.code]
                  }}</span>
                </v-card-title>
                <v-card-text>
                  {{ stringValues.address[selectedLanguage.code] }}:
                  {{ product.data.address }}
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span class="black--text font-weight-bold">{{
                    stringValues.additionalInformation[selectedLanguage.code]
                  }}</span>
                </v-card-title>
                <v-card-text>
                  {{ stringValues.prefferedLanguage[selectedLanguage.code] }}:
                  <span class="black--text font-weight-bold">{{
                    product.data.prefferedLanguage
                  }}</span>
                  <div>
                    <v-btn
                      x-small
                      text
                      color="primary"
                      class="px-0"
                      @click="openMapDialog()"
                      >{{
                        stringValues.showOnMap[selectedLanguage.code]
                      }}</v-btn
                    >
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span>{{ stringValues.status[selectedLanguage.code] }}</span>
                </v-card-title>
                <v-card-text>
                  {{ stringValues.status[selectedLanguage.code] }}:
                  {{ product.data.status.name }}
                </v-card-text>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col cols="8">
                      <div>
                        <span>{{
                          new Date(product.data.insertedDate).toLocaleString()
                        }}</span>
                      </div>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <div>
                        <v-icon small>mdi-eye</v-icon>
                        <span class="font-weight-bold text-caption">{{
                          " " + product.data.viewsCount
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-text class="text-center">
                  <v-row>
                    <v-col cols="12" md="6" lg="6" xl="6">
                      <v-select
                        v-model="selectedFailureId"
                        @value="id"
                        :items="failures.data"
                        :label="stringValues.error[selectedLanguage.code]"
                        outlined
                        dense
                        color="purple"
                        item-text="message"
                        item-value="id"
                        hide-details
                        class="my-2"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" xl="6">
                      <v-select
                        v-model="selectedStatusId"
                        @value="id"
                        :items="statuses.data"
                        :label="stringValues.status[selectedLanguage.code]"
                        outlined
                        dense
                        color="purple"
                        item-text="name"
                        item-value="id"
                        hide-details
                        class="my-2"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        v-model="feedback"
                        :label="stringValues.comment[selectedLanguage.code]"
                        color="purple"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn
                        small
                        class="green white--text mx-2 rounded-lg text-capitalize"
                        elevation="0"
                        @click="updateByProductId(product.data.id)"
                        >{{ stringValues.apply[selectedLanguage.code] }}</v-btn
                      >
                    </v-col>
                  </v-row>
                  <!-- <div v-if="product.data.statusId === 1">
                    <v-btn
                      small
                      class="red white--text mx-2 rounded-lg text-capitalize"
                      elevation="0"
                      @click="deleteMethod(product.data.id)"
                      >{{ stringValues.delete[selectedLanguage.code] }}</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-btn
                      small
                      class="green white--text mx-2 rounded-lg text-capitalize"
                      elevation="0"
                      @click="approve(product.data.id)"
                      >{{ stringValues.approve[selectedLanguage.code] }}</v-btn
                    >
                  </div> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <h4 class="red--text font-weight-regular">
        {{ stringValues.error[selectedLanguage.code] }}
      </h4>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "AdminMarketCategoryProductsDetails",
  components: {},
  computed: {
    ...mapGetters({
      getProduct: "market/GET_PRODUCT",
      getActionStatus: "market/GET_ACTION_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getFailures: "market/GET_FAILURES",
      getStatuses: "market/GET_STATUSES",
    }),
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    product: {
      get: function () {
        return this.getProduct;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    statuses: {
      get: function () {
        return this.getStatuses;
      },
    },
    failures: {
      get: function () {
        return this.getFailures;
      },
    },
  },
  data: () => ({
    viewFullComment: false,
    selectedFailureId: 0,
    selectedStatusId: 0,
    feedback: null,
  }),
  methods: {
    ...mapActions({
      getProductByIdFromApi: "market/ADMIN_GET_PRODUCT_BY_ID_FROM_API",
      approveProductByIdFromApi: "market/ADMIN_APPROVE_FROM_API",
      deleteProductByIdFromApi: "market/ADMIN_DELETE_FROM_API",
      getFailuresFromApi: "market/ADMIN_GET_FAILURES_FROM_API",
      getStatusesFromApi: "market/ADMIN_GET_STATUSES_FROM_API",
      updateByProductIdFromApi: "market/ADMIN_UPDATE_BY_PRODUCT_ID_FROM_API"
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setDialog: "common/SET_DIALOG",
    }),
    async updateByProductId(productId) {
      await this.updateByProductIdFromApi({
        productId: productId,
        statusId: this.selectedStatusId,
        failureId: this.selectedFailureId,
        feedback: this.feedback
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Ok!",
        });
        this.product.data.statusId = 1;
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Error!",
        });
      }
    },
    async deleteMethod(item) {
      await this.deleteProductByIdFromApi({
        productId: item,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Удалено!",
        });
        this.product.data.statusId = 4;
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Ошибка!",
        });
      }
    },
    openMapDialog() {
      this.setDialog({
        show: true,
        fullscreen: true,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: "Карта",
        view_id: 8,
        coords: {
          lat: this.product.data.latitude,
          lng: this.product.data.longitude,
        },
        isView: true,
      });
    },
    async approve(item) {
      await this.approveProductByIdFromApi({
        productId: item,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Одобрено!",
        });
        this.product.data.statusId = 1;
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Ошибка!",
        });
      }
    },
    async init(productId) {
      await this.getProductByIdFromApi({ id: productId });
      if (this.product.succeeded) {
        this.selectedFailureId = this.product.data.productFailureTypeId;
        this.selectedStatusId = this.product.data.statusId;
        this.feedback = this.product.data.moderatorFeedback;
      }
    },
  },
  watch: {
    $route(to) {
      let productId = parseInt(to.params.productId, 10);
      this.init(productId);
    },
  },
  async created() {
    await this.getFailuresFromApi();
    await this.getStatusesFromApi();
    let productId = parseInt(this.$route.params.productId, 10);
    await this.init(productId);
  },
};
</script>