<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-xl hidden-md-and-up mb-4"
      color="transparent"
    >
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-btn text x-small @click="goToInstagram(text.instagram)">
            <v-icon>$customInstagram</v-icon>
            <span
              class="text-caption text-lowercase"
              style="font-weight: 600"
              >{{ text.instagram }}</span
            >
          </v-btn>
          <v-btn text x-small @click="goToSite(text.url)">
            <v-icon>$customUrl</v-icon>
            <span
              class="text-caption text-lowercase"
              style="font-weight: 600"
              >{{ text.url }}</span
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "PromoContacts",
  data: () => ({
    image: {
      instagram: require("../../assets/promo/instagram.png"),
      url: require("../../assets/promo/url.png"),
    },
    text: {
      instagram: "easylife_turkiye",
      url: "https://el-turk.com",
    },
  }),
  methods: {
    goToInstagram(login) {
      window.open(`https://instagram.com/${login}`, "_blank");
    },
    goToSite(url) {
      window.open(url, "_blank");
    },
  },
};
</script>