import house from "../api/house/index"
import collector from "../api/collector/index"

const GetParametersByCategoryId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetParametersByCategoryId(query))
    })
}

const GetByRegionIdCategoryIdd = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetByRegionIdCategoryIdd(query))
    })
}

const GetById = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetByIdCollector(query))
    })
}

const GetReviewsByPointId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetReviewsByPointId(query))
    })
}

const AddReview = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddPointReview(query))
    })
}

const AdminGetPagedPoints = (query) => {
    return new Promise((resolve) => {
        resolve(collector.collector.AdminGetPagedPoints(query))
    })
}

const AdminUpdatePoints = (query) => {
    return new Promise((resolve) => {
        resolve(collector.collector.AdminUpdatePoints(query))
    })
}

const AdminGetPointsExcel = () => {
    return new Promise((resolve) => {
        resolve(collector.collector.AdminGetPointsExcel())
    })
}

export default {
    namespaced: true,
    state: {
        parameters: [],
        selectedParameters: {
            load: false,
            data: []
        },
        list: {
            pageNumber: 1,
            totalRecords: 0,
        },
        info: null,
        reviews: {
            pageNumber: 1,
            totalRecords: 0,
        },
        addReviewStatus: null,
        selectedSort: null,
        pagingStatus: true,
        favorites: null,
        actionStatus: null,
        excelGuid: null
    },
    getters: {
        GET_PARAMETERS(state) {
            return state.parameters;
        },
        GET_SELECTED_PARAMETERS(state) {
            return state.selectedParameters;
        },
        GET_LIST(state) {
            return state.list;
        },
        GET_INFO(state) {
            return state.info;
        },
        GET_REVIEWS(state) {
            return state.reviews;
        },
        GET_ADD_REVIEW_STATUS(state) {
            return state.addReviewStatus;
        },
        GET_SELECTED_SORT(state) {
            return state.selectedSort;
        },
        GET_PAGING_STATUS(state) {
            return state.pagingStatus;
        },
        GET_FAVORITES(state) {
            return state.favorites;
        },
        GET_ACTION_STATUS(state) {
            return state.actionStatus;
        },
        GET_EXCEL_GUID(state) {
            return state.excelGuid;
        },
    },
    mutations: {
        SET_PARAMETERS(state, payload) {
            state.parameters = payload;
        },
        SET_SELECTED_PARAMETERS(state, payload) {
            state.selectedParameters = payload;
        },
        SET_LIST(state, payload) {
            state.list = payload;
            if (payload.succeeded) {
                if (payload.data.length != 0) {
                    if (payload.data.length == 20)
                        state.pagingStatus = true;
                    else
                        state.pagingStatus = false;
                }
                else {
                    state.pagingStatus = false;
                }
            }
        },
        ADD_TO_LIST(state, payload) {
            if (payload.succeeded) {
                if (payload.data.length != 0) {
                    state.list.data = [
                        ...new Set([
                            ...state.list.data,
                            ...payload.data,
                        ]),
                    ];
                    if (payload.data.length == 20)
                        state.pagingStatus = true;
                    else
                        state.pagingStatus = false;
                }
                else {
                    state.pagingStatus = false;
                }
            }
        },
        SET_INFO(state, payload) {
            state.info = payload;
        },
        SET_REVIEWS(state, payload) {
            state.reviews = payload;
        },
        SET_ADD_REVIEW_STATUS(state, payload) {
            state.addReviewStatus = payload;
        },
        SET_SELECTED_SORT(state, payload) {
            state.selectedSort = payload;
        },
        SET_FAVORITES(state, payload) {
            state.favorites = payload;
        },
        ADD_FAVORITES(state, payload) {
            if (state.favorites == null)
                state.favorites = [];

            state.favorites.push(payload);
            localStorage.setItem('pointFavorites', JSON.stringify(state.favorites));
        },
        REMOVE_FAVORITES(state, payload) {
            if (state.favorites == null)
                state.favorites = [];

            let index = state.favorites.map(x => x.id).indexOf(payload);
            if (index > -1) {
                state.favorites.splice(index, 1);
            }

            localStorage.setItem('pointFavorites', JSON.stringify(state.favorites));
        },
        SET_ACTION_STATUS(state, payload) {
            state.actionStatus = payload;
        },
        SET_EXCEL_GUID(state, payload) {
            state.excelGuid = payload;
        },
    },
    actions: {
        async GET_PARAMETERS_FROM_API({ commit }, payload) {
            await GetParametersByCategoryId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PARAMETERS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PARAMETERS', { succeeded: false });
                })
        },
        COMMIT_SELECTED_PARAMETERS({ commit }, payload) {
            commit('SET_SELECTED_PARAMETERS', payload);
        },
        async GET_LIST_FROM_API({ commit }, payload) {
            await GetByRegionIdCategoryIdd(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LIST', { succeeded: false });
                })
        },
        async GET_LIST_PAGING_FROM_API({ commit }, payload) {
            await GetByRegionIdCategoryIdd(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('ADD_TO_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('ADD_TO_LIST', { succeeded: false });
                })
        },
        async GET_INFO_FROM_API({ commit }, payload) {
            await GetById(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_INFO', { succeeded: false });
                })
        },
        async GET_REVIEWS_BY_POINT_ID_FROM_API({ commit }, payload) {
            await GetReviewsByPointId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_REVIEWS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REVIEWS', { succeeded: false, totalRecords: 0 });
                })
        },
        async ADD_REVIEW_FROM_API({ commit }, payload) {
            await AddReview(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ADD_REVIEW_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ADD_REVIEW_STATUS', { succeeded: false, data: "Error!" });
                })
        },
        COMMIT_SELECTED_SORT({ commit }, payload) {
            commit('SET_SELECTED_SORT', payload);
        },
        INIT_FAVORITES({ commit }) {
            if (localStorage.getItem('pointFavorites') != null) {
                var obj = JSON.parse(localStorage.getItem('pointFavorites'));
                commit('SET_FAVORITES', obj);
            }
            else {
                commit('SET_FAVORITES', []);
            }
        },
        ADD_TO_FAVORITES({ commit }, payload) {
            commit('ADD_FAVORITES', payload);
        },
        REMOVE_FROM_FAVORITES({ commit }, payload) {
            commit('REMOVE_FAVORITES', payload);
        },
        async ADMIN_GET_POINTS_FROM_API({ commit }, payload) {
            await AdminGetPagedPoints(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LIST', { succeeded: false });
                })
        },
        async ADMIN_UPDATE_POINTS_FROM_API({ commit }, payload) {
            await AdminUpdatePoints(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', { succeeded: true, data: data });
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADMIN_GET_POINTS_EXCEL_FROM_API({ commit }, payload) {
            await AdminGetPointsExcel(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_EXCEL_GUID', { data: data, succeeded: true });
                    }
                }
            )
                .catch(() => {
                    commit('SET_EXCEL_GUID', { succeeded: false });
                })
        },
    }
}