<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg">
      <v-row>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-subheader>{{
            stringValues.dialogs[selectedLanguage.code]
          }}</v-subheader>
          <v-list dense v-if="previewMessages != null">
            <v-list-item-group color="purple">
              <template v-for="(dialog, index) in previewMessages.data">
                <v-list-item
                  :key="index + 'dialog'"
                  @click="openDialog(dialog)"
                >
                  <v-list-item-avatar>
                    <v-img :src="dialog.imageLink"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="dialog.name"></v-list-item-title>
                    <v-list-item-subtitle>{{
                      dialog.msg
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      <code>{{
                        new Date(dialog.timeOfMessage).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}</code>
                    </v-list-item-action-text>
                    <v-badge
                      :content="dialog.unreadMsgs"
                      v-if="dialog.unreadMsgs > 0"
                      color="purple"
                      overlap
                      bottom
                      bordered
                    >
                      <v-icon :color="'grey'">mdi-chat</v-icon>
                    </v-badge>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="index + 'dialogDvd'"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="12" md="9" lg="9" xl="9">
          <!-- Active Chat -->
          <v-card elevation="0" v-if="selectedDialog != null">
            <!-- User Info  -->
            <v-card-title>
              <v-list-item-avatar>
                <v-img :src="selectedDialog.imageLink"></v-img>
              </v-list-item-avatar>
              <span>{{ selectedDialog.name }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <!-- Chat  -->
            <v-card
              height="500"
              style="overflow-y: auto"
              id="chatHistory"
              class="pr-2 pl-2"
              @scroll.passive="OnScroll"
              fill-height
            >
              <v-row v-if="pagingStatus">
                <v-col cols="12">
                  <v-hover v-slot="{ hover }">
                    <v-alert
                      dense
                      class="purple white--text rounded-lg text-center"
                      tile
                      :elevation="hover ? 8 : 0"
                      @click="getMessagesByPage()"
                    >
                      {{ stringValues.loadMore[selectedLanguage.code] }}
                    </v-alert>
                  </v-hover>
                </v-col>
              </v-row>
              <v-timeline>
                <v-timeline-item
                  v-for="(msg, index) in chat.data.messages"
                  :key="index"
                  :left="!msg.isFromMe"
                  :right="msg.isFromMe"
                  hide-dot
                >
                  <v-alert class="text--purple" elevation="2" dense>
                    <div class="text-caption">
                      {{ msg.msg }}
                    </div>
                    <div class="text-right">
                      <v-chip x-small>
                        {{
                          new Date(msg.timeOfMessage).toLocaleTimeString([], {
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }}
                      </v-chip>
                    </div>
                  </v-alert>
                </v-timeline-item>
              </v-timeline>
            </v-card>
            <!-- Send Message  -->
            <v-card-actions>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="stringValues.enterMessage[selectedLanguage.code]"
                    outlined
                    append-icon="mdi-send-outline"
                    clearable
                    counter="125"
                    v-model="outMessage"
                    @click:append="SendMessage()"
                    @keyup.enter="SendMessage()"
                    color="purple"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MarketChat",
  components: {},
  computed: {
    ...mapGetters({
      getPreviewMessages: "marketChat/GET_PREVIEW_MESSAGES",
      getChat: "marketChat/GET_CHAT",
      getConnection: "hub/GET_CONNECTION",
      getPagingStatus: "marketChat/GET_PAGING_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    previewMessages: {
      get: function () {
        return this.getPreviewMessages;
      },
      set: function (newVal) {
        this.setPreviewMessages(newVal);
      },
    },
    chat: {
      get: function () {
        return this.getChat;
      },
      set: function (newVal) {
        this.setChat(newVal);
      },
    },
    connection: {
      get: function () {
        return this.getConnection;
      },
    },
    pagingStatus: {
      get: function () {
        return this.getPagingStatus;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    selectedDialog: null,
    outMessage: null,
    fab: false,
    pageNumber: 1,
  }),
  methods: {
    ...mapActions({
      getPreviewMessagesFromApi: "marketChat/GET_PREVIEW_MESSAGES_FROM_API",
      getChatFromApi: "marketChat/GET_CHAT_FROM_API",
      connectToHub: "hub/CONNECT",
      getChatPagingFromApi: "marketChat/GET_CHAT_PAGING_FROM_API",
    }),
    ...mapMutations({
      setChat: "marketChat/SET_CHAT",
      setPreviewMessages: "marketChat/SET_PREVIEW_MESSAGES",
    }),
    async openDialog(item) {
      this.selectedDialog = item;
      await this.getChatFromApi({
        pageNumber: 1,
        pageSize: 100,
        toUserId: item.companionId,
      });
      this.ScrollToEnd();
      this.pageNumber = 1;
    },
    SendMessage() {
      if (this.outMessage != null && this.outMessage.length > 0) {
        this.connection.invoke(
          "Send",
          this.outMessage,
          this.selectedDialog.companionId
        );
        let timeMessage = new Date();
        let newMessage = {
          isFromMe: true,
          msg: this.outMessage,
          timeOfMessage: timeMessage,
        };
        this.chat.data.messages.push(newMessage);

        let message = this.previewMessages.data.find(
          (x) => x.companionId == this.selectedDialog.companionId
        );
        if (message != undefined) {
          message.msg = this.outMessage;
          message.timeOfMessage = timeMessage;
          message.unreadMsgs = ++message.unreadMsgs;
        }
        this.outMessage = "";
        this.ScrollToEnd();
      }
    },
    ReceiveMessage(message, userName) {
      if (this.selectedDialog == null) {
        let messageT = this.previewMessages.data.find(
          (x) => x.name == userName
        );
        if (messageT != undefined) {
          messageT.msg = message;
          messageT.timeOfMessage = new Date();
          messageT.unreadMsgs = ++messageT.unreadMsgs;
        }
      }

      if (this.selectedDialog.name == userName) {
        let timeMessage = new Date();
        let newMessage = {
          isFromMe: false,
          msg: message,
          timeOfMessage: timeMessage,
        };

        this.chat.data.messages.push(newMessage);
        let messageT = this.previewMessages.data.find(
          (x) => x.companionId == this.selectedDialog.companionId
        );
        if (messageT != undefined) {
          messageT.msg = message;
          messageT.timeOfMessage = timeMessage;
          messageT.unreadMsgs = ++messageT.unreadMsgs;
        }
        this.ScrollToEnd();
      } else {
        let messageT = this.previewMessages.data.find(
          (x) => x.name == userName
        );
        if (messageT != undefined) {
          messageT.msg = message;
          messageT.timeOfMessage = new Date();
          messageT.unreadMsgs = ++messageT.unreadMsgs;
        }
      }
    },
    OnScroll() {
      var container = this.$el.querySelector("#chatHistory");
      this.fab = container.scrollHeight - container.scrollTop > 500;
    },

    ScrollToEnd() {
      setTimeout(() => {
        var container = this.$el.querySelector("#chatHistory");
        container.scrollTop = container.scrollHeight;
      });
    },
    async getMessagesByPage() {
      await this.getChatPagingFromApi({
        pageNumber: ++this.pageNumber,
        pageSize: 100,
        toUserId: this.selectedDialog.companionId,
      });
    },
  },
  async created() {
    await this.getPreviewMessagesFromApi({ pageNumber: 1, pageSize: 50 });
    this.connectToHub("https://marketChat.el-turk.com/chat");
    this.connection.on("Receive", this.ReceiveMessage);
  },
};
</script>
