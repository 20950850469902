<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="lg mt-8">
          <NestedProductCategoryList
            :productCategoryId="currentProductCategoryIdForCategories"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="rounded-lg mt-2 mb-8 grey lighten-4">
          <List
            :productCategoryId="currentProductCategoryId"
            :key="currentProductCategoryId"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import NestedProductCategoryList from "./NestedProductCategoryList.vue";
import List from "./List.vue";
export default {
  name: "MarketProductList",
  components: {
    NestedProductCategoryList,
    List,
  },
  data: () => ({
    currentProductCategoryId: null,
    currentProductCategoryIdForCategories: null,
  }),
  methods: {
    init(productCategoryId) {
      let arr = productCategoryId.split("|");
      let productCategoryid = parseInt(arr[arr.length - 1], 10);
      this.currentProductCategoryId = productCategoryid;
      this.currentProductCategoryIdForCategories = productCategoryId;
    },
  },
  watch: {
    $route(to) {
      this.init(to.params.productCategoryId);
    },
  },
  created() {
    this.init(this.$route.params.productCategoryId);
  },
};
</script>