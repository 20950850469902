<template>
  <v-container>
    <v-card elevation="0" width="100%" class="rounded-lg grey lighten-4">
      <v-card-title>{{
        stringValues.toContactUs[selectedLanguage.code]
      }}</v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedTheme"
          :items="themes"
          :item-text="`name[${selectedLanguage.code}]`"
          item-value="id"
          label="Выберите элементы"
          outlined
          dense
          color="purple"
          hide-details
        ></v-select>
        <v-textarea
          v-model="comment"
          :label="stringValues.systemReviewComment[selectedLanguage.code]"
          color="purple"
          type="text"
          outlined
          dense
          hide-details
          class="rounded-lg white mt-4"
        >
        </v-textarea>
        <v-textarea
          v-model="contacts"
          :label="stringValues.systemReviewConnection[selectedLanguage.code]"
          color="purple"
          type="text"
          outlined
          dense
          hide-details
          class="rounded-lg white mt-4"
        >
        </v-textarea>
        <v-btn
          class="
            mt-2
            purple
            white--text
            font-weight-regular
            rounded-lg
            text-capitalize
          "
          @click="send()"
          elevation="0"
          :loading="loading"
          :disabled="disabled"
          width="100%"
          small
        >
          {{ stringValues.apply[selectedLanguage.code] }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MenuContactUs",
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getActionStatus: "house/GET_ACTION_STATUS",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    themes: [
      {
        id: 1,
        name: { ru: "Я нашел баг", en: "I found a bug", tr: "Bag buldum" },
      },
      {
        id: 2,
        name: {
          ru: "Я нашел ошибку",
          en: "I found a mistake",
          tr: "Bir hata buldum",
        },
      },
      {
        id: 3,
        name: { ru: "Сотрудничество", en: "Cooperation", tr: "İşbirliği" },
      },
    ],
    selectedTheme: {
      id: 1,
      name: { ru: "Я нашел баг", en: "I found a bug", tr: "Bag buldum" },
    },
    comment: null,
    contacts: null,
    loading: false,
    disabled: false,
  }),
  methods: {
    ...mapActions({
      addFromApi: "house/ADD_SYSTEM_REVIEW_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    validate() {
      if (this.comment == null || this.comment.length == 0) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
        return false;
      }

      if (this.contacts == null || this.contacts.length == 0) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
        return false;
      }

      return true;
    },
    async send() {
      this.loading = true;

      if (!this.validate()) {
        this.loading = false;
        return;
      }

      await this.addFromApi({
        themeId: this.selectedTheme.id,
        connnectionInfo: this.contacts,
        comment: this.comment,
      });

      if (this.actionStatus.succeeded) {
        this.disabled = true;
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }

      this.loading = false;
    },
  },
  async created() {},
};
</script>
