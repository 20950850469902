<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8">
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="list.data"
            :items-per-page="20"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
          >
            <template slot="progress">
              <v-progress-linear
                color="purple"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:item.link="{ item }">
              <a :href="item.link" target="_blank">{{ item.link }}</a>
            </template>
            <template v-slot:item.createdDate="{ item }">
              <span>{{ new Date(item.createdDate).toLocaleString() }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="purple"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      Добавить
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.link"
                              label="Ссылка"
                              color="purple"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editedItem.description"
                              label="Описание"
                              color="purple"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" text @click="close">
                        Отменить
                      </v-btn>
                      <v-btn color="purple" text @click="save">
                        Применить
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Вы действительно хотите удалить текущую
                      запись?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" text @click="closeDelete"
                        >Отменить</v-btn
                      >
                      <v-btn color="purple" text @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-pagination
            circle
            v-model="list.pageNumber"
            :length="list.totalPages"
            color="purple"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "AdminHouseDocuments",
  props: {
    rcId: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      getList: "house/GET_APPLIANCES_LIST",
      getActionStatus: "house/GET_ACTION_STATUS",
    }),
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? "Добавить" : "Изменить";
    },
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "Ссылка",
        value: "link",
        align: "center",
        sortable: false,
      },
      {
        text: "Дата создания",
        value: "createdDate",
        align: "center",
        sortable: false,
      },
      {
        text: "Действия",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    dialog: false,
    dialogDelete: false,
    editedItem: {
      id: 0,
      link: "",
      description: "",
    },
    defaultItem: {
      id: 0,
      link: "",
      description: "",
    },
    editedIndex: -1,
  }),
  methods: {
    ...mapActions({
      getListFromApi: "house/ADMIN_GET_DOCUMENTS_LIST_BY_RC_ID_FROM_API",
      updateFromApi: "house/ADMIN_UPDATE_DOCUMENTS_FROM_API",
      addFromApi: "house/ADMIN_ADD_DOCUMENTS_FROM_API",
      deleteFromApi: "house/ADMIN_DELETE_DOCUMENTS_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_APPLIANCES_LIST",
    }),
    async getItemsFromApi(pageNumber, pageSize, rcId) {
      this.loading = true;
      await this.getListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        residentialComplexId: rcId,
      });
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteFromApi({
        data: {
          documentId: this.editedItem.id,
        },
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Успешно!",
        });
        this.list.data.splice(this.editedIndex, 1);
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Ошибка!",
        });
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.updateFromApi({
          documentId: this.editedItem.id,
          pointId: this.rcId,
          description: this.editedItem.description,
          link: this.editedItem.link,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Успешно!",
          });
          Object.assign(this.list.data[this.editedIndex], this.editedItem);
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: "Ошибка!",
          });
        }
      } else {
        await this.addFromApi({
          pointId: this.rcId,
          description: this.editedItem.description,
          link: this.editedItem.link,
        });
        if (this.actionStatus.succeeded) {
          this.editedItem.createdDate = new Date();
          this.list.data.unshift(this.editedItem);
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Успешно!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: "Ошибка!",
          });
        }
      }
      this.close();
    },
  },
  watch: {
    "list.pageNumber": function () {
      this.getItemsFromApi(this.list.pageNumber, 20, this.rcId);
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    if (this.rcId != null && this.rcId != undefined)
      await this.getItemsFromApi(1, 20, this.rcId);
  },
};
</script>
