<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8 grey lighten-4">
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <div v-if="favorites.length > 0">
            <v-card elevation="0">
              <v-list>
                <v-list-item-group>
                  <template v-for="(item, index) in favorites">
                    <v-list-item :key="item.id" @click="open(item.id)">
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-icon
                          color="purple"
                          @click.stop="addToFavorites(item)"
                          v-if="!favorites.some((x) => x.id == item.id)"
                          >mdi-cards-heart-outline</v-icon
                        >
                        <v-icon
                          color="purple"
                          @click.stop="removeFromFavorites(item.id)"
                          v-else
                          >mdi-cards-heart</v-icon
                        >
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index < favorites.length - 1"
                      :key="'dvd_' + index"
                    />
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </div>
          <div v-else class="text-center">
            <h4 class="red--text font-weight-regular">
              {{ stringValues.notElements[selectedLanguage.code] }}
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FavoritesHouse",
  components: {},
  computed: {
    ...mapGetters({
      getFavorites: "house/GET_FAVORITES",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    favorites: {
      get: function () {
        return this.getFavorites;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      addToFavoritesApi: "house/ADD_TO_FAVORITES",
      removeFromFavoritesApi: "house/REMOVE_FROM_FAVORITES",
    }),
    removeFromFavorites(id) {
      this.removeFromFavoritesApi(id);
    },
    async open(id) {
      this.$router.push({ path: `/house/${id}` });
    },
  },
  async created() {},
};
</script>
