<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8">
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="list.data"
            :items-per-page="20"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
          >
            <template slot="progress">
              <v-progress-linear
                color="purple"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:item.appllicationDate="{ item }">
              <span>{{
                new Date(item.appllicationDate).toLocaleString()
              }}</span>
            </template>
            <template v-slot:item.isApprovedTempl="{ item }">
              <div v-if="item.isApproved">
                <v-btn
                  x-small
                  fab
                  class="red white--text"
                  text
                  @click="updateAppliance(item)"
                >
                  <v-icon x-small>mdi-minus</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  x-small
                  fab
                  class="green white--text"
                  text
                  @click="updateAppliance(item)"
                  ><v-icon x-small>mdi-check</v-icon></v-btn
                >
              </div>
            </template>
          </v-data-table>
          <v-pagination
            circle
            v-model="list.pageNumber"
            :length="list.totalPages"
            color="purple"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "AdminHouseAppliances",
  props: {
    rcId: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters({
      getList: "house/GET_APPLIANCES_LIST",
      getActionStatus: "house/GET_ACTION_STATUS",
    }),
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    headers: [
      {
        text: "Отправитель",
        value: "user.name",
        align: "center",
        sortable: false,
      },
      {
        text: "Номер квартиры",
        value: "apartmentNum",
        align: "center",
        sortable: false,
      },
      {
        text: "Фамилия по тапу",
        value: "lastName",
        align: "center",
        sortable: false,
      },
      {
        text: "Номер телефона",
        value: "telephoneNumber",
        align: "center",
        sortable: false,
      },
      {
        text: "Дата подачи",
        value: "appllicationDate",
        align: "center",
        sortable: false,
      },
      {
        text: "Принять/Отклонить",
        value: "isApprovedTempl",
        align: "center",
        sortable: false,
      },
    ],
    loading: false,
  }),
  methods: {
    ...mapActions({
      getListFromApi: "house/ADMIN_GET_APPLICANCES_LIST_BY_RC_ID_FROM_API",
      updateStatusFromApi: "house/ADMIN_UPDATE_APPLIANCE_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_APPLIANCES_LIST",
    }),
    async getItemsFromApi(pageNumber, pageSize, rcId) {
      this.loading = true;
      await this.getListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        residentialComplexId: rcId,
      });
      this.loading = false;
    },
    async updateAppliance(item) {
      if (confirm("Вы уверены?")) {
        await this.updateStatusFromApi({
          residentialComplexId: item.residentialComplexId,
          userId: item.userId,
        });
        if (this.actionStatus.succeeded) {
          let currentAppliance = this.list.data.find((x) => x.id == item.id);
          if (currentAppliance != null) {
            currentAppliance.isApproved = !currentAppliance.isApproved;
          }
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Успешно!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: "Ошибка!",
          });
        }
      }
    },
  },
  watch: {
    "list.pageNumber": function () {
      this.getItemsFromApi(this.list.pageNumber, 20, this.rcId);
    },
  },
  async created() {
    if (this.rcId != null && this.rcId != undefined)
      await this.getItemsFromApi(1, 20, this.rcId);
  },
};
</script>
