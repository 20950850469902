<template>
  <v-container>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="text-center">
          <div v-if="info.succeeded">
            <div>
              <span class="font-weight-bold purple--text text-subtitle-2">
                {{ info.data.name }}
              </span>
            </div>
            <div>
              <v-btn
                small
                outlined
                color="green"
                class="font-weight-bold mx-1 my-1 rounded-lg"
                @click="openMoreDialog()"
              >
                <span class="black--text text-capitalize">{{
                  stringValues.details[selectedLanguage.code]
                }}</span>
              </v-btn>
              <v-btn
                small
                outlined
                color="green"
                class="font-weight-bold mx-1 my-1 rounded-lg"
                @click="openReviewsDialog()"
              >
                <span class="black--text text-capitalize">{{
                  stringValues.reviews[selectedLanguage.code]
                }}</span></v-btn
              >
            </div>
          </div>
          <div v-else>
            <h4 class="red--text font-weight-regular">
              {{ stringValues.notTiedRC[selectedLanguage.code] }}
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "HouseInfo",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapMutations({
      setDialog: "common/SET_DIALOG",
    }),
    openMoreDialog() {
      this.setDialog({
        show: true,
        fullscreen: true,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.details[this.selectedLanguage.code],
        view_id: 1,
      });
    },
    openReviewsDialog() {
      this.setDialog({
        show: true,
        fullscreen: true,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.reviews[this.selectedLanguage.code],
        view_id: 2,
      });
    },
  },
};
</script>