<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8 grey lighten-4">
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <div v-if="favorites.length > 0">
            <v-row no-gutters>
              <v-col
                cols="12"
                md="6"
                lg="6"
                xl="6"
                v-for="item in favorites"
                :key="item.id"
              >
                <v-card
                  elevation="0"
                  @click="open(item)"
                  class="mx-1 my-1 rounded-lg"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" md="3" lg="3" xl="3">
                        <v-img
                          lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                          :src="item.image.path"
                          class="rounded-lg"
                          max-width="100%"
                          height="12vh"
                        />
                        <v-card elevation="0" max-height="1vh">
                          <span
                            class="
                              grey--text
                              text-caption
                              d-inline-block
                              text-truncate
                            "
                            style="max-width: 100%"
                            >{{ item.address }}
                          </span>
                        </v-card>
                      </v-col>
                      <v-col cols="6" md="9" lg="9" xl="9">
                        <v-card elevation="0" max-height="3vh">
                          <v-card-title class="py-0 px-0">
                            <span
                              class="
                                font-weight-bold
                                text-subtitle-2
                                purple--text
                                d-inline-block
                                text-truncate
                              "
                              style="max-width: 80%"
                              >{{ item.name }}</span
                            >
                            <v-spacer />
                            <v-icon
                              color="purple"
                              @click.stop="addToFavorites(item)"
                              v-if="!favorites.some((x) => x.id == item.id)"
                              >mdi-cards-heart-outline</v-icon
                            >
                            <v-icon
                              color="purple"
                              @click.stop="removeFromFavorites(item.id)"
                              v-else
                              >mdi-cards-heart</v-icon
                            >
                          </v-card-title>
                        </v-card>
                        <v-card elevation="0" max-height="3vh">
                          <span class="grey--text text-caption"
                            >{{
                              stringValues[item.categoryName][
                                selectedLanguage.code
                              ]
                            }}
                          </span>
                        </v-card>
                        <v-card elevation="0" max-height="3vh">
                          <v-icon
                            small
                            :color="
                              item.averageMark >= 1 ? 'green' : 'grey lighten-1'
                            "
                            >mdi-hand-front-left</v-icon
                          >
                          <v-icon
                            small
                            :color="
                              item.averageMark >= 2 ? 'green' : 'grey lighten-1'
                            "
                            >mdi-hand-front-left</v-icon
                          >
                          <v-icon
                            small
                            :color="
                              item.averageMark >= 3 ? 'green' : 'grey lighten-1'
                            "
                            >mdi-hand-front-left</v-icon
                          >
                          <v-icon
                            small
                            :color="
                              item.averageMark >= 4 ? 'green' : 'grey lighten-1'
                            "
                            >mdi-hand-front-left</v-icon
                          >
                          <v-icon
                            small
                            :color="
                              item.averageMark >= 5 ? 'green' : 'grey lighten-1'
                            "
                            >mdi-hand-front-left</v-icon
                          >
                        </v-card>
                        <v-card elevation="0" max-height="3vh">
                          <span class="font-weight-medium text-caption"
                            >{{
                              stringValues.distanceTo[selectedLanguage.code]
                            }}
                            -
                          </span>
                          <span class="font-weight-medium text-caption"
                            >{{ parseInt(item.distance, 10) }}
                            {{ stringValues.km[selectedLanguage.code] }}
                          </span>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-else class="text-center">
            <h4 class="red--text font-weight-regular">
              {{ stringValues.notElements[selectedLanguage.code] }}
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FavoritesPoint",
  components: {},
  computed: {
    ...mapGetters({
      getFavorites: "collector/GET_FAVORITES",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    favorites: {
      get: function () {
        return this.getFavorites;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      addToFavoritesApi: "collector/ADD_TO_FAVORITES",
      removeFromFavoritesApi: "collector/REMOVE_FROM_FAVORITES",
    }),
    removeFromFavorites(id) {
      this.removeFromFavoritesApi(id);
    },
    open(item) {
      this.$router.push({ path: `/city/${item.product}/${item.id}` });
    },
  },
  async created() {},
};
</script>
