import productCategory from "../../../api/city/shop/index"

const GetAll = () => {
    return new Promise((resolve) => {
        resolve(productCategory.productCategory.GetAll())
    })
}

export default {
    namespaced: true,
    state: {
        productCategoryList: null
    },
    getters: {
        GET_PRODUCT_CATEGORY_LIST(state) {
            return state.productCategoryList;
        }
    },
    mutations: {
        SET_PRODUCT_CATEGORY_LIST(state, payload) {
            state.productCategoryList = payload;
        }
    },
    actions: {
        async GET_PRODUCT_CATEGORY_LIST_FROM_API({ commit }) {
            await GetAll().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCT_CATEGORY_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCT_CATEGORY_LIST', null);
                })
        }
    }
}