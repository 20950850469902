<template>
  <div style="width: 100%">
    <!-- Desktop -->
    <div class="hidden-sm-and-down">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="10" lg="10" xl="10">
          <Header :lang="'ru'" />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" lg="7" xl="7">
          <Easy :lang="'ru'" />
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" lg="7" xl="7">
          <Search :lang="'ru'" />
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" lg="7" xl="7">
          <Sale :lang="'ru'" />
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" lg="7" xl="7">
          <Usee :lang="'ru'" />
        </v-col>
      </v-row>
    </div>

    <!-- Mobile -->
    <div
      class="hidden-md-and-up"
      style="
        background: url('https://cdn.el-turk.com/bg.svg') no-repeat center
          center fixed !important;
        background-size: cover;
      "
    >
      <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" md="10" lg="10" xl="10">
          <Header :lang="'ru'" />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" md="7" lg="7" xl="7">
          <Easy :lang="'ru'" />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" md="7" lg="7" xl="7">
          <Search :lang="'ru'" />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" md="7" lg="7" xl="7">
          <Sale :lang="'ru'" />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" md="7" lg="7" xl="7">
          <Usee :lang="'ru'" />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" no-gutters>
        <v-col cols="12" md="7" lg="7" xl="7">
          <Contacts />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Header from "./Header.vue";
import Easy from "./Easy.vue";
import Search from "./Search.vue";
import Sale from "./Sale.vue";
import Usee from "./Use.vue";
import Contacts from "./Contacts.vue";
import { mapMutations } from "vuex";
export default {
  name: "PromoEn",
  components: {
    Header,
    Easy,
    Search,
    Sale,
    Usee,
    Contacts,
  },
  methods: {
    ...mapMutations({
      setAppBarShow: "common/SET_APP_BAR_SHOW",
    }),
  },
  created() {
    this.setAppBarShow(false);
  },
};
</script>