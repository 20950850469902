<template>
  <v-container>
    <div v-if="info.succeeded">
      <cat-carousel
        :items="this.info.data.images"
        :item-per-page="
          this.info.data.images.length < 3 ? this.info.data.images.length : 3
        "
        :indicators-config="{
          activeColor: '#A438B6',
          color: '#d1d1d1',
          hideIndicators: false,
        }"
      >
        <template slot="item" slot-scope="{ data }">
          <v-img
            :src="data.path"
            height="30vh"
            width="100%"
            @click="openImage()"
          ></v-img>
        </template>
      </cat-carousel>
    </div>
    <div v-else>
      <h4 class="red--text font-weight-regular">
        {{ stringValues.notElements[selectedLanguage.code] }}
      </h4>
    </div>
    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="grey">
            <v-spacer />
            <v-btn icon dark @click="dialog = false" color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="my-2">
          <v-carousel :show-arrows="info.data.images.length > 1">
            <v-carousel-item
              v-for="(item, i) in info.data.images"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-img
                :src="item.path"
                max-height="100%"
                max-width="100%"
              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HouseImages",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    openImage() {
      this.dialog = true;
    },
  },
};
</script>
