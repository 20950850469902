<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg" :loading="loading">
      <template slot="progress">
        <v-progress-linear color="purple" indeterminate></v-progress-linear>
      </template>
      <v-card-title class="py-0" v-if="info.succeeded">
        <v-spacer />
        <v-icon
          color="purple"
          @click.stop="addToFavorites(info.data)"
          v-if="!favorites.some((x) => x.id == info.data.id)"
          class="mx-2 my-2"
          >mdi-cards-heart-outline</v-icon
        >
        <v-icon
          color="purple"
          @click.stop="removeFromFavorites(info.data.id)"
          v-else
          class="mx-2 my-2"
          >mdi-cards-heart</v-icon
        >
      </v-card-title>
      <v-row class="d-flex align-center" no-gutters>
        <v-col cols="6">
          <Info />
        </v-col>
        <v-divider vertical />
        <v-col cols="6">
          <ResidentialComplexSelect />
        </v-col>
      </v-row>
    </v-card>
    <div v-if="info.succeeded">
      <v-card elevation="0" class="rounded-lg mt-8" v-if="info.succeeded">
        <v-row class="d-flex align-center">
          <v-col cols="12">
            <Images />
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="0" class="rounded-lg mt-8" v-if="info.succeeded">
        <v-row class="text-center d-flex align-center justify-center">
          <v-col cols="4" v-if="loginInfo != null">
            <v-btn
              outlined
              color="green"
              class="rounded-lg"
              @click="openEnterDialog()"
              width="90%"
            >
              <span
                class="
                  font-weight-bold
                  black--text
                  text-caption text-capitalize
                "
                >{{ stringValues.enter[selectedLanguage.code] }}</span
              >
            </v-btn>
          </v-col>
          <!-- <v-col cols="4" v-if="loginInfo != null">
            <v-btn
              outlined
              color="green"
              class="rounded-lg"
              @click="openApplyDialog()"
              width="90%"
            >
              <span
                class="
                  font-weight-bold
                  black--text
                  text-caption text-capitalize
                "
                >{{ stringValues.submitOnApp[selectedLanguage.code] }}</span
              >
            </v-btn>
          </v-col> -->
          <v-col cols="4">
            <v-btn
              outlined
              color="green"
              class="rounded-lg"
              @click="openAdsDialog()"
              width="90%"
            >
              <span
                class="
                  font-weight-bold
                  black--text
                  text-caption text-capitalize
                "
                >{{ stringValues.ads[selectedLanguage.code] }}</span
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="0" class="rounded-lg my-8" v-if="info.succeeded">
        <v-row class="d-flex align-center">
          <v-col cols="12">
            <Accounts />
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="0" class="rounded-lg mt-8" v-if="info.succeeded">
        <v-row class="d-flex align-center" no-gutters>
          <v-col cols="12">
            <Map />
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div v-else>
      <v-card elevation="0" class="rounded-lg my-8">
        <v-row class="d-flex align-center">
          <v-col cols="12" class="text-center">
            <h4 class="red--text font-weight-regular">
              {{ stringValues.selectFromTheList[selectedLanguage.code] }}
            </h4>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ResidentialComplexSelect from "./ResidentialComplexSelect.vue";
import Info from "./Info.vue";
import Images from "./Images.vue";
import Map from "./Map.vue";
import Accounts from "./Accounts.vue";
export default {
  name: "Main",
  components: {
    ResidentialComplexSelect,
    Info,
    Images,
    Map,
    Accounts,
  },
  computed: {
    ...mapGetters({
      getSelectedParameters: "house/GET_SELECTED_PARAMETERS",
      getSelectedRegion: "common/GET_SELECTED_REGION",
      getInfo: "house/GET_INFO",
      getIsUserResidentialComplex: "house/GET_IS_USER_RESIDENTIAL_COMPLEX",
      getLoginInfo: "account/GET_LOGIN_INFO",
      getFavorites: "house/GET_FAVORITES",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    selectedParameters: {
      get: function () {
        return this.getSelectedParameters;
      },
    },
    selectedRegion: {
      get: function () {
        return this.getSelectedRegion;
      },
    },
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    IsUserResidentialComplex: {
      get: function () {
        return this.getIsUserResidentialComplex;
      },
    },
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
    },
    favorites: {
      get: function () {
        return this.getFavorites;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    loading: true,
  }),
  metaInfo() {
    return {
      title: this.stringValues.house[this.selectedLanguage.code],
      titleTemplate: "%s | EasyLife",
    };
  },
  methods: {
    ...mapActions({
      getInfoByUserFromApi: "house/GET_INFO_BY_USER_FROM_API",
      getListByRegionIdFromApi: "house/GET_LIST_BY_REGION_ID_FROM_API",
      getIsUserResidentialComplexFromApi:
        "house/GET_IS_USER_RESIDENTIAL_COMPLEX_FROM_API",
      addToFavoritesApi: "house/ADD_TO_FAVORITES",
      removeFromFavoritesApi: "house/REMOVE_FROM_FAVORITES",
      getInfoByIdFromApi: "house/GET_INFO_BY_ID_FROM_API",
    }),
    ...mapMutations({
      setDialog: "common/SET_DIALOG",
    }),
    openEnterDialog() {
      this.setDialog({
        show: true,
        fullscreen: true,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.enter[this.selectedLanguage.code],
        view_id: 3,
      });
    },
    openApplyDialog() {
      this.setDialog({
        show: true,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.submitOnApp[this.selectedLanguage.code],
        view_id: 6,
        width: 800,
      });
    },
    openAdsDialog() {
      this.setDialog({
        show: true,
        fullscreen: true,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.ads[this.selectedLanguage.code],
        view_id: 9,
      });
    },
    addToFavorites(item) {
      let model = {
        id: item.id,
        name: item.name,
      };
      this.addToFavoritesApi(model);
    },
    removeFromFavorites(id) {
      this.removeFromFavoritesApi(id);
    },
    async init(id) {
      this.loading = true;
      if (isNaN(id)) {
        await this.getInfoByUserFromApi();
      } else {
        await this.getInfoByIdFromApi({ id: id });
      }

      await this.getListByRegionIdFromApi({
        id: this.selectedRegion.id,
        model: [],
      });
      this.loading = false;
    },
  },
  watch: {
    selectedParameters: function () {
      this.getListByRegionIdFromApi({
        id: this.selectedRegion.id,
        model: this.selectedParameters,
      });
    },
    info: function () {
      if (this.info.succeeded) {
        this.getIsUserResidentialComplexFromApi({
          residentialComplexId: this.info.data.id,
        });
      }
    },
    $route(to) {
      let id = parseInt(to.params.id, 10);
      this.init(id);
    },
  },
  async created() {
    let id = parseInt(this.$route.params.id, 10);
    await this.init(id);
  },
};
</script>
