<template>
  <div class="my-8">
    <v-row class="d-flex align-center justify-center">
      <v-col cols="10" md="4" lg="3" xl="3">
        <v-row>
          <v-col cols="12">
            <v-card elevation="0" class="py-6 rounded-lg">
              <v-card-title class="black--text">
                <span class="mx-auto">{{
                  stringValues.login[selectedLanguage.code]
                }}</span>
              </v-card-title>
              <v-card-subtitle class="text-center my-1">
                <div>
                  <span class="text-body-2 black--text darken-1">{{
                    stringValues.welcomeBack[selectedLanguage.code]
                  }}</span>
                </div>
                <div>
                  <span class="text-body-2 black--text darken-1">{{
                    stringValues.signIn[selectedLanguage.code]
                  }}</span>
                </div>
              </v-card-subtitle>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-row class="justify-center align-center">
                    <v-col cols="10" class="justify-center text-center">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="phoneNumber"
                            :label="
                              stringValues.phoneNumber[selectedLanguage.code]
                            "
                            color="purple"
                            type="phone"
                            :prefix="'+' + countryPhoneNumberCode"
                            :maxlength="
                              18 -
                              (1 + countryPhoneNumberCode).toString().length
                            "
                            :rules="[
                              phoneNumberRequiredRule(phoneNumber),
                              phoneNumberEqualLengthRule(phoneNumber),
                            ]"
                            :error-messages="
                              errorMessages
                                .filter((x) =>
                                  [
                                    'phoneNumberRequired',
                                    'phoneNumberEqual',
                                  ].includes(x.ruleName)
                                )
                                .map((x) => x.text)
                                .join()
                            "
                          >
                            <template v-slot:append>
                              <CountryCodeSelect :value.sync="code" />
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-text-field
                        v-model="password"
                        :label="stringValues.password[selectedLanguage.code]"
                        color="purple"
                        :append-icon="
                          passwordShow
                            ? 'mdi-eye-outline'
                            : 'mdi-eye-off-outline'
                        "
                        :type="passwordShow ? 'text' : 'password'"
                        @click:append="passwordShow = !passwordShow"
                        :rules="[
                          passwordRequiredRule(password),
                          passwordMinLengthRule(password),
                          passwordMaxLengthRule(password),
                        ]"
                        :error-messages="
                          errorMessages
                            .filter((x) =>
                              [
                                'passwordRequired',
                                'passwordMin',
                                'passwordMax',
                              ].includes(x.ruleName)
                            )
                            .map((x) => x.text)
                            .join()
                        "
                      >
                      </v-text-field>
                      <v-btn
                        id="enter-btn"
                        width="100%"
                        class="
                          mt-2
                          purple
                          white--text
                          font-weight-regular
                          rounded-lg
                          text-capitalize
                        "
                        @click="enter()"
                        elevation="0"
                        :loading="loading"
                        :disabled="loading"
                      >
                        {{ stringValues.enter[selectedLanguage.code] }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <span class="text-body-2 grey--text darken-1">{{
                        stringValues.notAccount[selectedLanguage.code]
                      }}</span>
                      <v-btn
                        text
                        x-small
                        class="
                          text-body-2
                          purple--text
                          text-decoration-underline text-capitalize
                        "
                        to="/account/register"
                      >
                        {{ stringValues.register[selectedLanguage.code] }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <div>
                        <span class="text-subtitle-2">{{
                          stringValues.loginWith[selectedLanguage.code]
                        }}</span>
                      </div>
                      <div>
                        <v-btn
                          outlined
                          color="purple"
                          @click="openGoogleAuthentication()"
                        >
                          <v-icon>mdi-google</v-icon>
                        </v-btn>
                        <v-btn
                          outlined
                          color="purple"
                          class="mx-5 my-2"
                          @click="openFacebookAuthentication()"
                        >
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import socialNetworkIdentityInstance from "../../api/socialNetworkIdentity/instance";
import CountryCodeSelect from "../Common/CountryCodeSelect.vue";
export default {
  name: "Login",
  components: {
    CountryCodeSelect,
  },
  computed: {
    ...mapGetters({
      getLoginInfo: "account/GET_LOGIN_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
      set: function (newValue) {
        this.setLoginInfo(newValue);
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    code: null,
    phoneNumber: null,
    password: null,
    passwordShow: false,
    loading: false,
    modelState: [
      {
        ruleName: "passwordRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMin",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMax",
        status: false,
        text: null,
      },
      {
        ruleName: "phoneNumberRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "phoneNumberEqual",
        status: false,
        text: null,
      },
    ],
    errorMessages: [],
    countryPhoneNumberCode: null,
    instance: socialNetworkIdentityInstance,
  }),
  metaInfo() {
    return {
      title: this.stringValues.login[this.selectedLanguage.code],
      titleTemplate: "%s | EasyLife",
    };
  },
  methods: {
    ...mapActions({
      loginFromApi: "account/LOGIN_FROM_API",
      commitSocialNetworkAuthType: "common/COMMIT_SOCIAL_NETWORK_AUTH_TYPE",
    }),
    ...mapMutations({
      setLoginInfo: "account/SET_LOGIN_INFO",
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async enter() {
      var validateStatus = this.modelState.every((x) => x.status);
      if (validateStatus) {
        this.loading = true;

        let formattedPhoneNumber =
          "+" + this.countryPhoneNumberCode + " " + this.phoneNumber;
        // let removeCharacters = ["(", ")", "-", " "];
        // for (var i = 0; i < removeCharacters.length; i++) {
        //   formattedPhoneNumber = formattedPhoneNumber.replaceAll(
        //     removeCharacters[i],
        //     ""
        //   );
        // }

        await this.loginFromApi({
          UserName: formattedPhoneNumber,
          Password: this.password,
          ApplicationId: "b542a312-7ee8-43be-a8c4-3af3f148d067",
        });

        if (this.loginInfo.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.loginInfo.message,
          });

          var data = this.$router.resolve({
            path: "/",
          });
          document.location.href = data.href;
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.loginInfo.message,
          });
        }
        this.loading = false;
      } else {
        this.errorMessages = this.modelState
          .filter((x) => !x.status)
          .map((x) => ({
            ruleName: x.ruleName,
            text: x.text,
          }));
      }
    },
    passwordRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Enter password";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMin"
      );
      if (v != null && v.length < 6) {
        currentRule.status = false;
        currentRule.text = "Minimum password length 6 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMax"
      );
      if (v != null && v.length > 15) {
        currentRule.status = false;
        currentRule.text = "Maximum password length 255 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    phoneNumberRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "phoneNumberRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Enter phone number";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("phoneNumberRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    phoneNumberEqualLengthRule(v) {
      let formattedPhoneNumber = "+" + this.countryPhoneNumberCode + " " + v;
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "phoneNumberEqual"
      );
      if (v != null && formattedPhoneNumber.length < 10) {
        currentRule.status = false;
        currentRule.text = "Phone number incorrect format";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("phoneNumberEqual");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    openGoogleAuthentication() {
      document.location.href = `${this.instance.defaults.baseURL}/account/googleLogin`;
    },
    openFacebookAuthentication() {
      document.location.href = `${this.instance.defaults.baseURL}/account/facebookLogin`;
    },
  },
  watch: {
    phoneNumber: function () {
      var x = this.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,7})/);

      this.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "" + x[3] : "");
    },
    code: function () {
      this.countryPhoneNumberCode = this.code[2];
      this.phoneNumber = null;
    },
  },
  mounted() {},
};
</script>
<style scoped>
#enter-btn {
  width: 160px;
}
</style>