import axios from 'axios'

let languageObj = JSON.parse(localStorage.getItem('language'));
let language = 'ru';
if (languageObj != null)
    language = languageObj.code;

const instance = axios.create({
    baseURL: "https://collector.el-turk.com",
    headers: { 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('identity'))?.data.token, 'Accept-Language': language }
})

export default instance
