<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg mt-4">
      <v-row class="d-flex align-center">
        <v-col cols="6">
          <Weather />
        </v-col>
        <v-divider vertical />
        <v-col cols="6">
          <ExchangeRate />
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0" class="rounded-lg mt-8">
      <Menu />
    </v-card>
    <v-card elevation="0" class="rounded-lg mt-4 grey lighten-4">
      <Ads />
    </v-card>
    <!-- <v-card elevation="0" class="rounded-lg mt-4">
      <Map />
    </v-card> -->
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Weather from "./Weather.vue";
import ExchangeRate from "./ExchangeRate.vue";
// import Map from "./Map.vue";
import Menu from "./Menu.vue";
import Ads from "./Ads.vue";
export default {
  name: "Main",
  components: {
    Weather,
    ExchangeRate,
    // Map,
    Menu,
    Ads,
  },
  computed: {
    ...mapGetters({
      getSelectedRegion: "common/GET_SELECTED_REGION",
    }),
    selectedRegion: {
      get: function () {
        return this.getSelectedRegion;
      },
    },
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: this.selectedRegion.name,
      titleTemplate: "%s | EasyLife",
    };
  },
};
</script>
