import product from "../../../api/city/shop/index"

const GetPagedByCategoryId = (query) => {
    return new Promise((resolve) => {
        resolve(product.product.GetPagedByCategoryId(query))
    })
}

export default {
    namespaced: true,
    state: {
        productList: null
    },
    getters: {
        GET_PRODUCT_LIST(state) {
            return state.productList;
        }
    },
    mutations: {
        SET_PRODUCT_LIST(state, payload) {
            state.productList = payload;
        }
    },
    actions: {
        async GET_PRODUCT_LIST_FROM_API({ commit }, payload) {
            await GetPagedByCategoryId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PRODUCT_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PRODUCT_LIST', null);
                })
        }
    }
}