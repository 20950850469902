<template>
  <v-container>
    <div v-if="product.succeeded">
      <v-card elevation="0" class="rounded-lg">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="5" lg="5" xl="5">
              <div>
                <v-carousel
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="product.data.productImages.length > 1"
                >
                  <v-carousel-item
                    v-for="(item, i) in product.data.productImages"
                    :key="i"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      :src="item.path"
                      contain
                      height="100%"
                      width="100%"
                      @click="openImage()"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-col>
            <v-col cols="12" md="7" lg="7" xl="7">
              <v-card class="mx-2 my-2 rounded-lg" :elevation="0" width="100%">
                <v-card-text>
                  <div>
                    <span class="font-weight-bold purple--text">
                      <div v-if="product.data.priceTypeId == 1">
                        {{ product.data.price }} {{ product.data.currency }}
                      </div>
                      <div v-else>
                        {{ product.data.priceType.name }}
                      </div>
                    </span>
                  </div>
                  <div class="my-1">
                    <span class="font-weight-bold">{{
                      product.data.name
                    }}</span>
                  </div>
                  <div class="my-1">
                    <div>
                      <v-list>
                        <v-list-item two-line>
                          <v-list-item-avatar>
                            <img :src="product.data.user.imageLink" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="text-caption">{{
                              stringValues.salesMan[selectedLanguage.code]
                            }}</v-list-item-title>
                            <v-list-item-subtitle class="font-weight-bold"
                              >{{ product.data.user.firstName }}
                              {{
                                product.data.user.lastName
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>
                  <div class="my-1">
                    <v-icon>mdi-phone</v-icon>
                    <span>{{ product.data.user.phoneNumber }}</span>
                  </div>
                  <div class="my-1">
                    <v-icon>mdi-map-marker</v-icon>
                    <span>{{ product.data.region.name }}</span>
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span>{{ stringValues.options[selectedLanguage.code] }}</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-row
                        v-for="item in product.data.groupedParameterValues"
                        :key="item.parameterId"
                      >
                        <v-col cols="6" class="text-left">
                          <h4 class="font-weight-regular grey--text">
                            {{ item.parameter.name }}:
                          </h4>
                        </v-col>
                        <v-col cols="6" class="text-left">
                          <h4 class="font-weight-bold black--text">
                            {{
                              item.productParameterValueItemList
                                .map((x) => x.parameterCatalog.name)
                                .join()
                            }}
                          </h4>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span>{{
                    stringValues.description[selectedLanguage.code]
                  }}</span>
                </v-card-title>
                <v-card-text>
                  <div v-if="!viewFullComment">
                    <span
                      class="font-weight-regular d-inline-block text-truncate"
                      style="max-width: 100%"
                    >
                      {{ product.data.comment }}
                    </span>
                    <div>
                      <v-btn
                        x-small
                        text
                        color="purple"
                        class="px-0 text-capitalize font-weight-bold"
                        @click="viewFullComment = !viewFullComment"
                        >{{
                          stringValues.showAll[selectedLanguage.code]
                        }}...</v-btn
                      >
                    </div>
                  </div>
                  <div v-else>
                    <span class="font-weight-regular">
                      {{ product.data.comment }}
                    </span>
                    <div>
                      <v-btn
                        x-small
                        text
                        color="purple"
                        class="px-0 text-capitalize font-weight-bold"
                        @click="viewFullComment = !viewFullComment"
                        >{{
                          stringValues.showPartially[selectedLanguage.code]
                        }}...</v-btn
                      >
                    </div>
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span>{{
                    stringValues.location[selectedLanguage.code]
                  }}</span>
                </v-card-title>
                <v-card-text>
                  {{ stringValues.address[selectedLanguage.code] }}:
                  {{ product.data.address }}
                </v-card-text>
                <v-divider />
                <v-card-title>
                  <span class="black--text font-weight-regular">{{
                    stringValues.additionalInformation[selectedLanguage.code]
                  }}</span>
                </v-card-title>
                <v-card-text>
                  {{ stringValues.prefferedLanguage[selectedLanguage.code] }}:
                  <span class="black--text font-weight-bold">{{
                    product.data.prefferedLanguage
                  }}</span>
                  <div>
                    <v-btn
                      x-small
                      text
                      color="purple"
                      class="px-0 text-capitalize font-weight-bold"
                      @click="openMapDialog()"
                      >{{
                        stringValues.showOnMap[selectedLanguage.code]
                      }}</v-btn
                    >
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col cols="8">
                      <div>
                        <span>{{
                          new Date(product.data.insertedDate).toLocaleString()
                        }}</span>
                      </div>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <div>
                        <v-icon small>mdi-eye</v-icon>
                        <span class="font-weight-bold text-caption">{{
                          " " + product.data.viewsCount
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-text
                  class="text-center"
                  v-if="
                    loginInfo != null &&
                    loginInfo.data.userId == product.data.userId
                  "
                >
                  <v-card-title>
                    <span class="black--text font-weight-regular">{{
                      stringValues.moderatorComment[selectedLanguage.code]
                    }}</span>
                  </v-card-title>
                  <div>
                    <b>{{ stringValues.status[selectedLanguage.code] }}</b
                    >:
                    {{ product.data.status?.name }}
                  </div>
                  <div>
                    <b>{{ stringValues.error[selectedLanguage.code] }}</b
                    >:
                    {{ product.data.productFailureType?.message }}
                  </div>
                  <div>
                    <v-textarea
                      outlined
                      disabled
                      v-model="product.data.moderatorFeedback"
                      :label="stringValues.feedback[selectedLanguage.code]"
                      color="purple"
                    ></v-textarea>
                  </div>
                  <div>
                    <v-textarea
                      outlined
                      v-model="product.data.clientFeedback"
                      :label="stringValues.comment[selectedLanguage.code]"
                      color="purple"
                    ></v-textarea>
                  </div>
                  <div>
                    <v-btn
                      small
                      class="green white--text mx-2 mb-2 rounded-lg text-capitalize"
                      elevation="0"
                      >{{ stringValues.send[selectedLanguage.code] }}</v-btn
                    >
                  </div>
                  <v-divider />
                </v-card-text>
                <v-card-text class="text-center">
                  <v-btn
                    class="purple white--text mx-2 rounded-lg text-capitalize"
                    @click="sendMessage()"
                    small
                    elevation="0"
                    >{{ stringValues.write[selectedLanguage.code] }}</v-btn
                  >
                  <v-btn
                    class="green white--text mx-2 rounded-lg text-capitalize"
                    small
                    elevation="0"
                    @click="call()"
                    >{{ stringValues.call[selectedLanguage.code] }}</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <h4 class="red--text font-weight-regular">
        {{ stringValues.error[selectedLanguage.code] }}
      </h4>
    </div>
    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="grey">
            <v-spacer />
            <v-btn icon dark @click="dialog = false" color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="my-2">
          <v-carousel :show-arrows="product.data.productImages.length > 1">
            <v-carousel-item
              v-for="(item, i) in product.data.productImages"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-img
                :src="item.path"
                max-height="100%"
                max-width="100%"
              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MarketCategoryProductsDetails",
  components: {},
  computed: {
    ...mapGetters({
      getProduct: "market/GET_PRODUCT",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getLoginInfo: "account/GET_LOGIN_INFO",
    }),
    product: {
      get: function () {
        return this.getProduct;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
    },
  },
  data: () => ({
    viewFullComment: false,
    dialog: false,
  }),
  metaInfo() {
    return {
      title:
        this.product != null && this.product.succeeded
          ? this.product.data.name
          : "",
      titleTemplate: "%s | EasyLife",
       meta: [
        { property: 'og:title', content: this.product != null && this.product.succeeded ? this.product.data.name : "" },
        { property: 'og:description', content: this.product != null && this.product.succeeded ? this.product.data.comment : "" },
        { property: 'og:image', content: this.product != null && this.product.succeeded ? this.product.data.productImages[0].path : "" },
        { property: 'og:url', content: document.location.href },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProductByIdFromApi: "market/GET_PRODUCT_BY_ID_FROM_API",
    }),
    ...mapMutations({
      setDialog: "common/SET_DIALOG",
    }),
    call() {
      window.open(`tel:${this.product.data.user.phoneNumber}`);
    },
    openMapDialog() {
      this.setDialog({
        show: true,
        fullscreen: true,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: "Карта",
        view_id: 8,
        coords: {
          lat: this.product.data.latitude,
          lng: this.product.data.longitude,
        },
        isView: true,
      });
    },
    sendMessage() {
      this.$router.push({
        path: `/market/dialog/${this.product.data.user.id}`,
      });
    },
    async init(productId) {
      await this.getProductByIdFromApi({ id: productId });
    },
    openImage() {
      this.dialog = true;
    },
  },
  watch: {
    $route(to) {
      let productId = parseInt(to.params.productId, 10);
      this.init(productId);
    },
  },
  async created() {
    let productId = parseInt(this.$route.params.productId, 10);
    await this.init(productId);
  },
};
</script>