export default function (instance) {
    return {
        Login(payload) {
            return instance.post('/Account/Login', payload);
        },
        RegisterClient(payload) {
            return instance.post('/Account/RegisterClient', payload);
        },
        ChangeImageLink(payload) {
            return instance.post('/Account/ChangeImageLink', payload);
        },
        ChangePassword(payload) {
            return instance.post('/Account/ChangePassword', payload);
        },
        ChangePersonalData(payload) {
            return instance.post('/Account/ChangePersonalData', payload);
        },
        GetAllUsers() {
            return instance.get(`/Account/GetAllUsers`);
        },
        ChangeUserRoles(payload) {
            return instance.post(`/Account/ChangeUserRoles`, payload);
        },
        ResetPassword(payload){
            return instance.post(`/Account/ResetPassword`, payload);
        }
    }
}
