import account from '../api/socialNetworkIdentity/index'

const Get = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.Get(query))
    })
}


export default {
    namespaced: true,
    state: {
        response: null,
    },
    getters: {
        GET_RESPONSE(state) {
            return state.response;
        },
    },
    mutations: {
        SET_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        async GET_FROM_API({ commit }, payload) {
            await Get(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_RESPONSE', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_RESPONSE', { succeeded: false });
                })
        },
    }
}
