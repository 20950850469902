<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="lastName"
          :label="stringValues.surname[selectedLanguage.code]"
          color="green"
          type="text"
          outlined
          dense
          hide-details
          class="rounded-lg white"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="apartmentNum"
          :label="stringValues.apartmentNum[selectedLanguage.code]"
          color="green"
          type="text"
          outlined
          dense
          hide-details
          class="rounded-lg white"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="profile.data.user.phoneNumber"
          :label="stringValues.phoneNumber[selectedLanguage.code]"
          color="green"
          type="text"
          outlined
          dense
          hide-details
          class="rounded-lg white"
          disabled
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          class="purple white--text font-weight-regular rounded-lg"
          @click="apply()"
          elevation="0"
          :loading="loading"
          :disabled="loading"
          width="100%"
          small
        >
          <span class="text-capitalize">{{
            stringValues.sendToManager[selectedLanguage.code]
          }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "HouseApply",
  components: {},
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getAddUserStatus: "house/GET_ADD_USER_STATUS",
      getProfile: "account/GET_LOGIN_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    addUserStatus: {
      get: function () {
        return this.getAddUserStatus;
      },
    },
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    profile: {
      get: function () {
        return this.getProfile;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    lastName: null,
    apartmentNum: null,
    telephoneNumber: null,
    loading: false,
  }),
  methods: {
    ...mapActions({
      addUserFromApi: "house/ADD_USER_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    validateFields() {
      if (
        this.lastName == null ||
        this.lastName == undefined ||
        this.lastName.length == 0
      ) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Введите фамилию!",
        });
        return false;
      }

      if (
        this.apartmentNum == null ||
        this.apartmentNum == undefined ||
        this.apartmentNum.length == 0
      ) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Введите номер квартиры!",
        });
        return false;
      }

      return true;
    },
    async apply() {
      this.loading = true;

      if (!this.validateFields()) {
        this.loading = false;
        return;
      }

      await this.addUserFromApi({
        residentialComplexId: this.info.data.id,
        lastName: this.lastName,
        apartmentNum: this.apartmentNum,
        telephoneNumber: this.profile.data.user.phoneNumber,
      });

      if (this.addUserStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.addUserStatus.data,
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.addUserStatus.data,
        });
      }
      this.loading = false;
    },
  },
};
</script>
