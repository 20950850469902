<template>
  <v-container>
    <!-- Desktop -->
    <v-card
      elevation="0"
      class="rounded-lg my-8 grey lighten-4 hidden-sm-and-down"
    >
      <v-row>
        <v-col cols="12" class="text-center">
          <span class="purple--text darken-1 text-h6 font-weight-bold">{{
            lang == "ru" ? text.easyToUse : textEn.easyToUse
          }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col cols="12">
              <span
                class="purple--text darken-1 text-subtitle-2"
                style="font-weight: 600"
                >{{ lang == "ru" ? text.elHome : textEn.elHome }} -
              </span>
              <span class="text-subtitle-2" style="font-weight: 600">
                {{ lang == "ru" ? text.about : textEn.about }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img :src="image.use" max-height="60vh" contain> </v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="4">
              <v-img :src="image.accountRu" max-height="15vh" contain> </v-img>
            </v-col>
            <v-col cols="8">
              <v-img :src="image.accountMenuRu" max-height="15vh" contain>
              </v-img>
            </v-col>
            <v-col cols="12">
              <v-row
                class="d-flex align-center justify-center"
                v-for="(item, index) in lang == 'ru' ? listItems : listItemsEn"
                :key="index"
              >
                <v-col cols="2">
                  <v-img :src="image.ok" max-height="3vh" contain> </v-img>
                </v-col>
                <v-col cols="10">
                  <span class="text-subtitle-2" style="font-weight: 600">{{
                    item.title
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12">
              <Download :lang="lang" class="mx-auto" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- Mobile -->
    <v-card
      elevation="0"
      class="rounded-lg hidden-md-and-up"
      color="transparent"
    >
      <v-row no-gutters>
        <v-col cols="12" class="text-center">
          <span class="purple--text darken-1 text-h6 font-weight-bold">{{
            lang == "ru" ? text.easyToUse : textEn.easyToUse
          }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col cols="12">
              <span
                class="purple--text darken-1 text-subtitle-2"
                style="font-weight: 600"
                >{{ lang == "ru" ? text.elHome : textEn.elHome }} -
              </span>
              <span class="text-subtitle-2" style="font-weight: 600">
                {{ lang == "ru" ? text.about : textEn.about }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img :src="image.use" max-height="60vh" contain> </v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="12">
              <v-row
                class="d-flex align-center justify-center"
                v-for="(item, index) in lang == 'ru' ? listItems : listItemsEn"
                :key="index"
              >
                <v-col cols="2">
                  <v-img :src="image.ok" max-height="4vh" contain> </v-img>
                </v-col>
                <v-col cols="10">
                  <span class="text-subtitle-2" style="font-weight: 600">{{
                    item.title
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12">
              <Download :lang="lang" class="mx-auto" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import Download from "./Download.vue";
export default {
  name: "PromoUse",
  components: {
    Download,
  },
  props: {
    lang: {
      type: String,
      default() {
        return "ru";
      },
    },
  },
  data: () => ({
    image: {
      ok: require("../../assets/promo/ok.png"),
      accountRu: require("../../assets/promo/accountRu.svg"),
      accountMenuRu: require("../../assets/promo/accountMenuRu.svg"),
      use: require("../../assets/promo/use.png"),
      downloadRu: require("../../assets/promo/downloadRu.svg"),
      downloadEn: require("../../assets/promo/downloadEn.svg"),
    },
    listItems: [
      {
        title: "Оплачивай счета за коммунальные услуги прямо из приложения.",
      },
      {
        title: "Следи за новостями и событиями в твоем доме в режиме он-лайн.",
      },
      { title: "Просматривай камеры видеонаблюдения прямо из приложения." },
      {
        title: "Получай финансовые отчеты Управляющего прямо в приложении.",
      },
      {
        title: "Пиши сообщения Управляющему на родном языке и получай ответы.",
      },
    ],
    listItemsEn: [
      {
        title: "Pay your utility bills directly from the app.",
      },
      {
        title: "Follow news and events in your home online.",
      },
      { title: "View security cameras directly from the app." },
      {
        title: "Get your Manager's financial reports directly in the app.",
      },
      {
        title:
          "Write messages to the Manager in your native language and get answers.",
      },
    ],
    text: {
      elHome: "EasyLife HOMES",
      about: "новая система администрирования Жилых Комплексов в Турции.",
      easyToUse: "Легко использовать",
    },
    textEn: {
      elHome: "EasyLife HOMES",
      about: "A new system for managing of residential complexes in Turkey.",
      easyToUse: "Easy to USE",
    },
  }),
};
</script>