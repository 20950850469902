<template>
  <div class="my-8">
    <v-row class="d-flex align-center justify-center">
      <v-col cols="10" md="4" lg="3" xl="3">
        <v-row>
          <v-col cols="12">
            <v-card elevation="0" class="py-6 rounded-lg">
              <v-card-title class="black--text">
                <span class="mx-auto">{{
                  stringValues.restorePassword[selectedLanguage.code]
                }}</span>
              </v-card-title>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-row class="justify-center align-center">
                    <v-col cols="10" class="justify-center text-center">
                      <v-text-field
                        v-model="password"
                        :label="stringValues.newPassword[selectedLanguage.code]"
                        color="purple"
                        :append-icon="
                          passwordShow
                            ? 'mdi-eye-outline'
                            : 'mdi-eye-off-outline'
                        "
                        :type="passwordShow ? 'text' : 'password'"
                        @click:append="passwordShow = !passwordShow"
                        :rules="[
                          passwordRequiredRule(password),
                          passwordMinLengthRule(password),
                          passwordMaxLengthRule(password),
                        ]"
                        :error-messages="
                          errorMessages
                            .filter((x) =>
                              [
                                'passwordRequired',
                                'passwordMin',
                                'passwordMax',
                              ].includes(x.ruleName)
                            )
                            .map((x) => x.text)
                            .join()
                        "
                      >
                      </v-text-field>
                      <v-btn
                        id="apply-btn"
                        width="100%"
                        class="mt-3 purple white--text font-weight-regular rounded-lg text-capitalize"
                        @click="apply()"
                        elevation="0"
                        :loading="loading"
                        :disabled="loading"
                      >
                        {{ stringValues.apply[selectedLanguage.code] }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ResetPassword",
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getResetStatus: "account/GET_CHANGE_STATUS",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    resetStatus: {
      get: function () {
        return this.getResetStatus;
      },
    },
  },
  methods: {
    ...mapActions({
      resetPasswordFromApi: "account/RESET_PASSWORD_FROM_API",
    }),
    ...mapMutations({
      setLoginInfo: "account/SET_LOGIN_INFO",
      setSnackBar: "common/SET_SNACKBAR",
    }),
    passwordRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Enter password";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMin"
      );
      if (v != null && v.length < 6) {
        currentRule.status = false;
        currentRule.text = "Minimum password length 6 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMax"
      );
      if (v != null && v.length > 15) {
        currentRule.status = false;
        currentRule.text = "Maximum password length 255 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    async apply() {
      var validateStatus = this.modelState.every((x) => x.status);
      if (validateStatus) {
        this.loading = true;

        await this.resetPasswordFromApi({
          userId: this.userId,
          code: this.token,
          password: this.password,
        });

        if (this.resetStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.resetStatus.message,
          });

          var data = this.$router.resolve({
            path: "/",
          });
          document.location.href = data.href;
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.resetStatus.message,
          });
        }
        this.loading = false;
      } else {
        this.errorMessages = this.modelState
          .filter((x) => !x.status)
          .map((x) => ({
            ruleName: x.ruleName,
            text: x.text,
          }));
      }
    },
  },
  data: () => ({
    password: null,
    passwordShow: false,
    loading: false,
    modelState: [
      {
        ruleName: "passwordRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMin",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMax",
        status: false,
        text: null,
      },
    ],
    errorMessages: [],
    userId: null,
    token: null,
  }),
  created() {
    let userId = this.$route.params.userId;
    let token = this.$route.params.token;

    if (userId != undefined && userId != null) this.userId = userId;

    if (token != undefined && token != null) this.token = token;
  },
};
</script>