<template>
  <v-container>
    <!-- Desktop -->
    <v-card
      elevation="0"
      class="rounded-lg my-8 grey lighten-4 hidden-sm-and-down"
    >
      <v-row>
        <v-col cols="12" class="text-center">
          <span class="purple--text darken-1 text-h6 font-weight-bold">{{
            lang == "ru" ? text.easyToSale : textEn.easyToSale
          }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col cols="12">
              <span
                class="purple--text darken-1 text-subtitle-2"
                style="font-weight: 600"
                >{{ lang == "ru" ? text.elMarket : textEn.elMarket }} -
              </span>
              <span class="text-subtitle-2" style="font-weight: 600">
                {{ lang == "ru" ? text.about : textEn.about }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img :src="image.sale" max-height="60vh" contain> </v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="12">
              <v-img
                :src="lang == 'ru' ? image.marketMenuRu : image.marketMenuEn"
                max-height="20vh"
                contain
              >
              </v-img>
            </v-col>
            <v-col cols="12">
              <v-row
                class="d-flex align-center justify-center"
                v-for="(item, index) in lang == 'ru' ? listItems : listItemsEn"
                :key="index"
              >
                <v-col cols="2">
                  <v-img :src="image.ok" max-height="3vh" contain> </v-img>
                </v-col>
                <v-col cols="10">
                  <span class="text-subtitle-2" style="font-weight: 600">{{
                    item.title
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12">
              <Download :lang="lang" class="mx-auto" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- Mobile -->
    <v-card
      elevation="0"
      class="rounded-lg hidden-md-and-up"
      color="transparent"
    >
      <v-row no-gutters>
        <v-col cols="12" class="text-center">
          <span class="purple--text darken-1 text-h6 font-weight-bold">{{
            lang == "ru" ? text.easyToSale : textEn.easyToSale
          }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col cols="12">
              <span
                class="purple--text darken-1 text-subtitle-2"
                style="font-weight: 600"
                >{{ lang == "ru" ? text.elMarket : textEn.elMarket }} -
              </span>
              <span class="text-subtitle-2" style="font-weight: 600">
                {{ lang == "ru" ? text.about : textEn.about }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img
                :src="lang == 'ru' ? image.marketRu : image.marketEn"
                max-width="100%"
                contain
              >
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img :src="image.sale" max-height="60vh" contain> </v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row
            class="d-flex align-center justify-center"
            v-for="(item, index) in lang == 'ru' ? listItems : listItemsEn"
            :key="index"
          >
            <v-col cols="2">
              <v-img :src="image.ok" max-height="4vh" contain> </v-img>
            </v-col>
            <v-col cols="10">
              <span class="text-subtitle-2" style="font-weight: 600">{{
                item.title
              }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row no-gutters class="mt-2">
            <v-col cols="12">
              <Download :lang="lang" class="mx-auto" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import Download from "./Download.vue";
export default {
  name: "PromoSale",
  components: {
    Download,
  },
  props: {
    lang: {
      type: String,
      default() {
        return "ru";
      },
    },
  },
  data: () => ({
    image: {
      ok: require("../../assets/promo/ok.png"),
      marketMenuRu: require("../../assets/promo/marketMenuRu.svg"),
      marketMenuEn: require("../../assets/promo/marketMenuEn.svg"),
      sale: require("../../assets/promo/sale.png"),
      downloadRu: require("../../assets/promo/downloadRu.svg"),
      downloadEn: require("../../assets/promo/downloadEn.svg"),
      marketRu: require("../../assets/promo/marketRu.svg"),
      marketEn: require("../../assets/promo/marketEn.svg"),
    },
    listItems: [
      {
        title: "Размещай бесплатные объявления и их увидят тысячи человек.",
      },
      {
        title: "Ищи интересные тебе товары по рубрикам и цене.",
      },
      { title: "Делись объявлениями с друзьями прямо из приложения." },
      {
        title:
          "Переписывайся и торгуйся с продавцом на родном языке. Автоматический перевод сообщений.",
      },
    ],
    listItemsEn: [
      {
        title: "Post free ads and thousands of people will see them.",
      },
      {
        title: "Search for items of interest to you by category and price.",
      },
      { title: "Share ads with your friends right from the app." },
      {
        title:
          "Correspond and haggle with the seller in your native language. Automatic translation of messages.",
      },
    ],
    text: {
      elMarket: "EL-MARKET",
      about:
        "система подачи частных объявлений, структурированная по рубрикам.",
      easyToSale: "Легко продать и купить",
    },
    textEn: {
      elMarket: "EL-MARKET",
      about: "a system of private ads, structured by rubrics.",
      easyToSale: "Easy to SELL and BUY",
    },
  }),
};
</script>