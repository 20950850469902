<template>
  <v-card-text class="px-1 py-1" v-if="info.succeeded">
    <GmapMap
      :center="{ lat: info.data.latitude, lng: info.data.longitude }"
      :zoom="16"
      map-type-id="terrain"
      style="width: 100%; max-width: 100%; height: 30vh"
    >
      <GmapMarker
        :position="{ lat: info.data.latitude, lng: info.data.longitude }"
      />
    </GmapMap>
  </v-card-text>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HouseMap",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
  },
  data: () => ({}),
};
</script>
