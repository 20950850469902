<template>
  <v-container>
    <div v-if="list.succeeded && list.data.length > 0">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
          lg="6"
          xl="6"
          v-for="item in list.data"
          :key="item.id"
        >
          <v-card
            elevation="0"
            @click="open(item.id)"
            class="mx-1 my-1 rounded-lg"
          >
            <v-card-text>
              <v-row>
                <v-col cols="6" md="3" lg="3" xl="3">
                  <v-img
                    lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                    :src="'https://images.el-turk.com/Resize/GetThumbnail?link=' + item.images[0].path"
                    class="rounded-lg"
                    max-width="100%"
                    height="12vh"
                  />
                  <v-card elevation="0" max-height="1vh">
                    <span
                      class="
                        grey--text
                        text-caption
                        d-inline-block
                        text-truncate
                      "
                      style="max-width: 100%"
                      >{{ item.address }}
                    </span>
                  </v-card>
                </v-col>
                <v-col cols="6" md="9" lg="9" xl="9">
                  <v-card elevation="0" max-height="3vh">
                    <v-card-title class="py-0 px-0">
                      <span
                        class="
                          font-weight-bold
                          text-subtitle-2
                          purple--text
                          d-inline-block
                          text-truncate
                        "
                        style="max-width: 80%"
                        >{{ item.name }}</span
                      >
                      <v-spacer />
                      <v-icon
                        color="purple"
                        @click.stop="addToFavorites(item)"
                        v-if="!favorites.some((x) => x.id == item.id)"
                        >mdi-cards-heart-outline</v-icon
                      >
                      <v-icon
                        color="purple"
                        @click.stop="removeFromFavorites(item.id)"
                        v-else
                        >mdi-cards-heart</v-icon
                      >
                    </v-card-title>
                  </v-card>
                  <v-card elevation="0" max-height="3vh">
                    <span class="grey--text text-caption"
                      >{{ stringValues[categoryName][selectedLanguage.code] }}
                    </span>
                  </v-card>
                  <v-card elevation="0" max-height="3vh">
                    <v-icon
                      small
                      :color="
                        item.averageMark >= 1 ? 'green' : 'grey lighten-1'
                      "
                      >mdi-hand-front-left</v-icon
                    >
                    <v-icon
                      small
                      :color="
                        item.averageMark >= 2 ? 'green' : 'grey lighten-1'
                      "
                      >mdi-hand-front-left</v-icon
                    >
                    <v-icon
                      small
                      :color="
                        item.averageMark >= 3 ? 'green' : 'grey lighten-1'
                      "
                      >mdi-hand-front-left</v-icon
                    >
                    <v-icon
                      small
                      :color="
                        item.averageMark >= 4 ? 'green' : 'grey lighten-1'
                      "
                      >mdi-hand-front-left</v-icon
                    >
                    <v-icon
                      small
                      :color="
                        item.averageMark >= 5 ? 'green' : 'grey lighten-1'
                      "
                      >mdi-hand-front-left</v-icon
                    >
                  </v-card>
                  <v-card elevation="0" max-height="3vh">
                    <span class="font-weight-medium text-caption"
                      >{{ stringValues.distanceTo[selectedLanguage.code] }} -
                    </span>
                    <span class="font-weight-medium text-caption"
                      >{{ parseInt(item.distance, 10) }}
                      {{ stringValues.km[selectedLanguage.code] }}
                    </span>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="pagingStatus">
        <v-col cols="12">
          <v-hover v-slot="{ hover }">
            <v-alert
              dense
              class="purple white--text text-center rounded-lg"
              tile
              :elevation="hover ? 8 : 0"
              @click="getItemsByPage()"
            >
              <v-btn small text class="purple white--text" :loading="loading">
                <span>{{
                  stringValues.loadMore[selectedLanguage.code]
                }}</span></v-btn
              >
            </v-alert>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <h4 class="red--text font-weight-regular text-center my-2">
        {{ stringValues.notElements[selectedLanguage.code] }}
      </h4>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ListTemplate",
  computed: {
    ...mapGetters({
      getList: "collector/GET_LIST",
      getPagingStatus: "collector/GET_PAGING_STATUS",
      getSelectedParameters: "collector/GET_SELECTED_PARAMETERS",
      getLocation: "common/GET_LOCATION",
      getCollectorItems: "common/GET_COLLECTOR_ITEMS",
      getSelectedSort: "collector/GET_SELECTED_SORT",
      getSelectedRegion: "common/GET_SELECTED_REGION",
      getFavorites: "collector/GET_FAVORITES",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    list: {
      get: function () {
        return this.getList;
      },
    },
    location: {
      get: function () {
        return this.getLocation;
      },
    },
    collectorItems: {
      get: function () {
        return this.getCollectorItems;
      },
    },
    pagingStatus: {
      get: function () {
        return this.getPagingStatus;
      },
    },
    selectedParameters: {
      get: function () {
        return this.getSelectedParameters;
      },
    },
    selectedSort: {
      get: function () {
        return this.getSelectedSort;
      },
    },
    selectedRegion: {
      get: function () {
        return this.getSelectedRegion;
      },
    },
    favorites: {
      get: function () {
        return this.getFavorites;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    pageNumber: 1,
    categoryName: "list",
    loading: false,
  }),
  methods: {
    ...mapActions({
      getListPagingFromApi: "collector/GET_LIST_PAGING_FROM_API",
      addToFavoritesApi: "collector/ADD_TO_FAVORITES",
      removeFromFavoritesApi: "collector/REMOVE_FROM_FAVORITES",
    }),
    async open(id) {
      let splittedPath = this.$route.path.split("/");
      let splittedPathLength = splittedPath.length;
      if (splittedPathLength > 0) {
        let fieldName = splittedPath[splittedPathLength - 1];

        this.$router.push({ path: `/city/${fieldName}/${id}` });
      }
    },
    formatSelectedParameters() {
      let resultModel = this.selectedParameters.data.map((x) => ({
        id: x.id,
        parameterCatalogs: x.parameterCatalogs.filter((z) => z.selected),
      }));

      return resultModel;
    },
    async getItemsByPage() {
      if (this.loading) return;
      this.loading = true;
      let splittedPath = this.$route.path.split("/");
      let splittedPathLength = splittedPath.length;
      if (splittedPathLength > 0) {
        let fieldName = splittedPath[splittedPathLength - 1];
        let categoryId = this.collectorItems[fieldName];
        await this.getListPagingFromApi({
          regionId: this.selectedRegion.id,
          categoryId: categoryId,
          longitude: this.location.longitude,
          latitude: this.location.latitude,
          orderId: this.selectedSort != null ? this.selectedSort : 1,
          pageNumber: ++this.pageNumber,
          pageSize: 20,
          parameters: this.formatSelectedParameters(),
        });
      }
      this.loading = false;
    },
    addToFavorites(item) {
      let model = {
        id: item.id,
        name: item.name,
        comment:
          item.comment != null && item.comment.length > 250
            ? item.comment.substring(0, 250) + "..."
            : item.comment,
        image: item.images[0],
        distance: item.distance,
        averageMark: item.averageMark,
        address: item.address,
        categoryName: this.categoryName,
      };

      let splittedPath = this.$route.path.split("/");
      let splittedPathLength = splittedPath.length;
      if (splittedPathLength > 0) {
        let fieldName = splittedPath[splittedPathLength - 1];
        model.product = fieldName;
      }

      this.addToFavoritesApi(model);
    },
    removeFromFavorites(id) {
      this.removeFromFavoritesApi(id);
    },
  },
  async created() {
    let splittedPath = this.$route.path.split("/");
    let splittedPathLength = splittedPath.length;
    let fieldName = splittedPath[splittedPathLength - 1];
    this.categoryName = fieldName;
  },
};
</script>
