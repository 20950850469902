<template>
  <v-container>
    <span class="text-subtitle-2"
      >{{ stringValues.selectedRegion[selectedLanguage.code] }}:
    </span>
    <span class="text-subtitle-2 purple--text">{{ selectedRegion.name }}</span>
    <v-list>
      <v-card-subtitle class="purple white--text">
        <v-row>
          <v-col cols="8">
            <span>{{ stringValues.chooseRegion[selectedLanguage.code] }}</span>
          </v-col>
          <v-col cols="4" class="text-right">
            <v-btn
              x-small
              elevation="0"
              class="rounded-lg text-capitalize"
              @click="reset()"
              >{{ stringValues.reset[selectedLanguage.code] }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-list-item-group>
        <v-list-item
          v-for="item in subRegions"
          :key="item.id"
          @click="getSubRegions(item)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SelectRegion",
  computed: {
    ...mapGetters({
      getRegions: "common/GET_REGIONS",
      getSelectedRegion: "common/GET_SELECTED_REGION",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    regions: {
      get: function () {
        return this.getRegions;
      },
    },
    selectedRegion: {
      get: function () {
        return this.getSelectedRegion;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    subRegions: [],
  }),
  methods: {
    ...mapActions({
      commitSelectedRegion: "common/COMMIT_SELECTED_REGION",
    }),
    selectRegion(item) {
      this.commitSelectedRegion({ id: item.id, name: item.name });
    },
    getSubRegions(item) {
      this.selectRegion(item);
      if (item.subRegions.length > 0) {
        this.subRegions = item.subRegions;
      }
    },
    reset() {
      this.subRegions = this.regions.data;
    },
  },
  async created() {
    this.subRegions = this.regions.data;
  },
};
</script>