<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-lg my-8"
      v-if="userList.succeeded && userList.data.length > 0"
    >
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="stringValues.search[selectedLanguage.code]"
            single-line
            outlined
            dense
            hide-details
            class="mx-6"
            color="purple"
          ></v-text-field>
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="userList.data"
            :items-per-page="20"
            class="elevation-0"
            :loading="loading"
            :search="search"
          >
            <template slot="progress">
              <v-progress-linear
                color="purple"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:item.roles="{ item }">
              <v-btn tile x-small @click="openRoleDialog(item)">
                {{ item.roles.map((x) => x.name).join() }}
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else elevation="0">
      <h4 class="red--text font-weight-regular text-center">
        {{ stringValues.notElements[selectedLanguage.code] }}
      </h4>
    </v-card>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          {{ stringValues.roleAssignment[selectedLanguage.code] }}
        </v-card-title>
        <v-card-text class="my-2 text-center">
          <v-select
            v-if="selectedItem != null"
            v-model="selectedItem.roles"
            :items="allRoles"
            outlined
            dense
            small-chips
            color="purple"
            item-text="name"
            item-value="id"
            hide-details
            class="my-2"
            multiple
            return-object
          ></v-select>
          <v-btn
            small
            class="green white--text mx-2 rounded-lg text-capitalize"
            elevation="0"
            @click="apply()"
            >{{ stringValues.apply[selectedLanguage.code] }}</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="dialog = false"
            class="text-capitalize"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "MarketAppRoleAssignmentAdministrator",
  computed: {
    ...mapGetters({
      getUserList: "account/GET_USERS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getChangeStatus: "account/GET_CHANGE_STATUS",
    }),
    userList: {
      get: function () {
        return this.getUserList;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    changeStatus: {
      get: function () {
        return this.getChangeStatus;
      },
    },
  },
  data: () => ({
    selectedItem: null,
    dialog: false,
    search: "",
    headers: {
      ru: [
        {
          text: "Имя",
          value: "firstName",
          sortable: false,
        },
        {
          text: "Фамилия",
          value: "lastName",
          sortable: false,
        },
        {
          text: "E-Mail",
          value: "email",
          sortable: false,
        },
        {
          text: "Номер телефона",
          value: "phoneNumber",
          sortable: false,
        },
        {
          text: "Роли",
          value: "roles",
          sortable: false,
        },
      ],
      en: [
        {
          text: "First name",
          value: "firstName",
          sortable: false,
        },
        {
          text: "Last name",
          value: "lastName",
          sortable: false,
        },
        {
          text: "E-Mail",
          value: "email",
          sortable: false,
        },
        {
          text: "Phone number",
          value: "phoneNumber",
          sortable: false,
        },
        {
          text: "Roles",
          value: "roles",
          sortable: false,
        },
      ],
      tr: [
        {
          text: "İsim",
          value: "firstName",
          sortable: false,
        },
        {
          text: "Soyad",
          value: "lastName",
          sortable: false,
        },
        {
          text: "E-Mail",
          value: "email",
          sortable: false,
        },
        {
          text: "Telefon numarası",
          value: "phoneNumber",
          sortable: false,
        },
        {
          text: "Roller",
          value: "roles",
          sortable: false,
        },
      ],
    },
    loading: true,
    allRoles: [
      {
        concurrencyStamp: "3124a72f-7212-4af0-9508-952282f57dbe",
        id: "1d06c624-4cdc-4025-a6ef-20f0850303af",
        name: "CLIENT",
        normalizedName: null,
      },
      {
        concurrencyStamp: "dfb2ddcc-f132-4889-8316-cc072c3dd3c1",
        id: "29c0c512-1bb5-486c-a7bf-df814830306e",
        name: "MANAGER",
        normalizedName: null,
      },
      {
        concurrencyStamp: "1a57a175-9b11-414c-889f-fcae4ec42799",
        id: "318110e3-3a79-468e-9800-c47bc3087c9f",
        name: "AGENT",
        normalizedName: null,
      },
      {
        concurrencyStamp: "6401442e-59bc-4a9b-9c5a-0187e3e84408",
        id: "4e6f0dce-9a1e-4f76-a8c7-ce7ed7e48a1e",
        name: "SUPER_ADMIN",
        normalizedName: null,
      },
      {
        concurrencyStamp: "3c699c12-de75-4cf2-b07b-7105e20da95c",
        id: "bd7211fe-b5c5-431c-b197-dc71b34b4d9d",
        name: "APP_ADMIN",
        normalizedName: null,
      },
      {
        concurrencyStamp: "d7e34275-bf74-4802-86ac-e3eafd8fc594",
        id: "be55f026-65e8-4ff9-9e97-fba148a2a8b4",
        name: "ADMIN",
        normalizedName: null,
      },
    ],
  }),
  methods: {
    ...mapActions({
      getUsersFromApi: "account/GET_USERS_FROM_API",
      changeUserRolesFromApi: "account/CHANGE_USER_ROLES_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async getItemsFromApi() {
      this.loading = true;
      await this.getUsersFromApi();
      this.loading = false;
    },
    openRoleDialog(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    async apply() {
      await this.changeUserRolesFromApi({
        userId: this.selectedItem.id,
        roles: this.selectedItem.roles.map((x) => x.name),
      });
      if (this.changeStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Ok!",
        });
        this.selectedItem = null;
        this.dialog = false;
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Error!",
        });
        this.selectedItem = null;
        this.dialog = false;
      }
    },
  },
  async created() {
    await this.getItemsFromApi();
  },
};
</script>