import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import el from "../assets/icons/el.vue"
import shoppingbag from "../assets/icons/shopping-bag.vue"
import home from "../assets/icons/home.vue"
import menu from "../assets/icons/menu.vue"
import cafe from "../assets/icons/cafe.vue"
import shoppingCart from "../assets/icons/shopping-cart.vue"
import beauty from "../assets/icons/beauty.vue"
import government from "../assets/icons/government.vue"
import travel from "../assets/icons/travel.vue"
import health from "../assets/icons/health.vue"
import finance from "../assets/icons/finance.vue"
import carRent from "../assets/icons/car-rent.vue"
import realEstate from "../assets/icons/real-estate.vue"
import transport from "../assets/icons/transport.vue"
import education from "../assets/icons/education.vue"
import insurance from "../assets/icons/insurance.vue"
import hotel from "../assets/icons/hotel.vue"
import services from "../assets/icons/services.vue"
import instagram from "../assets/icons/instagram.vue"
import url from "../assets/icons/url.vue"
import customFireOn from "../assets/icons/fire-on.vue"
import customFireOff from "../assets/icons/fire-off.vue"



export default new Vuetify({
    icons: {
        values: {
            customEl: {
                component: el
            },
            customShoppingBag: {
                component: shoppingbag
            },
            customHome: {
                component: home
            },
            customMenu: {
                component: menu
            },
            customCafe: {
                component: cafe
            },
            customShoppingCart: {
                component: shoppingCart
            },
            customBeauty: {
                component: beauty
            },
            customGovernment: {
                component: government
            },
            customTravel: {
                component: travel
            },
            customHealth: {
                component: health
            },
            customFinance: {
                component: finance
            },
            customCarRent: {
                component: carRent
            },
            customRealEstate: {
                component: realEstate
            },
            customTransport: {
                component: transport
            },
            customEducation: {
                component: education
            },
            customInsurance: {
                component: insurance
            },
            customHotel: {
                component: hotel
            },
            customServices: {
                component: services
            },
            customInstagram: {
                component: instagram
            },
            customUrl: {
                component: url
            },
            customFireOn: {
                component: customFireOn
            },
            customFireOff: {
                component: customFireOff
            },
        }
    }
});
