<template>
  <v-container class="px-8 py-4">
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
        xl="4"
        class="d-flex justify-center align-center"
      >
        <span class="font-weight-bold text-subtitle-1">{{
          stringValues.paymentOfBills[selectedLanguage.code]
        }}</span>
      </v-col>
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-row>
          <v-col cols="6" md="4" lg="4" xl="4">
            <v-btn
              class="purple white--text rounded-lg"
              width="100%"
              elevation="0"
              @click="
                open(
                  'https://onlineislemler.turktelekom.com.tr/fatura-sorgulama-odeme/mobil'
                )
              "
            >
              <v-icon left>mdi-web-check</v-icon>
              <span class="text-caption text-capitalize">{{
                stringValues.internet[selectedLanguage.code]
              }}</span>
            </v-btn>
          </v-col>
          <v-col cols="6" md="4" lg="4" xl="4">
            <v-btn
              class="purple white--text rounded-lg"
              width="100%"
              elevation="0"
            >
              <v-icon left>mdi-fire</v-icon>
              <span class="text-caption text-capitalize">{{
                stringValues.gas[selectedLanguage.code]
              }}</span>
            </v-btn>
          </v-col>
          <v-col cols="6" md="4" lg="4" xl="4">
            <v-btn
              class="purple white--text rounded-lg"
              width="100%"
              elevation="0"
              @click="
                open(
                  'https://online.asat.gov.tr/webportal/index.php?wwsayfa=25'
                )
              "
            >
              <v-icon left>mdi-water-outline</v-icon>
              <span class="text-caption text-capitalize">{{
                stringValues.water[selectedLanguage.code]
              }}</span>
            </v-btn>
          </v-col>
          <v-col cols="6" md="4" lg="4" xl="4">
            <v-btn
              class="purple white--text rounded-lg"
              width="100%"
              elevation="0"
              @click="open('https://online.ckakdeniz.com.tr')"
            >
              <v-icon left>mdi-lightning-bolt-outline</v-icon>
              <span class="text-caption text-capitalize">{{
                stringValues.electricity[selectedLanguage.code]
              }}</span>
            </v-btn>
          </v-col>
          <v-col cols="6" md="4" lg="4" xl="4">
            <v-btn
              class="purple white--text rounded-lg"
              width="100%"
              elevation="0"
            >
              <v-icon left>mdi-home-city-outline</v-icon>
              <span class="text-caption text-capitalize">{{
                stringValues.aydat[selectedLanguage.code]
              }}</span>
            </v-btn>
          </v-col>
          <v-col cols="6" md="4" lg="4" xl="4">
            <v-btn
              class="purple white--text rounded-lg"
              width="100%"
              elevation="0"
            >
              <v-icon left>mdi-handshake</v-icon>
              <span class="text-caption text-capitalize">{{
                stringValues.other[selectedLanguage.code]
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HouseAccounts",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    water: null,
    electricity: null,
    gas: null,
  }),
  methods: {
    open(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
