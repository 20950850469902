import Vue from 'vue'
import Vuex from 'vuex'
import account from './account'
import common from './common'
import weather from './weather'
import exchangeRate from './exchangeRate'
import productCategory from './city/shop/productCategory'
import product from './city/shop/product'
import house from './house'
import market from './market'
import collector from './collector'
import houseChat from './houseChat'
import marketChat from './marketChat'
import hub from './hub'
import socialNetworkAccount from './socialNetworkAccount'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    common,
    weather,
    exchangeRate,
    productCategory,
    product,
    house,
    market,
    collector,
    houseChat,
    marketChat,
    hub,
    socialNetworkAccount
  }
})
