<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-lg my-8"
      v-if="productList.succeeded && productList.data.length > 0"
    >
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="productList.data"
            :items-per-page="20"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
          >
            <template slot="progress">
              <v-progress-linear
                color="purple"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:item.enter="{ item }">
              <v-btn small fab text @click="openAd(item.pointId)">
                <v-icon color="black">mdi-location-enter</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.startDate="{ item }">
              <span>{{ new Date(item.startDate).toLocaleDateString() }}</span>
            </template>
            <template v-slot:item.endDate="{ item }">
              <span>{{ new Date(item.endDate).toLocaleDateString() }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="item.isConfirmed">
                <v-btn
                  x-small
                  fab
                  class="green white--text"
                  text
                  @click="confirmItem(item)"
                  ><v-icon x-small>mdi-check</v-icon></v-btn
                >
              </div>
              <div v-else>
                <v-btn
                  x-small
                  fab
                  class="red white--text"
                  text
                  @click="confirmItem(item)"
                >
                  <v-icon x-small>mdi-minus</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:item.edit="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Edit</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.comment"
                            :label="
                              headers[selectedLanguage.code].find(
                                (x) => x.value == 'comment'
                              ).text
                            "
                            color="purple"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.startDate"
                                hide-details
                                color="purple"
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :label="
                                  stringValues.dateStart[selectedLanguage.code]
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.startDate"
                              no-title
                              scrollable
                              color="purple"
                              :locale="
                                selectedLanguage != undefined &&
                                selectedLanguage != null
                                  ? selectedLanguage.code
                                  : 'en'
                              "
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.endDate"
                                hide-details
                                color="purple"
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :label="
                                  stringValues.dateEnd[selectedLanguage.code]
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.endDate"
                              no-title
                              scrollable
                              color="purple"
                              :locale="
                                selectedLanguage != undefined &&
                                selectedLanguage != null
                                  ? selectedLanguage.code
                                  : 'en'
                              "
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="purple" text @click="close">
                      {{ stringValues.cancel[selectedLanguage.code] }}
                    </v-btn>
                    <v-btn color="purple" text @click="save">
                      {{ stringValues.apply[selectedLanguage.code] }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
          <v-pagination
            circle
            v-model="productList.pageNumber"
            :length="productList.totalPages"
            color="purple"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else elevation="0">
      <h4 class="red--text font-weight-regular text-center">
        {{ stringValues.notElements[selectedLanguage.code] }}
      </h4>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "CollectorOrdersAdministrator",
  components: {},
  computed: {
    ...mapGetters({
      getProductList: "house/GET_LIST",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getActionStatus: "house/GET_ACTION_STATUS",
    }),
    productList: {
      get: function () {
        return this.getProductList;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    headers: {
      ru: [
        { text: "Открыть", value: "enter", sortable: false, align: "center" },
        { text: "Название", value: "name", sortable: false, align: "center" },
        {
          text: "Начало",
          value: "startDate",
          sortable: false,
          align: "center",
        },
        {
          text: "Конец",
          value: "endDate",
          sortable: false,
        },
        { text: "Комментарий", value: "comment", sortable: false },
        {
          text: "Статус",
          value: "actions",
          sortable: false,
          align: "center",
        },
        {
          text: "Изменить",
          value: "edit",
          sortable: false,
          align: "center",
        },
      ],
      en: [
        { text: "Open", value: "enter", sortable: false, align: "center" },
        { text: "Name", value: "name", sortable: false, align: "center" },
        {
          text: "Start",
          value: "startDate",
          sortable: false,
          align: "center",
        },
        {
          text: "End",
          value: "endDate",
          sortable: false,
          align: "center",
        },
        { text: "Comment", value: "comment", sortable: false },
        {
          text: "Status",
          value: "actions",
          sortable: false,
          align: "center",
        },
        {
          text: "Edit",
          value: "edit",
          sortable: false,
          align: "center",
        },
      ],
      tr: [
        { text: "Açmak", value: "enter", sortable: false, align: "center" },
        { text: "İsim", value: "name", sortable: false, align: "center" },
        {
          text: "Başlangıç",
          value: "startDate",
          sortable: false,
          align: "center",
        },
        {
          text: "Tamamlanma",
          value: "endDate",
          sortable: false,
          align: "center",
        },
        { text: "Bir yorum", value: "comment", sortable: false },
        {
          text: "Durum",
          value: "actions",
          sortable: false,
          align: "center",
        },
        {
          text: "Değiştirmek",
          value: "edit",
          sortable: false,
          align: "center",
        },
      ],
    },
    dialog: false,
    editedItem: {
      id: 0,
      startDate: "",
      endDate: "",
      comment: "",
    },
    defaultItem: {
      id: 0,
      startDate: "",
      endDate: "",
      comment: "",
    },
    loading: true,
    editedIndex: -1,
    menu: false,
    menu1: false,
  }),
  methods: {
    ...mapActions({
      getOrderListFromApi: "house/ADMIN_GET_ORDER_FROM_API",
      confirmByOrderIdFromApi: "house/ADMIN_CONFIRM_BY_ORDER_ID_FROM_API",
      updateFromApi: "house/ADMIN_UPDATE_BY_ORDER_ID_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async getItemsFromApi(pageNumber, pageSize) {
      this.loading = true;
      await this.getOrderListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
      });
      this.loading = false;
    },
    openAd(productId) {
      window.open(`/city/food/${productId}`, "_blank");
    },
    async confirmItem(item) {
      if (confirm("Вы уверены?")) {
        await this.confirmByOrderIdFromApi({
          id: item.id,
        });
        if (this.actionStatus.succeeded) {
          let currentItem = this.productList.data.find((x) => x.id == item.id);
          if (currentItem != null) {
            currentItem.isConfirmed = !currentItem.isConfirmed;
          }
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Успешно!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: "Ошибка!",
          });
        }
      }
    },
    editItem(item) {
      this.editedIndex = this.productList.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.updateFromApi({
          id: this.editedItem.id,
          comment: this.editedItem.comment,
          startDate: this.editedItem.startDate,
          endDate: this.editedItem.endDate,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          Object.assign(
            this.productList.data[this.editedIndex],
            this.editedItem
          );
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      }
      this.close();
    },
  },
  watch: {
    "productList.pageNumber": function () {
      this.getItemsFromApi(this.productList.pageNumber, 20);
    },
  },
  async created() {
    this.getItemsFromApi(1, 20);
  },
};
</script>