<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="description"
              :label="stringValues.text[selectedLanguage.code]"
              color="purple"
              type="text"
              outlined
              dense
              hide-details
              class="rounded-0 white"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-card elevation="0" class="text-center">
              <div>
                <v-icon
                  large
                  :color="mark >= 1 ? 'green' : 'grey lighten-1'"
                  @click="mark = 1"
                  >mdi-hand-front-left</v-icon
                >
                <v-icon
                  large
                  :color="mark >= 2 ? 'green' : 'grey lighten-1'"
                  @click="mark = 2"
                  >mdi-hand-front-left</v-icon
                >
                <v-icon
                  large
                  :color="mark >= 3 ? 'green' : 'grey lighten-1'"
                  @click="mark = 3"
                  >mdi-hand-front-left</v-icon
                >
                <v-icon
                  large
                  :color="mark >= 4 ? 'green' : 'grey lighten-1'"
                  @click="mark = 4"
                  >mdi-hand-front-left</v-icon
                >
                <v-icon
                  large
                  :color="mark >= 5 ? 'green' : 'grey lighten-1'"
                  @click="mark = 5"
                  >mdi-hand-front-left</v-icon
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn
              class="
                mt-2
                purple
                white--text
                font-weight-regular
                rounded-lg
                text-capitalize
              "
              @click="add()"
              elevation="0"
              :loading="loading"
              :disabled="loading"
              small
            >
              {{ stringValues.add[selectedLanguage.code] }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "AddReviewTemplate",
  computed: {
    ...mapGetters({
      getInfo: "collector/GET_INFO",
      getAddReviewStatus: "collector/GET_ADD_REVIEW_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    addReviewStatus: {
      get: function () {
        return this.getAddReviewStatus;
      },
    },
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    description: null,
    mark: null,
    loading: false,
  }),
  methods: {
    ...mapActions({
      addReviewFromApi: "collector/ADD_REVIEW_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async add() {
      this.loading = true;
      await this.addReviewFromApi({
        pointId: this.info.data.id,
        description: this.description,
        mark: this.mark,
      });

      if (this.addReviewStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.loading = false;
    },
  },
  async created() {},
};
</script>
