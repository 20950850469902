<template>
  <v-container>
    <!-- Desktop -->
    <v-card
      elevation="0"
      class="rounded-lg my-8 grey lighten-4 hidden-sm-and-down"
    >
      <v-row>
        <v-col cols="12" class="text-center">
          <span
            class="text-uppercase text-h5 font-weight-bold"
            style="color: #4dcc1d"
            >{{ lang == "ru" ? text.allEasy : textEn.allEasy }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row
            v-for="(item, index) in lang == 'ru' ? easyItems : easyItemsEn"
            :key="'ei_' + index"
            no-gutters
          >
            <v-col cols="12">
              <span
                class="purple--text text-subtitle-2"
                style="font-weight: 600"
                >{{ item.title }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img :src="image.easy" max-height="60vh" contain> </v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="4">
              <v-img :src="image.language" max-height="3vh" contain> </v-img>
            </v-col>
            <v-col cols="8">
              <div class="text-right text-subtitle-2" style="font-weight: 600">
                <span>{{
                  lang == "ru" ? text.language : textEn.language
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-center">
            <v-col cols="9">
              <div class="text-left text-subtitle-2" style="font-weight: 600">
                <span>{{ lang == "ru" ? text.route : textEn.route }}</span>
              </div>
            </v-col>
            <v-col cols="3">
              <v-img :src="image.route" max-height="7vh" contain> </v-img>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-center">
            <v-col cols="3">
              <v-img :src="image.ad" max-height="7vh" contain> </v-img>
            </v-col>
            <v-col cols="9">
              <div class="text-left text-subtitle-2" style="font-weight: 600">
                <span>{{ lang == "ru" ? text.ad : textEn.ad }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-center">
            <v-col cols="9">
              <div class="text-left text-subtitle-2" style="font-weight: 600">
                <span>{{ lang == "ru" ? text.home : textEn.home }}</span>
              </div>
            </v-col>
            <v-col cols="3">
              <v-img :src="image.home" max-height="7vh" contain> </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-2">
              <Download :lang="lang" class="mx-auto" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- Mobile -->
    <v-card
      elevation="0"
      class="rounded-lg hidden-md-and-up"
      color="transparent"
    >
      <v-row no-gutters>
        <v-col cols="12" class="text-left">
          <span
            class="text-uppercase text-h5 font-weight-bold"
            style="color: #4dcc1d"
            >{{ lang == "ru" ? text.allEasy : textEn.allEasy }}</span
          >
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row
            v-for="(item, index) in lang == 'ru' ? easyItems : easyItemsEn"
            :key="'ei_' + index"
            no-gutters
          >
            <v-col cols="12">
              <span
                class="purple--text text-subtitle-2"
                style="font-weight: 600"
                >{{ item.title }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-img :src="image.easy" max-height="60vh" contain> </v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="4">
              <v-img :src="image.language" max-width="100%" contain> </v-img>
            </v-col>
            <v-col cols="8">
              <div class="text-right text-subtitle-2" style="font-weight: 600">
                <span>{{
                  lang == "ru" ? text.language : textEn.language
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-center">
            <v-col cols="9">
              <div class="text-left text-subtitle-2" style="font-weight: 600">
                <span>{{ lang == "ru" ? text.route : textEn.route }}</span>
              </div>
            </v-col>
            <v-col cols="3">
              <v-img :src="image.route" max-width="12vh" contain> </v-img>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-center">
            <v-col cols="3">
              <v-img :src="image.ad" max-height="12vh" contain> </v-img>
            </v-col>
            <v-col cols="9">
              <div class="text-left text-subtitle-2" style="font-weight: 600">
                <span>{{ lang == "ru" ? text.ad : textEn.ad }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-center">
            <v-col cols="9">
              <div class="text-left text-subtitle-2" style="font-weight: 600">
                <span>{{ lang == "ru" ? text.home : textEn.home }}</span>
              </div>
            </v-col>
            <v-col cols="3">
              <v-img :src="image.home" max-height="15vh" contain> </v-img>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12">
              <Download :lang="lang" class="mx-auto" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import Download from "./Download.vue";
export default {
  name: "PromoEasy",
  components: {
    Download,
  },
  props: {
    lang: {
      type: String,
      default() {
        return "ru";
      },
    },
  },
  data: () => ({
    image: {
      easy: require("../../assets/promo/1.png"),
      language: require("../../assets/promo/languages.svg"),
      route: require("../../assets/promo/route.svg"),
      ad: require("../../assets/promo/ad.svg"),
      home: require("../../assets/promo/elhomes.svg"),
      downloadRu: require("../../assets/promo/downloadRu.svg"),
      downloadEn: require("../../assets/promo/downloadEn.svg"),
    },
    text: {
      elHome: "EasyLife HOMES",
      allEasy: "ВСЁ ПРОСТО!",
      language:
        "Выбери свой родной язык и получай сообщения с автоматическим переводом.",
      route:
        "Находи любое место (магазин, ресторан, достопримечательность), смотри на карте и прокладывай маршрут.",
      ad: "Подавай объявление о продаже или найди то, что тебе нужно на мультиязычном портале частных объявлений.",
      home: "Оплачивай коммунальные платежи, смотри отчеты Управляющего и участвуй в жизни твоего дома с EasyLife HOMES",
    },
    textEn: {
      elHome: "EasyLife HOMES",
      allEasy: "EVERYTHING EASY!",
      language:
        "Choose your native language and receive messages with automatic translation.",
      route:
        "Find any place (store, restaurant, landmark), look at the map and create a route.",
      ad: "Place an ad for sale or find what you need on the multilingual private classifieds portal.",
      home: "Pay your utility bills, see your property manager's reports and get involved in your home's life with EasyLife HOMES.",
    },
    easyItems: [
      { title: "Легко НАЙТИ" },
      { title: "Легко ПРОДАТЬ и КУПИТЬ" },
      { title: "Легко ИСПОЛЬЗОВАТЬ" },
    ],
    easyItemsEn: [
      { title: "Easy to FIND" },
      { title: "Easy to SELL and BUY" },
      { title: "Easy to USE" },
    ],
  }),
};
</script>