<template>
  <div>
    <v-card
      elevation="3"
      class="text-center mx-auto my-2 white--text rounded-lg"
      color="#4dcc1d"
      max-width="320"
    >
      <v-row>
        <v-col cols="12">
          <span class="text-subtitle-2" style="font-weight: 600">{{
            lang == "ru" ? text.downloadNowRu : text.downloadNowEn
          }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-img
            :src="image.googlePlay"
            contain
            max-width="120"
            class="my-2 mx-auto"
            @click="openGooglePlay()"
            style="cursor: pointer"
          ></v-img>
        </v-col>
        <v-col cols="6">
          <v-img
            :src="image.appStore"
            contain
            max-width="120"
            class="my-2 mx-auto"
            @click="openAppStore()"
            style="cursor: pointer"
          ></v-img>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "PromoDownload",
  props: {
    lang: {
      type: String,
      default() {
        return "ru";
      },
    },
  },
  data: () => ({
    image: {
      googlePlay: require("../../assets/promo/gp.png"),
      appStore: require("../../assets/promo/as.png"),
    },
    text: {
      downloadNowRu: "Скачивай прямо сейчас",
      downloadNowEn: "Download now",
    },
  }),
  methods: {
    openGooglePlay() {
      window.fbq("track", "InitiateCheckout", { value: "Download", currency: "Android" });
      window.open(
        `https://play.google.com/store/apps/details?id=com.easy_life.easy_life`,
        "_blank"
      );
    },
    openAppStore() {
      window.fbq("track", "ViewContent", { value: "download", currency: "IOS" });
      window.open(
        `https://apps.apple.com/kz/app/easylife-turkiye/id1671635384`,
        "_blank"
      );
    },
  },
};
</script>