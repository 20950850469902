<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8 grey lighten-4">
      <v-card-title>
        <span>Панель управления администратора ЖК</span>
        <v-spacer />
        <v-autocomplete
          v-model="selectedRcId"
          :items="list.data"
          label="Выбрать ЖК"
          item-text="name"
          item-value="id"
          hide-details
          color="purple"
        >
        </v-autocomplete>
      </v-card-title>
      <v-row>
        <v-col cols="12">
          <v-tabs grow center-active color="purple" v-model="selectedItem">
            <v-tab>Заявки</v-tab>
            <v-tab>Новости</v-tab>
            <v-tab>Документы</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Appliances
            v-if="selectedItem == 0"
            :rcId="selectedRcId"
            :key="selectedRcId"
          />
          <News
            v-else-if="selectedItem == 1"
            :rcId="selectedRcId"
            :key="selectedRcId"
          />
          <Documents
            v-else-if="selectedItem == 2"
            :rcId="selectedRcId"
            :key="selectedRcId"
          />
          <div v-else></div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Appliances from "./Appliances.vue";
import News from "./News.vue";
import Documents from "./Documents.vue";
export default {
  name: "MainAdministrator",
  components: {
    Appliances,
    News,
    Documents,
  },
  computed: {
    ...mapGetters({
      getList: "house/GET_LIST",
    }),
    list: {
      get: function () {
        return this.getList;
      },
    },
  },
  data: () => ({
    selectedItem: 0,
    selectedRcId: null,
  }),
  methods: {
    ...mapActions({
      getListFromApi: "house/ADMIN_GET_LIST_BY_USER_ID_FROM_API",
    }),
  },
  async created() {
    await this.getListFromApi({
      pageNumber: 1,
      pageSize: 20,
    });
  },
};
</script>
