export default function (instance) {
    return {
        AdminGetPagedPoints(payload) {
            return instance.get(`/Admin/GetPaged?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}`);
        },
        AdminUpdatePoints(payload) {
            return instance.put(`/Admin/Update`, payload);
        },
        AdminGetPointsExcel() {
            return instance.get(`/Admin/GetPagedExcel`);
        },
    }
}
