<template>
  <div>
    <v-slide-group show-arrows v-if="list != null" class="pa-4">
      <v-slide-item v-for="item in list.data" :key="item.id">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card
              class="mx-2 my-2 rounded-lg"
              :elevation="0"
              @click="open(item)"
            >
              <v-card-text class="px-0 pt-0">
                <v-img
                  lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                  :src="item.imageLink"
                  class="rounded-lg mx-auto"
                  height="96"
                  width="96"
                >
                </v-img>
              </v-card-text>
              <v-card-text class="text-center text-subtitle-2 py-0">
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 96px"
                  >{{ item.name }}</span
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-slide-item>
    </v-slide-group>
    <div v-else>
      <v-skeleton-loader type="image" class="rounded-lg"></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MarketProductCategoryList",
  computed: {
    ...mapGetters({
      getAll: "market/GET_PRODUCT_CATEGORIES",
    }),
    list: {
      get: function () {
        return this.getAll;
      },
    },
  },
  data: () => ({}),
  methods: {
    open(item) {
      if (item.subProductCategories.length > 0) {
        this.$router.push({
          path: "/market/" + item.id,
        });
      }
    },
  },
};
</script>