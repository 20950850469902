<template>
  <v-container>
    <div>Error</div>
  </v-container>
</template>
<script>
export default {
  name: "FacebookError",
  data: () => ({}),
};
</script>