<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8 grey lighten-4">
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <FilterDiv />
          <v-row v-if="loading" no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" v-for="n in 6" :key="n">
              <v-skeleton-loader
                class="mx-1 my-1 rounded-lg"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <ListTemplate v-else />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import FilterDiv from "./FilterDiv.vue";
import ListTemplate from "./ListTemplate.vue";
export default {
  name: "MainTemplate",
  components: {
    FilterDiv,
    ListTemplate,
  },
  computed: {
    ...mapGetters({
      getLocation: "common/GET_LOCATION",
      getCollectorItems: "common/GET_COLLECTOR_ITEMS",
      getSelectedParameters: "collector/GET_SELECTED_PARAMETERS",
      getSelectedSort: "collector/GET_SELECTED_SORT",
      getSelectedRegion: "common/GET_SELECTED_REGION",
      getParameters: "collector/GET_PARAMETERS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    location: {
      get: function () {
        return this.getLocation;
      },
    },
    collectorItems: {
      get: function () {
        return this.getCollectorItems;
      },
    },
    selectedParameters: {
      get: function () {
        return this.getSelectedParameters;
      },
      set: function (newVal) {
        return this.setSelectedParameters(newVal);
      },
    },
    selectedSort: {
      get: function () {
        return this.getSelectedSort;
      },
    },
    selectedRegion: {
      get: function () {
        return this.getSelectedRegion;
      },
    },
    parameters: {
      get: function () {
        return this.getParameters;
      },
      set: function (newVal) {
        this.setParameters(newVal);
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    categoryId: null,
    loading: true,
    productName: "",
  }),
  metaInfo() {
    return {
      title: this.stringValues[this.productName][this.selectedLanguage.code],
      titleTemplate: "%s | EasyLife",
    };
  },
  methods: {
    ...mapActions({
      getParametersFromApi: "collector/GET_PARAMETERS_FROM_API",
      getListFromApi: "collector/GET_LIST_FROM_API",
    }),
    ...mapMutations({
      setParameters: "collector/SET_PARAMETERS",
      setSelectedParameters: "collector/SET_SELECTED_PARAMETERS",
    }),
    formatSelectedParameters() {
      let splittedPath = this.$route.path.split("/");
      let splittedPathLength = splittedPath.length;
      let fieldName = splittedPath[splittedPathLength - 1];
      if (this.selectedParameters.product == fieldName) {
        let resultModel = this.selectedParameters.data.map((x) => ({
          id: x.id,
          parameterCatalogs: x.parameterCatalogs.filter((z) => z.selected),
        }));

        return resultModel;
      } else {
        let resultModel = (this.parameters.data = this.parameters.data.map(
          (x) => ({
            id: x.id,
            name: x.name,
            parameterCatalogs: x.parameterCatalogs.map((z) => ({
              id: z.id,
              name: z.name,
              parameterId: z.parameterId,
              selected: true,
            })),
          })
        ));
        return resultModel;
      }
    },
  },
  watch: {
    selectedParameters: {
      handler: function () {
        let splittedPath = this.$route.path.split("/");
        let splittedPathLength = splittedPath.length;
        if (splittedPathLength > 0) {
          let fieldName = splittedPath[splittedPathLength - 1];
          let categoryId = this.collectorItems[fieldName];
          let formattedSelectedParameters = this.formatSelectedParameters();
          if (this.location != null && this.selectedParameters.load) {
            this.getListFromApi({
              regionId: this.selectedRegion.id,
              categoryId: categoryId,
              longitude: this.location.longitude,
              latitude: this.location.latitude,
              orderId: this.selectedSort != null ? this.selectedSort : 1,
              pageNumber: 1,
              pageSize: 20,
              parameters: formattedSelectedParameters,
            });
            this.selectedParameters.load = false;
          }
        }
      },
      deep: true,
    },
    selectedSort: function () {
      let splittedPath = this.$route.path.split("/");
      let splittedPathLength = splittedPath.length;
      if (splittedPathLength > 0) {
        let fieldName = splittedPath[splittedPathLength - 1];
        let categoryId = this.collectorItems[fieldName];

        if (this.location != null) {
          let formattedSelectedParameters = this.formatSelectedParameters();
          this.getListFromApi({
            regionId: this.selectedRegion.id,
            categoryId: categoryId,
            longitude: this.location.longitude,
            latitude: this.location.latitude,
            orderId: this.selectedSort != null ? this.selectedSort : 1,
            pageNumber: 1,
            pageSize: 20,
            parameters: formattedSelectedParameters,
          });
        }
      }
    },
  },
  async created() {
    this.loading = true;
    let splittedPath = this.$route.path.split("/");
    let splittedPathLength = splittedPath.length;
    if (splittedPathLength > 0) {
      let fieldName = splittedPath[splittedPathLength - 1];
      this.productName = fieldName;
      let categoryId = this.collectorItems[fieldName];
      await this.getParametersFromApi({ id: categoryId });

      if (this.selectedParameters.data.length == 0) {
        this.parameters.data = this.parameters.data.map((x) => ({
          id: x.id,
          name: x.name,
          parameterCatalogs: x.parameterCatalogs.map((z) => ({
            id: z.id,
            name: z.name,
            parameterId: z.parameterId,
            selected: true,
          })),
        }));
      } else {
        if (this.selectedParameters.product == fieldName) {
          this.parameters.data = this.selectedParameters.data;
        } else {
          this.parameters.data = this.parameters.data.map((x) => ({
            id: x.id,
            name: x.name,
            parameterCatalogs: x.parameterCatalogs.map((z) => ({
              id: z.id,
              name: z.name,
              parameterId: z.parameterId,
              selected: true,
            })),
          }));
        }
      }

      if (this.location != null) {
        await this.getListFromApi({
          regionId: this.selectedRegion.id,
          categoryId: categoryId,
          longitude: this.location.longitude,
          latitude: this.location.latitude,
          orderId: this.selectedSort != null ? this.selectedSort : 1,
          pageNumber: 1,
          pageSize: 20,
          parameters: this.formatSelectedParameters(),
        });
      }
    }
    this.loading = false;
  },
};
</script>
