<template>
  <v-container>
    <v-slide-group>
      <v-slide-item v-for="n in 8" :key="n">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters class="text-center">
              <v-col cols="12">
                <v-card
                  class="mx-1 my-1 rounded-lg grey lighten-4 pa-2"
                  :elevation="0"
                  @click="to(topItems[n - 1].path)"
                >
                  <v-icon large color="black">{{
                    topItems[n - 1].icon
                  }}</v-icon>
                  <div
                    class="font-weight-bold text-caption text-truncate"
                    style="max-width: 100%"
                  >
                    {{
                      stringValues[topItems[n - 1].title][selectedLanguage.code]
                    }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="text-center">
              <v-col cols="12">
                <v-card
                  class="mx-1 my-1 rounded-lg grey lighten-4 pa-2"
                  :elevation="0"
                  @click="to(bottomItems[n - 1].path)"
                >
                  <v-icon large color="black">{{
                    bottomItems[n - 1].icon
                  }}</v-icon>
                  <div
                    class="font-weight-bold text-caption text-truncate"
                    style="max-width: 100%"
                  >
                    {{
                      stringValues[bottomItems[n - 1].title][
                        selectedLanguage.code
                      ]
                    }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-slide-item>
    </v-slide-group>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Menu",
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    topItems: [
      {
        id: 1,
        title: "cafe",
        path: "food",
        icon: "$customCafe",
      },
      {
        id: 2,
        title: "shop",
        path: "shop",
        icon: "$customShoppingCart",
      },
      {
        id: 3,
        title: "beauty",
        path: "beauty",
        icon: "$customBeauty",
      },
      {
        id: 4,
        title: "post",
        path: "post",
        icon: "mdi-email-outline",
      },
      {
        id: 9,
        title: "carRent",
        path: "carRent",
        icon: "$customCarRent",
      },
      {
        id: 10,
        title: "realEstate",
        path: "realEstate",
        icon: "$customRealEstate",
      },
      {
        id: 11,
        title: "services",
        path: "services",
        icon: "$customServices",
      },
      {
        id: 12,
        title: "transport",
        path: "transport",
        icon: "$customTransport",
      },
    ],
    bottomItems: [
      {
        id: 5,
        title: "tourism",
        path: "tourism",
        icon: "$customTravel",
      },
      {
        id: 6,
        title: "hospital",
        path: "hospital",
        icon: "$customHealth",
      },
      {
        id: 7,
        title: "bank",
        path: "finance",
        icon: "$customFinance",
      },
      {
        id: 8,
        title: "government",
        path: "government",
        icon: "$customGovernment",
      },
      {
        id: 13,
        title: "education",
        path: "education",
        icon: "$customEducation",
      },
      {
        id: 14,
        title: "insurance",
        path: "insurance",
        icon: "$customInsurance",
      },
      {
        id: 15,
        title: "hotels",
        path: "hotels",
        icon: "$customHotel",
      },
      {
        id: 16,
        title: "other",
        path: "other",
        icon: "mdi-format-list-checkbox",
      },
    ],
  }),
  methods: {
    to(item) {
      this.$router.push({ path: `/city/${item}` });
    },
  },
  async created() {},
};
</script>