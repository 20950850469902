import house from "../api/house/index"
import market from "../api/market/index"

const GetAllLanguages = () => {
    return new Promise((resolve) => {
        resolve(house.house.GetAllLanguages())
    })
}

const GetAllRegions = () => {
    return new Promise((resolve) => {
        resolve(market.market.GetAllRegions())
    })
}

export default {
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            timeout: 3000,
            color: 'purple',
            text: ''
        },
        location: null,
        dialog: {
            show: false,
            fullscreen: false,
            hide_overlay: false,
            transition: "dialog-bottom-transition",
            title: "Заголовок",
            view_id: 0,
            width: 500
        },
        collectorItems: {
            food: 1,
            shop: 2,
            beauty: 3,
            government: 4,
            tourism: 5,
            hospital: 6,
            finance: 7,
            services: 8,
            carRent: 9,
            realEstate: 10,
            post: 11,
            transport: 12,
            education: 13,
            insurance: 14,
            hotels: 15,
            other: 16
        },
        stringValues: null,
        languages: null,
        selectedLanguage: null,
        regions: null,
        selectedRegion: null,
        appBarShow: true
    },
    getters: {
        GET_SNACKBAR(state) {
            return state.snackbar;
        },
        GET_LOCATION(state) {
            return state.location;
        },
        GET_DIALOG(state) {
            return state.dialog;
        },
        GET_COLLECTOR_ITEMS(state) {
            return state.collectorItems;
        },
        GET_STRING_VALUES(state) {
            return state.stringValues;
        },
        GET_LANGUAGES(state) {
            return state.languages;
        },
        GET_SELECTED_LANGUAGE(state) {
            if (localStorage.getItem('language') != null) {
                var languageObj = JSON.parse(localStorage.getItem('language'))
                languageObj = { data: languageObj };
                state.selectedLanguage = languageObj.data;
            }
            else {
                localStorage.setItem('language', JSON.stringify({ code: 'ru', id: 1, name: 'Русский' }));
                state.selectedLanguage = { code: 'ru', id: 1, name: 'Русский' };
            }
            return state.selectedLanguage;
        },
        GET_REGIONS(state) {
            return state.regions;
        },
        GET_SELECTED_REGION(state) {
            if (localStorage.getItem('region') != null) {
                var regionObj = JSON.parse(localStorage.getItem('region'))
                regionObj = { data: regionObj };
                state.selectedRegion = regionObj.data;
            }
            else {
                localStorage.setItem('region', JSON.stringify({ id: 243, name: 'Turkey' }));
                state.selectedRegion = { id: 243, name: 'Turkey' };
            }
            return state.selectedRegion;
        },
        GET_APP_BAR_SHOW(state) {
            return state.appBarShow;
        }
    },
    mutations: {
        SET_SNACKBAR(state, payload) {
            state.snackbar = payload;
        },
        SET_LOCATION(state, payload) {
            state.location = payload;
        },
        SET_DIALOG(state, payload) {
            state.dialog = payload;
        },
        SET_COLLECTOR_ITEMS(state, payload) {
            state.collectorItems = payload;
        },
        SET_STRING_VALUES(state, payload) {
            state.stringValues = payload;
        },
        SET_LANGUAGES(state, payload) {
            state.languages = payload;
        },
        SET_SELECTED_LANGUAGE(state, payload) {
            localStorage.setItem('language', JSON.stringify(payload));
            state.selectedLanguage = payload;
        },
        SET_REGIONS(state, payload) {
            state.regions = payload;
        },
        SET_SELECTED_REGION(state, payload) {
            localStorage.setItem('region', JSON.stringify(payload));
            state.selectedRegion = payload;
        },
        SET_APP_BAR_SHOW(state, payload){
            state.appBarShow = payload;
        }
    },
    actions: {
        GENERATE_SNACKBAR({ commit }, payload) {
            commit('SET_SNACKBAR', payload);
        },
        GENERATE_LOCATION({ commit }, payload) {
            commit('SET_LOCATION', payload);
        },
        GENERATE_DIALOG({ commit }, payload) {
            commit('SET_DIALOG', payload);
        },
        GENERATE_COLLECTOR_ITEMS({ commit }, payload) {
            commit('SET_COLLECTOR_ITEMS', payload);
        },
        COMMIT_STRING_VALUES({ commit }, payload) {
            commit('SET_STRING_VALUES', payload);
        },
        async GET_LANGUAGES_FROM_API({ commit }) {
            await GetAllLanguages().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LANGUAGES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LANGUAGES', { succeeded: false });
                })
        },
        COMMIT_SELECTED_LANGUAGE({ commit }, payload) {
            commit('SET_SELECTED_LANGUAGE', payload);
        },
        async GET_REGIONS_FROM_API({ commit }) {
            await GetAllRegions().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_REGIONS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REGIONS', { succeeded: false });
                })
        },
        COMMIT_SELECTED_REGION({ commit }, payload) {
            commit('SET_SELECTED_REGION', payload);
        },
        COMMIT_APP_BAR_SHOW({ commit }, payload) {
            commit('SET_APP_BAR_SHOW', payload);
        },
    }
}