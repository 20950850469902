<template>
  <v-container>
    <v-card elevation="0" class="my-8">
      <v-card-title>
        {{ stringValues.changePersonalData[selectedLanguage.code] }}
      </v-card-title>
      <v-row class="d-flex align-center justify-center">
        <v-col cols="10" class="text-center">
          <v-text-field
            v-model="firstName"
            :label="stringValues.name[selectedLanguage.code]"
            color="purple"
            type="text"
            maxlength="256"
            :rules="[
              firstNameRequiredRule(firstName),
              firstNameMinLengthRule(firstName),
              firstNameMaxLengthRule(firstName),
            ]"
            :error-messages="
              errorMessages
                .filter((x) =>
                  [
                    'firstNameRequired',
                    'firstNameMin',
                    'firstNameMax',
                  ].includes(x.ruleName)
                )
                .map((x) => x.text)
                .join()
            "
            dense
            outlined
          >
          </v-text-field>
          <v-text-field
            v-model="lastName"
            :label="stringValues.surname[selectedLanguage.code]"
            color="purple"
            type="text"
            maxlength="256"
            :rules="[
              lastNameRequiredRule(lastName),
              lastNameMinLengthRule(lastName),
              lastNameMaxLengthRule(lastName),
            ]"
            :error-messages="
              errorMessages
                .filter((x) =>
                  ['lastNameRequired', 'lasttNameMin', 'lastNameMax'].includes(
                    x.ruleName
                  )
                )
                .map((x) => x.text)
                .join()
            "
            dense
            outlined
          >
          </v-text-field>
          <v-btn
            class="
              mt-2
              purple
              white--text
              font-weight-regular
              rounded-lg
              text-capitalize
            "
            @click="change()"
            elevation="0"
            :loading="loading"
            :disabled="loading"
            small
          >
            {{ stringValues.apply[selectedLanguage.code] }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ChangeAvatar",
  components: {},
  computed: {
    ...mapGetters({
      getChangeStatus: "account/GET_CHANGE_STATUS",
      getLoginInfo: "account/GET_LOGIN_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    changeStatus: {
      get: function () {
        return this.getChangeStatus;
      },
    },
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
      set: function (newValue) {
        this.setLoginInfo(newValue);
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    firstName: null,
    lastName: null,
    modelState: [
      {
        ruleName: "firstNameRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "firstNameMin",
        status: false,
        text: null,
      },
      {
        ruleName: "firstNameMax",
        status: false,
        text: null,
      },
      {
        ruleName: "lastNameRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "lastNameMin",
        status: false,
        text: null,
      },
      {
        ruleName: "lastNameMax",
        status: false,
        text: null,
      },
    ],
    errorMessages: [],
    loading: false,
  }),
  methods: {
    ...mapActions({
      changePersonalDataFromApi: "account/CHANGE_PERSONAL_DATA_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setDialog: "common/SET_DIALOG",
      setLoginInfo: "account/SET_LOGIN_INFO",
    }),
    firstNameRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "firstNameRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Enter name";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("firstNameRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    firstNameMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "firstNameMin"
      );
      if (v != null && v.length < 3) {
        currentRule.status = false;
        currentRule.text = "Minimum firstname length 3 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("firstNameMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    firstNameMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "firstNameMax"
      );
      if (v != null && v.length > 255) {
        currentRule.status = false;
        currentRule.text = "Maximum firstname length 255 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("firstNameMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    lastNameRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "lastNameRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Enter surname";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("lastNameRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    lastNameMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "lastNameMin"
      );
      if (v != null && v.length < 3) {
        currentRule.status = false;
        currentRule.text = "Minimum surname length 3 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("lastNameMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    lastNameMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "lastNameMax"
      );
      if (v != null && v.length > 255) {
        currentRule.status = false;
        currentRule.text = "Maximum surname length 255 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("lastNameMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    async change() {
      var validateStatus = this.modelState.every((x) => x.status);
      if (validateStatus) {
        this.loading = true;
        await this.changePersonalDataFromApi({
          firstName: this.firstName,
          lastName: this.lastName,
        });
        if (this.changeStatus.succeeded) {
          this.setDialog({
            show: false,
            fullscreen: false,
            hide_overlay: true,
            transition: "dialog-bottom-transition",
            title: "Изменить персональные данные",
            view_id: 12,
            width: 800,
          });
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          this.loginInfo.data.user.firstName = this.firstName;
          this.loginInfo.data.user.lastName = this.lastName;
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
        this.loading = false;
      } else {
        this.errorMessages = this.modelState
          .filter((x) => !x.status)
          .map((x) => ({
            ruleName: x.ruleName,
            text: x.text,
          }));
      }
    },
  },
  async created() {},
};
</script>
