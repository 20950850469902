<template>
  <div>
    <GmapMap
      v-if="isView"
      :center="coords"
      :zoom="16"
      map-type-id="terrain"
      style="width: 100%; max-width: 100%; height: 90vh"
    >
      <GmapMarker :position="coords" />
    </GmapMap>
    <GmapMap
      v-else
      :center="coords"
      :zoom="16"
      map-type-id="terrain"
      style="width: 100%; max-width: 100%; height: 90vh"
    >
      <GmapMarker
        :position="coords"
        :draggable="true"
        @drag="updateCoordinates"
      />
    </GmapMap>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "MapCommon",
  props: {
    coords: {
      type: Object,
      default() {
        return {
          lat: 0,
          lng: 0,
        };
      },
    },
    isView: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapMutations({
      setAddLocation: "market/SET_ADD_LOCATION",
    }),
    updateCoordinates(location) {
      let coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.setAddLocation([coordinates.lat, coordinates.lng]);
    },
  },
};
</script>