import VueRouter from 'vue-router'
import Login from "../components/Account/Login.vue"
import Register from "../components/Account/Register.vue"
import HouseMain from "../components/House/Main.vue"
import CityMain from "../components/City/Main.vue"
import MainTemplate from "../components/Collector/MainTemplate.vue"
import InfoTemplate from "../components/Collector/InfoTemplate.vue"
import MenuMain from "../components/Menu/Main.vue"
import HouseMoreExtended from "../components/House/MoreExtended.vue"
import MarketMain from "../components/Market/Main.vue"
import MarketProductList from "../components/Market/ProductList.vue"
import MarketCategoryProductsDetails from "../components/Market/Info.vue"
import MarketUserList from "../components/Market/UserList.vue"
import MarketUserListAdd from "../components/Market/Add.vue"
import MarketChat from "../components/Market/Chat.vue"
import FavoritesMain from "../components/Favorites/Main.vue"
import GoogleOk from "../components/Account/GoogleOk.vue"
import GoogleError from "../components/Account/GoogleError.vue"
import FacebookOk from "../components/Account/FacebookOk.vue"
import FacebookError from "../components/Account/FacebookError.vue"
import MarketDialog from "../components/Market/Dialog.vue"
import MarketCategoryProductQueryList from "../components/Market/QueryList.vue"
import MainHouseAdministrator from "../components/Administrator/House/Main.vue"
import MainMarketAdministrator from "../components/Administrator/Market/Main.vue"
import MarketAdministatorInfo from "../components/Administrator/Market/Info.vue"
import MainPointsAdministrator from "../components/Administrator/Collector/Main.vue"
import MainAppAdministrator from "../components/Administrator/App/Main.vue"
import Promo from "../components/Promo/Promo.vue"
import PromoEn from "../components/Promo/PromoEn.vue"
import ResetPassword from "../components/Account/ResetPassword.vue"

var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/account/login',
            component: Login
        },
        {
            path: '/account/register',
            component: Register
        },
        {
            path: '/house',
            component: HouseMain
        },
        {
            path: '/house/:id',
            component: HouseMain
        },
        {
            path: '/market',
            component: MarketMain
        },
        {
            path: '/market/:productCategoryId',
            component: MarketProductList
        },
        {
            path: '/city',
            component: CityMain
        },
        {
            path: '/',
            redirect: '/city'
        },
        {
            path: '/city/all/:id',
            component: InfoTemplate
        },
        {
            path: '/city/food',
            component: MainTemplate
        },
        {
            path: '/city/food/:id',
            component: InfoTemplate
        },
        {
            path: '/city/shop',
            component: MainTemplate
        },
        {
            path: '/city/shop/:id',
            component: InfoTemplate
        },
        {
            path: '/city/beauty',
            component: MainTemplate
        },
        {
            path: '/city/beauty/:id',
            component: InfoTemplate
        },
        {
            path: '/city/finance',
            component: MainTemplate
        },
        {
            path: '/city/finance/:id',
            component: InfoTemplate
        },
        {
            path: '/city/government',
            component: MainTemplate
        },
        {
            path: '/city/government/:id',
            component: InfoTemplate
        },
        {
            path: '/city/hospital',
            component: MainTemplate
        },
        {
            path: '/city/hospital/:id',
            component: InfoTemplate
        },
        {
            path: '/city/tourism',
            component: MainTemplate
        },
        {
            path: '/city/tourism/:id',
            component: InfoTemplate
        },
        {
            path: '/city/carRent',
            component: MainTemplate
        },
        {
            path: '/city/carRent/:id',
            component: InfoTemplate
        },
        {
            path: '/city/realEstate',
            component: MainTemplate
        },
        {
            path: '/city/realEstate/:id',
            component: InfoTemplate
        },
        {
            path: '/city/post',
            component: MainTemplate
        },
        {
            path: '/city/post/:id',
            component: InfoTemplate
        },
        {
            path: '/city/transport',
            component: MainTemplate
        },
        {
            path: '/city/transport/:id',
            component: InfoTemplate
        },
        {
            path: '/city/education',
            component: MainTemplate
        },
        {
            path: '/city/education/:id',
            component: InfoTemplate
        },
        {
            path: '/city/insurance',
            component: MainTemplate
        },
        {
            path: '/city/insurance/:id',
            component: InfoTemplate
        },
        {
            path: '/city/hotels',
            component: MainTemplate
        },
        {
            path: '/city/hotels/:id',
            component: InfoTemplate
        },
        {
            path: '/city/other',
            component: MainTemplate
        },
        {
            path: '/city/other/:id',
            component: InfoTemplate
        },
        {
            path: '/city/services',
            component: MainTemplate
        },
        {
            path: '/city/services/:id',
            component: InfoTemplate
        },
        {
            path: '/menu',
            component: MenuMain
        },
        {
            path: '/house/more/:id',
            component: HouseMoreExtended
        },
        {
            path: '/market/ad/:productId',
            component: MarketCategoryProductsDetails
        },
        {
            path: '/market/userList/all',
            component: MarketUserList
        },
        {
            path: '/market/userList/add',
            component: MarketUserListAdd
        },
        {
            path: '/market/chat/all',
            component: MarketChat
        },
        {
            path: '/market/dialog/:companionId',
            component: MarketDialog
        },
        {
            path: '/favorites/:product',
            component: FavoritesMain
        },
        {
            path: '/googleOk/:guid',
            component: GoogleOk
        },
        {
            path: '/googleError',
            component: GoogleError
        },
        {
            path: '/facebookOk/:guid',
            component: FacebookOk
        },
        {
            path: '/facebookError',
            component: FacebookError
        },
        {
            path: '/market/list/all/:query',
            component: MarketCategoryProductQueryList
        },
        {
            path: '/administrator/house',
            component: MainHouseAdministrator
        },
        {
            path: '/administrator/market',
            component: MainMarketAdministrator
        },
        {
            path: '/market/admin/ad/:productId',
            component: MarketAdministatorInfo
        },
        {
            path: '/administrator/points',
            component: MainPointsAdministrator
        },
        {
            path: '/administrator/app',
            component: MainAppAdministrator
        },
        {
            path: '/promo-ru',
            component: Promo
        },
        {
            path: '/promo-en',
            component: PromoEn
        },
        {
            path: '/account/reset-password/:userId/:token',
            component: ResetPassword
        }
    ]
})

export default router