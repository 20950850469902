<template>
  <v-container>
    <v-card-title class="py-0">
      <span class="text-truncate" style="max-width: 80%">{{
        stringValues[this.productName][selectedLanguage.code]
      }}</span>
      <v-spacer />
      <v-btn
        elevation="0"
        x-small
        class="purple white--text hidden-sm-and-down text-capitalize"
        @click="openFilterDialog()"
      >
        {{ stringValues.filters[selectedLanguage.code] }}
        <v-icon right>mdi-filter</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            x-small
            class="purple white--text mx-2 hidden-sm-and-down text-capitalize"
            v-bind="attrs"
            v-on="on"
          >
            {{ stringValues.sorting[selectedLanguage.code] }}
            <v-icon right>mdi-sort-descending</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="selectSort(1)">
              <v-list-item-title>{{
                stringValues.sortByGeolocation[selectedLanguage.code]
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="selectSort(2)">
              <v-list-item-title>{{
                stringValues.sortByRating[selectedLanguage.code]
              }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn
        elevation="0"
        x-small
        class="hidden-md-and-up"
        @click="openFilterDialog()"
        fab
        text
        color="purple"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="hidden-md-and-up text-center py-0">
      <v-btn-toggle v-model="sort" dense color="purple" class="mt-2">
        <v-btn dense small class="text-capitalize">
          {{ stringValues.sortByGeolocation[selectedLanguage.code] }}
        </v-btn>
        <v-btn dense small class="text-capitalize">
          {{ stringValues.sortByRating[selectedLanguage.code] }}
        </v-btn>
      </v-btn-toggle>
    </v-card-text>
  </v-container>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "FilterDiv",
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    productName: "list",
    sort: 0,
    resultSort: 1,
  }),
  methods: {
    ...mapMutations({
      setDialog: "common/SET_DIALOG",
    }),
    ...mapActions({
      commitSelectedSort: "collector/COMMIT_SELECTED_SORT",
    }),
    openFilterDialog() {
      this.setDialog({
        show: true,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.filters[this.selectedLanguage.code],
        view_id: 5,
        width: 800,
      });
    },
    selectSort(item) {
      this.commitSelectedSort(item);
    },
  },
  watch: {
    sort: function () {
      this.resultSort = this.sort + 1;
      this.commitSelectedSort(this.resultSort);
    },
  },
  async created() {
    let splittedPath = this.$route.path.split("/");
    let splittedPathLength = splittedPath.length;
    let fieldName = splittedPath[splittedPathLength - 1];
    this.productName = fieldName;
  },
};
</script>