<template>
  <v-container>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="6" class="text-center">
          <div>
            <span class="text-caption grey--text">{{
              stringValues.currency[selectedLanguage.code]
            }}</span>
          </div>
        </v-col>
        <v-col cols="6" class="text-center">
          <div>
            <span class="text-caption grey--text">{{
              stringValues.course[selectedLanguage.code]
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-icon color="purple" small>mdi-currency-rub</v-icon>
              <span class="purple--text text-caption">RUB</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="text-center">
          <span class="font-weight-bold text-caption">{{ rubToKzt }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-icon color="purple" small>mdi-currency-usd</v-icon>
              <span class="purple--text text-caption">USD</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="text-center">
          <span class="font-weight-bold text-caption">{{ usdToKzt }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ExchangeRate",
  computed: {
    ...mapGetters({
      getExchangeRate: "exchangeRate/GET_EXCHANGE_RATE",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    exchangeRate: {
      get: function () {
        return this.getExchangeRate;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    usdToKzt: null,
    rubToKzt: null,
  }),
  methods: {
    ...mapActions({
      getExchangeRateFromApi: "exchangeRate/GET_EXCHANGE_RATE_FROM_API",
    }),
    async getExchangeRateMethod(from, to) {
      await this.getExchangeRateFromApi({
        from: from,
        to: to,
      });
    },
  },
  async created() {
    await this.getExchangeRateMethod("USD", "KZT");
    if (this.exchangeRate != null) {
      this.usdToKzt = this.exchangeRate.result.toFixed(2);
    }

    await this.getExchangeRateMethod("RUB", "KZT");
    if (this.exchangeRate != null) {
      this.rubToKzt = this.exchangeRate.result.toFixed(2);
    }
  },
};
</script>