import weather from "../api/city/weather/index"

const Get = (query) => {
    return new Promise((resolve) => {
        resolve(weather.weather.Get(query))
    })
}

export default {
    namespaced: true,
    state: {
        weather: null,
    },
    getters: {
        GET_WEATHER(state) {
            return state.weather;
        },
    },
    mutations: {
        SET_WEATHER(state, payload) {
            state.weather = payload;
        },
    },
    actions: {
        async GET_WEATHER_FROM_API({ commit }, payload) {
            await Get(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_WEATHER', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_WEATHER', null);
                })
        }
    }
}