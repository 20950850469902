<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="10">
        <v-row>
          <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
            <v-avatar class="profile" color="grey" size="164">
              <v-img :src="profile.data.user.imageLink"></v-img>
            </v-avatar>
            <div>
              <span class="font-weight-bold text-subtitle-2"
                >{{ profile.data.user.firstName }}
                {{ profile.data.user.lastName }}
                <v-icon small @click="logOut()">mdi-exit-to-app</v-icon></span
              >
            </div>
            <div>
              <v-icon small class="mx-1">mdi-email-outline</v-icon>
              <span class="font-weight-normal text-subtitle-2">{{
                profile.data.user.email
              }}</span>
            </div>
            <div>
              <v-icon small class="mx-1">mdi-phone</v-icon>
              <span class="font-weight-normal text-subtitle-2">{{
                profile.data.user.phoneNumber
              }}</span>
            </div>
          </v-col>
          <v-col cols="12" md="8" lg="8" xl="8" class="text-center">
            <v-card
              elevation="0"
              width="100%"
              class="rounded-lg"
              @click="changePassword()"
            >
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>mdi-lock-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{
                      stringValues.changePassword[selectedLanguage.code]
                    }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card
              elevation="0"
              width="100%"
              class="rounded-lg my-4"
              @click="changeAvatar()"
            >
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>mdi-camera-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{
                      stringValues.changeAvatar[selectedLanguage.code]
                    }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card
              elevation="0"
              width="100%"
              class="rounded-lg my-4"
              @click="changePersonalData()"
            >
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>mdi-notebook-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{
                      stringValues.changePersonalData[selectedLanguage.code]
                    }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "MenuProfile",
  computed: {
    ...mapGetters({
      getProfile: "account/GET_LOGIN_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    profile: {
      get: function () {
        return this.getProfile;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapMutations({
      setDialog: "common/SET_DIALOG",
    }),
    changePassword() {
      this.setDialog({
        show: true,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.changePassword[this.selectedLanguage.code],
        view_id: 10,
        width: 800,
      });
    },
    changeAvatar() {
      this.setDialog({
        show: true,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.changeAvatar[this.selectedLanguage.code],
        view_id: 11,
        width: 800,
      });
    },
    changePersonalData() {
      this.setDialog({
        show: true,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.changePersonalData[this.selectedLanguage.code],
        view_id: 12,
        width: 800,
      });
    },
    logOut() {
      localStorage.removeItem("identity");
      document.location.reload();
    },
  },
  async created() {
    if (this.profile == null) {
      this.$router.push({ path: `/account/login` });
    }
  },
};
</script>
