<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg my-8 grey lighten-4">
      <v-card-title>
        <span>{{ stringValues.favorites[selectedLanguage.code] }}</span>
      </v-card-title>
      <v-divider />
      <v-row>
        <v-col cols="12">
          <v-tabs
            grow
            center-active
            color="purple"
            v-model="selectedProduct"
            background-color="grey lighten-4"
          >
            <v-tab @click="goTo('point')" class="text-capitalize">{{
              stringValues.points[selectedLanguage.code]
            }}</v-tab>
            <v-tab @click="goTo('market')" class="text-capitalize">{{
              stringValues.ads[selectedLanguage.code]
            }}</v-tab>
            <v-tab @click="goTo('house')" class="text-capitalize">{{
              stringValues.rc[selectedLanguage.code]
            }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Point v-if="product == 'point'" />
          <Market v-else-if="product == 'market'" />
          <House v-else-if="product == 'house'" />
          <div v-else></div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Point from "./Point.vue";
import Market from "./Market.vue";
import House from "./House.vue";
export default {
  name: "FavoritesMain",
  components: {
    Point,
    Market,
    House,
  },
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    product: "point",
    selectedProduct: 0,
  }),
  methods: {
    goTo(product) {
      this.$router.push({
        path: `/favorites/${product}`,
      });
    },
    tabsChange() {
      switch (this.product) {
        case "point":
          this.selectedProduct = 0;
          break;
        case "market":
          this.selectedProduct = 1;
          break;
        case "house":
          this.selectedProduct = 2;
          break;
        default:
          break;
      }
    },
  },
  watch: {
    $route(to) {
      this.product = to.params.product;
      this.tabsChange();
    },
  },
  created() {
    let product = this.$route.params.product;
    this.product = product;
    this.tabsChange();
  },
};
</script>
