export default function (instance) {
    return {
        GetPreviewMessages(payload) {
            return instance.post('/Messages/GetPreviewMessages', payload);
        },
        GetPagedChat(payload) {
            return instance.post('/Messages/GetPagedChat', payload);
        }
    }
}
