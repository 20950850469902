<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg mt-8 grey lighten-4">
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-row class="text-center">
            <v-col cols="6">
              <v-icon color="black" @click="open(1)"
                >mdi-account-outline</v-icon
              >
              <div class="font-weight-bold text-caption">
                {{ stringValues.profile[selectedLanguage.code] }}
              </div>
            </v-col>
            <v-col cols="6">
              <v-icon color="black" @click="open(2)">mdi-send-outline</v-icon>
              <div class="font-weight-bold text-caption">
                {{ stringValues.toContactUs[selectedLanguage.code] }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0" class="rounded-lg mt-8 grey lighten-4">
      <v-row>
        <v-col cols="12">
          <Profile v-if="actionId == 1" />
          <ContactUs v-else-if="actionId == 2" />
          <div v-else></div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Profile from "./Profile.vue";
import ContactUs from "./ContactUs.vue";
export default {
  name: "MenuMain",
  components: {
    Profile,
    ContactUs,
  },
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    actionId: 1,
  }),
  metaInfo() {
    return {
      title: this.stringValues.menu[this.selectedLanguage.code],
      titleTemplate: "%s | EasyLife",
    };
  },
  methods: {
    open(actionId) {
      this.actionId = actionId;
    },
  },
  async created() {},
};
</script>
