<template>
  <v-container>
    <div v-if="documents.totalRecords > 0">
      <v-card
        elevation="0"
        v-for="article in documents.data"
        :key="article.id"
        class="rounded-lg my-2"
      >
        <v-card-text>
          <a
            :href="article.link"
            target="_blank"
            style="text-decoration: none"
            >{{ article.description }}</a
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <span class="text-subtitle-2 font-weight-bold">{{
            new Date(article.createdDate).toLocaleString()
          }}</span>
        </v-card-actions>
      </v-card>
      <div class="text-center">
        <v-pagination
          circle
          v-model="documents.pageNumber"
          :length="documents.totalPages"
          color="purple"
        ></v-pagination>
      </div>
    </div>
    <div v-else>
      <h4 class="red--text font-weight-regular">
        {{ stringValues.notElements[selectedLanguage.code] }}
      </h4>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HouseDocuments",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getDocuments: "house/GET_DOCUMENTS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    documents: {
      get: function () {
        return this.getDocuments;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getDocumentsByResidentialComplexIdFromApi:
        "house/GET_DOCUMENTS_BY_RESIDENTIAL_COMPLEX_ID_FROM_API",
    }),
    async getItemsFromApi(residentialComplexId, pageNumber, pageSize) {
      await this.getDocumentsByResidentialComplexIdFromApi({
        residentialComplexId: residentialComplexId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      });
    },
  },
  watch: {
    info: function () {
      this.getItemsFromApi(this.info.data.id, 1, 20);
    },
    "documents.pageNumber": function () {
      this.getItemsFromApi(this.info.data.id, this.documents.pageNumber, 20);
    },
  },
  async created() {
    await this.getItemsFromApi(this.info.data.id, 1, 20);
  },
};
</script>
