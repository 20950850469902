<template>
  <v-container>
    <div>Ok</div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "GoogleOk",
  computed: {
    ...mapGetters({
      getResponse: "socialNetworkAccount/GET_RESPONSE",
    }),
    response: {
      get: function () {
        return this.getResponse;
      },
    },
  },
  methods: {
    ...mapActions({
      getFromApi: "socialNetworkAccount/GET_FROM_API",
    }),
    ...mapMutations({
      setLoginInfo: "account/SET_LOGIN_INFO",
      setSnackBar: "common/SET_SNACKBAR",
    }),
  },
  data: () => ({}),
  async created() {
    let guid = this.$route.params.guid;
    await this.getFromApi({ guid: guid });
    if (this.response.succeeded) {
      this.setLoginInfo(this.response);
      this.setSnackBar({
        show: true,
        timeout: 3000,
        color: "green",
        text: this.response.message,
      });
      var data = this.$router.resolve({
        path: "/",
      });
      document.location.href = data.href;
    } else {
      this.setSnackBar({
        show: true,
        timeout: 3000,
        color: "purple",
        text: this.response.message,
      });
    }
  },
};
</script>