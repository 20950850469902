<template>
  <v-container>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div v-if="parameters.succeeded">
            <!-- <div class="text-right">
              <v-btn
                outlined
                rounded
                color="red darken-1"
                x-small
                class="my-1"
                >{{ stringValues.reset[selectedLanguage.code] }}</v-btn
              >
            </div> -->
            <template v-for="parameter in parameters.data">
              <v-card elevation="0" :key="parameter.id">
                <v-card-subtitle class="font-weight-bold black--text">
                  {{ parameter.name }}
                </v-card-subtitle>
                <v-card-text>
                  <v-list-item
                    dense
                    v-for="item in parameter.parameterCatalogs"
                    :key="item.id"
                  >
                    <v-list-item-title>
                      <span
                        class="text-subtitle-2 font-weight-regular black--text"
                        >{{ item.name }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-checkbox
                        on-icon="mdi-hand-peace"
                        off-icon="mdi-hand-peace"
                        color="green"
                        hide-details
                        v-model="item.selected"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-card-text>
              </v-card>
              <v-divider :key="'dvd_' + parameter.id" />
            </template>
            <div class="text-center">
              <v-btn
                width="100%"
                small
                elevation="0"
                class="purple white--text my-1 text-capitalize"
                @click="apply()"
              >
                {{ stringValues.apply[selectedLanguage.code] }}
              </v-btn>
            </div>
          </div>
          <div v-else>{{ stringValues.error[selectedLanguage.code] }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "FilterTemplate",
  computed: {
    ...mapGetters({
      getParameters: "collector/GET_PARAMETERS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    parameters: {
      get: function () {
        return this.getParameters;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      commitSelectedParameters: "collector/COMMIT_SELECTED_PARAMETERS",
    }),
    ...mapMutations({
      setDialog: "common/SET_DIALOG",
    }),
    apply() {
      let splittedPath = this.$route.path.split("/");
      let splittedPathLength = splittedPath.length;
      let fieldName = splittedPath[splittedPathLength - 1];

      this.commitSelectedParameters({
        data: this.parameters.data,
        load: true,
        product: fieldName,
      });

      this.setDialog({
        show: false,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.filters[this.selectedLanguage.code],
        view_id: 5,
        width: 800,
      });
    },
  },
  async created() {},
};
</script>