import account from '../api/identity/index'

const Login = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.Login(query))
    })
}

const RegisterClient = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.RegisterClient(query))
    })
}

const ChangeImageLink = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.ChangeImageLink(query))
    })
}

const ChangePassword = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.ChangePassword(query))
    })
}

const ChangePersonalData = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.ChangePersonalData(query))
    })
}

const GetAllUsers = () => {
    return new Promise((resolve) => {
        resolve(account.account.GetAllUsers())
    })
}

const ChangeUserRoles = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.ChangeUserRoles(query))
    })
}

const ResetPassword = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.ResetPassword(query))
    })
}


export default {
    namespaced: true,
    state: {
        loginInfo: null,
        registerInfo: null,
        changeStatus: null,
        users: null
    },
    getters: {
        GET_LOGIN_INFO(state) {
            if (localStorage.getItem('identity') != null) {
                var identityObj = JSON.parse(localStorage.getItem('identity'))
                identityObj = { data: identityObj };
                state.loginInfo = identityObj.data;
            }
            return state.loginInfo;
        },
        GET_REGISTER_INFO(state) {
            return state.registerInfo;
        },
        GET_CHANGE_STATUS(state) {
            return state.changeStatus;
        },
        GET_USERS(state) {
            return state.users;
        },
    },
    mutations: {
        SET_LOGIN_INFO(state, payload) {
            if (payload.succeeded)
                localStorage.setItem('identity', JSON.stringify(payload));

            state.loginInfo = payload;
        },
        SET_REGISTER_INFO(state, payload) {
            state.registerInfo = payload;
        },
        SET_CHANGE_STATUS(state, payload) {
            state.changeStatus = payload;
        },
        SET_USERS(state, payload) {
            state.users = payload;
        },
    },
    actions: {
        async LOGIN_FROM_API({ commit }, payload) {
            await Login(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LOGIN_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LOGIN_INFO', { succeeded: false });
                })
        },
        async REGISTER_CLIENT_FROM_API({ commit }, payload) {
            await RegisterClient(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_REGISTER_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REGISTER_INFO', { succeeded: false });
                })
        },
        async CHANGE_AVATAR_FROM_API({ commit }, payload) {
            await ChangeImageLink(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_CHANGE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_CHANGE_STATUS', { succeeded: false });
                })
        },
        async CHANGE_PASSWORD_FROM_API({ commit }, payload) {
            await ChangePassword(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_CHANGE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_CHANGE_STATUS', { succeeded: false });
                })
        },
        async CHANGE_PERSONAL_DATA_FROM_API({ commit }, payload) {
            await ChangePersonalData(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_CHANGE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_CHANGE_STATUS', { succeeded: false });
                })
        },
        async CHANGE_USER_ROLES_FROM_API({ commit }, payload) {
            await ChangeUserRoles(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_CHANGE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_CHANGE_STATUS', { succeeded: false });
                })
        },
        async GET_USERS_FROM_API({ commit }) {
            await GetAllUsers().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_USERS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_USERS', { succeeded: false });
                })
        },
        async RESET_PASSWORD_FROM_API({ commit }, payload) {
            await ResetPassword(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_CHANGE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_CHANGE_STATUS', { succeeded: false });
                })
        },
    }
}